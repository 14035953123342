<template>
    <CreateForm
        :title="$t('user_permissions_add')"
        :action-button-disabled="form.user === '' || form.role === ''"
        :return-route="route('settings.user-permissions.index')"
        @submit="onSubmit"
    >
      <div class="flex flex-wrap w-full gap-x-16 pl-2">
        <Dropdown
            :label="$t('fcul_member')"
            :model-value="form.user"
            :list="fculMember"
            :search-value="fculMemberQuery"
            :is-required="true"
            @on-change="(value) => form.user = value?.id ?? ''"
            @on-search="(value) => onSearch(value, 'fculMember')"
        />
        <Dropdown

            :label="$t('roles')"
            :model-value="form.role"
            :list="roles"
            :search-value="roleSearch"
            :is-required="true"
            @on-change="(value) => form.role = value?.id"
            @on-search="(value) => onSearch(value, 'role')"
        />
      </div>
    </CreateForm>
</template>

<script setup>

import {router, useForm} from "@inertiajs/vue3";
import CreateForm from "../../../Components/Forms/CreateForm.vue";
import Dropdown from "../../../Components/Dropdown.vue";
import _ from "lodash";
import {ref} from "vue";

const props = defineProps({
  fculMember: Array,
  fculMemberQuery: String,
  roles: Array,
  roleSearch: String
})


const form = useForm({
  user: '',
  role: ''
})

const queryParameters = ref({})

const onSearch =  _.throttle((value, property) => {

  if (queryParameters.value && value === ""){
    delete queryParameters.value[property]
  } else {
    queryParameters.value[property] = value
  }

  router.get(route('settings.user-permissions.create'), queryParameters.value, {
    preserveState: true,
  })
}, 1500)

const onSubmit = () => {
    form.post(route('settings.user-permissions.store'))
}

</script>

<style>
textarea:focus, input:focus{
  outline: 0.19rem solid;
  outline-color: #2C3FB1;
}
</style>
