<template>
    <EditForm
        class=""
        :title="$t('projects_update_financing')"
        :action-button-disabled="false"
        :return-route="route('projects.show', project.id)"
        @submit="onSubmit"
    >
        <div class="w-full flex flex-wrap gap-x-10 px-2 lg:px-0">
            <FundusInput
                :label="$t('budget_value_total')"
                type="number"
                :error="form.errors.totalBudgetValue"
                :model-value="form.totalBudgetValue"
                :is-required="true"
                @on-change="(value) => onChange(value, 'totalBudgetValue')"
            />
            <div
                v-if="form.totalBudgetValue !== project?.totalBudgetValue?.value"
                class="w-full lg:w-96"
            >
                <FundusInput

                    :label="$t('budget_value_total_observation')"
                    :error="form.errors.totalBudgetValueObservation"
                    :model-value="form.totalBudgetValueObservation"
                    :inputSize="INPUT_SIZES.LARGE"
                    @on-change="(value) => onChange(value, 'totalBudgetValueObservation')"
                />
            </div>
            <div class="flex w-full gap-x-10 flex-col lg:flex-row">
                <FundusInput
                    :label="$t('financing_value_total')"
                    type="number"
                    :error="form.errors.totalFinancingValue"
                    :model-value="form.totalFinancingValue"
                    :is-required="true"
                    @on-change="(value) => onChange(value, 'totalFinancingValue')"
                />
                <div
                    v-if="form.totalFinancingValue !== project?.totalFinancingValue?.value"
                    class="w-full lg:w-96"
                >
                    <FundusInput

                        :label="$t('financing_value_total_observation')"
                        :error="form.errors.totalFinancingValueObservation"
                        :model-value="form.totalFinancingValueObservation"
                        :inputSize="INPUT_SIZES.LARGE"
                        @on-change="(value) => onChange(value, 'totalFinancingValueObservation')"
                    />
                </div>
            </div>
            <ComposedListAlternative
                class="mt-8 bg-white text-black"
                v-slot="listProps"
                :label="$t('financing_entities')"
                :list-display-name="(item) => `${$t(item.financingEntity.designation)} &nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;${item.financingEntity?.financingEntityPercentage === null || item.financingEntity?.financingEntityPercentage? '': item.financingEntity?.financingEntityPercentage + '%'}`"
                :button-disable="(addingValue) => !addingValue.financingEntity || !addingValue.financingEntityPercentage"
                :list-size="INPUT_SIZES.MEDIUM"
                :error="form.errors.financingEntities"
                :model-value="project.financingEntities.map((entity) => ({financingEntity: entity}))"
                :has-edit="true"
                @on-list-update="(value) => {
                        onChange(value.map((item) => ({id: item.financingEntity.id ?? item.financingEntity.entityId, financingEntityPercentage: item.financingEntityPercentage ?? item.financingEntity.financingEntityPercentage, type: item.financingEntity.type})), 'financingEntities')
                        onSearch('', 'financingEntities', form.financingEntities)
                }"
                @on-edit="(value, toAddContent) => {
                    toAddContent.financingEntity = value.financingEntity
                    toAddContent.financingEntityPercentage = value.financingEntityPercentage
                }"
            >
                <Dropdown
                    :label="$t('financing_entities_single')"
                    :model-value="listProps.toAddContent.financingEntity"
                    :list="financingEntities"
                    :search-value="financingEntityQuery"
                    :invert-color="true"
                    @on-change="(value) => listProps.toAddContent.financingEntity = value"
                    @on-search="(value) => onSearch(value, 'financingEntities', form.financingEntities)"
                />
                <Input
                    :label="$t('financing_entities_percentage')"
                    type="number"
                    :invert-color="true"
                    :model-value="listProps.toAddContent.financingEntityPercentage"
                    @on-change="(value) => listProps.toAddContent.financingEntityPercentage = value"
                />
            </ComposedListAlternative>
            <ComposedListAlternative
                class="mt-8 bg-white text-black"
                v-slot="listProps"
                :label="$t('financing_programs')"
                :list-display-name="(item) =>item.financingProgram.designation"
                :button-disable="(addingValue) => !addingValue.financingProgram"
                :list-size="INPUT_SIZES.MEDIUM"
                :error="form.errors.financingPrograms"
                :is-required="true"
                :model-value="project?.financingPrograms.map((program)=> ({financingProgram: {id: program.id, designation: program.designation}}))"
                @on-list-update="(value) => {
                        form.financingProgramValueDecomposition = form.financingProgramValueDecomposition.filter(item => value.find(program => program.financingProgram.id === item.aggregatorId))
                        onChange(value.map((item) => item.financingProgram.id), 'financingPrograms')
                        onSearch('', 'financingPrograms', form.financingPrograms)
                    }"
            >
                <Dropdown
                    :label="$t('financing_programs_single')"
                    :model-value="listProps.toAddContent.financingProgram"
                    :list="financingPrograms"
                    :search-value="financingProgramQuery"

                    :is-required="true"
                    :invert-color="true"
                    @on-change="(value) => listProps.toAddContent.financingProgram = value"
                    @on-search="(value) => onSearch(value, 'financingPrograms', form.financingPrograms)"

                />
            </ComposedListAlternative>
            <FundsDecompositionEdit class="mt-8 bg-white text-black"
                v-if="form.financingPrograms.length !== 0"
                :section-name="$t('financing_programs_value_distribution')"
                :error="form.errors.financingProgramValueDecomposition"
                :decompositions-financing="project?.financingPrograms.filter(item => form.financingPrograms.includes(item.id)).concat(convertNewProjects()).map(program => ({id:program.id , name: program.designation,decompositionInputs: program.attributesList}))"
                :label-name="(value) => value.name"
                :model-value="project?.financingPrograms.map(program =>  program.decompositionInputs.map(item => ({inputId: item.attributeId, inputValue: item.value, aggregatorId: item.financingProgramId})))"
                :edit-values="form.financingProgramValueDecomposition"
                @on-change="(value) => onChange(value, 'financingProgramValueDecomposition')"

            />
            <ComposedListAlternative
                class="mt-8 bg-white text-black"
                v-slot="listProps"
                :label="$t('fcul_decomposition_models')"
                :list-display-name="(item) =>item.fculDecompositionModels.designation"
                :button-disable="(addingValue) => !addingValue.fculDecompositionModels"
                :list-size="INPUT_SIZES.MEDIUM"
                :model-value="project?.fculDecompositionModels.map((program)=> ({fculDecompositionModels: {id: program.id, designation: program.designation}}))"
                @on-list-update="(value) => {
                          onChange(value.map((item) => item.fculDecompositionModels.id), 'fculDecompositionModels')
                          onSearch('', 'fculDecompositionModel', form.fculDecompositionModels)
                      }"
            >
                <Dropdown
                    :label="$t('fcul_decomposition_models_single')"
                    :model-value="listProps.toAddContent.fculDecompositionModels"
                    :list="fculDecompositionModels"
                    :search-value="fculDecompositionModelsQuery"
                    :invert-color="true"
                    @on-change="(value) => listProps.toAddContent.fculDecompositionModels = value"
                    @on-search="(value) => onSearch(value, 'fculDecompositionModel', form.fculDecompositionModels)"

                />
            </ComposedListAlternative>
            <FundsDecompositionEdit class="mt-8 bg-white text-black"
                                    v-if="form.fculDecompositionModels.length !== 0"
                                    :section-name="$t('fcul_decomposition_models_distribution')"
                                    :error="form.errors.fculDecompositionModelsDecomposition"
                                    :decompositions-financing="project?.fculDecompositionModels.filter(item => form.fculDecompositionModels.includes(item.id)).concat(convertNewModel()).map(model => ({id:model.id , name: model.designation,decompositionInputs: model.attributesList}))"
                                    :label-name="(value) => value.name"
                                    :model-value="project?.fculDecompositionModels.map(model => model.decompositionInputs.map(item => ({inputId: item.attributeId, inputValue: item.value, aggregatorId: item.decompositionModelId})))"
                                    :edit-values="form.fculDecompositionModelsDecomposition"
                                    @on-change="(value) => onChange(value, 'fculDecompositionModelsDecomposition')"

            />
            <ComposedListAlternative
                class="mt-8 bg-white text-black"
                :label="$t('project_list_fcul')"
                v-slot="listProps"
                :list-display-name="(item) => item.costCenterId"
                :button-disable="(addingValue) => !addingValue.costCenterId"
                :model-value="form.listProjectFcul"
                @on-list-update="(value) => onChange(value, 'listProjectFcul')"
            >
              <Input
                  :label="$t('project_id')"
                  :invert-color="true"
                  :model-value="listProps.toAddContent.costCenterId"
                  @on-change="(value) => listProps.toAddContent.costCenterId = value"
              />
              <Input
                  :label="$t('project_description')"
                  :model-value="listProps.toAddContent.projectDescription"
                  :input-size="INPUT_SIZES.XLARGE"
                  :not-resizable="true"
                  :invert-color="true"
                  @on-change="(value) => listProps.toAddContent.projectDescription = value"
              />
            </ComposedListAlternative>
            <ComposedListAlternative
                class="mt-8 bg-white text-black"
                :label="$t('project_list_fciencias')"
                v-slot="listProps"
                :list-display-name="(item) => item.costCenterId"
                :button-disable="(addingValue) => !addingValue.costCenterId"
                :model-value="form.listProjectFCiencias"
                @on-list-update="(value) => onChange(value, 'listProjectFCiencias')"
            >
              <Input
                  :label="$t('project_id')"
                  :model-value="listProps.toAddContent.costCenterId"
                  :invert-color="true"
                  @on-change="(value) => listProps.toAddContent.costCenterId = value"
              />
              <Input
                  :label="$t('project_description')"
                  :model-value="listProps.toAddContent.projectDescription"
                  :input-size="INPUT_SIZES.XLARGE"
                  :not-resizable="true"
                  :invert-color="true"
                  @on-change="(value) => listProps.toAddContent.projectDescription = value"
              />
            </ComposedListAlternative>
            <ComposedListAlternative
                class="mt-8 bg-white text-black"
                :label="$t('project_list_ffcul')"
                v-slot="listProps"
                :list-display-name="(item) => item.costCenterId"
                :button-disable="(addingValue) => !addingValue.costCenterId"
                :model-value="form.listProjectFFcul"
                @on-list-update="(value) => onChange(value, 'listProjectFFcul')"
            >
              <Input
                  :label="$t('project_id')"
                  :invert-color="true"
                  :model-value="listProps.toAddContent.costCenterId"
                  @on-change="(value) => listProps.toAddContent.costCenterId = value"
              />
              <Input
                  :label="$t('project_description')"
                  :model-value="listProps.toAddContent.projectDescription"
                  :input-size="INPUT_SIZES.XLARGE"
                  :not-resizable="true"
                  :invert-color="true"
                  @on-change="(value) => listProps.toAddContent.projectDescription = value"
              />
            </ComposedListAlternative>
        </div>
    </EditForm>
</template>

<script setup>
import FundusInput from "../../../Components/Input.vue"
import {INPUT_SIZES} from "../../../Components/Input.vue";
import {router, useForm} from "@inertiajs/vue3";
import Dropdown from "../../../Components/Dropdown.vue";
import _ from "lodash";
import {ref} from "vue";
import EditForm from "../../../Components/Forms/EditForm.vue";
import Input from "../../../Components/Input.vue";
import ComposedListAlternative from "../../../Components/Projeto/ComposedList.vue";
import FundsDecompositionEdit from "../../../Components/Projeto/FundsDecompositionEdit.vue";

const props = defineProps({
    project: Object,
    financingEntities: Array,
    financingEntityQuery: String,
    financingPrograms: Array,
    financingProgramQuery: String,
    financingDecompositionInputs: Array,
    fculDecompositionModels: Array,
    fculDecompositionModelsQuery: String,
    fculDecompositionModelsInputs: Array,
})



const form = useForm({
    type: "FINANCING_INFO",
    totalFinancingValue: props.project?.totalFinancingValue?.value ?? "",
    totalFinancingValueObservation: "",
    totalBudgetValue: props.project?.totalBudgetValue?.value ?? "",
    totalBudgetValueObservation: "",
    financingEntities: props.project?.financingEntities.map((entity) => ({
            id:entity.entityId,
            financingEntityPercentage: entity.financingEntityPercentage,
            type: entity.type
        })) ?? "",
    financingPrograms: props.project?.financingPrograms.map((program)=> program.id),
    financingProgramValueDecomposition: props.project?.financingPrograms?.map(program => program.decompositionInputs)[0]?.map(input => ({inputId: input.attributeId, inputValue: input.value, aggregatorId: input.financingProgramId})) ?? [],
    fculDecompositionModels: props.project?.fculDecompositionModels.map((model)=> model.id),
    fculDecompositionModelsDecomposition: props.project?.fculDecompositionModels?.map(model => model.decompositionInputs)[0]?.map(input => ({inputId: input.attributeId, inputValue: input.value, aggregatorId: input.decompositionModelId})) ?? [],
    listProjectFcul: props.project?.listProjectFcul ?? [],
    listProjectFCiencias: props.project?.listProjectFCiencias ?? [],
    listProjectFFcul: props.project?.listProjectFFcul ?? []
});


const queryParameters = ref({})

const onSearch =  _.throttle((value, property, used) => {

    if (queryParameters.value && value === ""){
        delete queryParameters.value[property]
    } else {
        queryParameters.value[property] = value
    }

    if (used) {
        queryParameters.value[property + "Used"] = JSON.stringify(used)
    }

    router.get(route('projects.edit.financing', props.project?.id), queryParameters.value, {
        preserveState: true,
    })
}, 1000)

const convertNewProjects = () => {
    return props?.financingDecompositionInputs?.map(item => ({id: item.id, designation: item.financingProgramName, attributesList: item.decompositionInputs})) ?? []
}

const convertNewModel = () => {
    return props?.fculDecompositionModelsInputs?.map(item => ({id: item.id, designation: item.designation, attributesList: item.decompositionInputs})) ?? []
}


const onChange = (value, propertyName) => {

    if (form.errors[propertyName]) {
        form.errors[propertyName] = null;
    }
    form[propertyName] = ((value !== undefined)?  value : null);
}

const onSubmit = () => {

    for (let prop in form.data()) {
        if (form[prop] instanceof Object && form[prop].id) {
            form[prop] = form[prop].id
        }
    }

    form.put(route('projects.update', props.project.id))
}


</script>
