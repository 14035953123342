<template>
<div class="w-full flex flex-col lg:flex-row justify-between lg:items-center bg-white lg:py-8 py-4 lg:px-16 px-4 rounded-lg drop-shadow-xl shadow-black mb-6">
  <h1
      class="font-bold text-lg lg:break-all pr-2 lg:w-1/3 h-full"
      :class="{
        'lg:w-1/3': subTitle && thirdTitle,
        'lg:w-1/2': (subTitle && !thirdTitle) || (!subTitle && thirdTitle),
        'lg:w-full': !subTitle && !thirdTitle
      }"
  >{{title}}</h1>
  <div class="flex items-center w-full"
    :class="{
      'lg:w-1/5': !updateRoute && !toggleItemRoute && !deleteRoute && subTitle,
      'lg:w-1/3': !updateRoute && !toggleItemRoute && !deleteRoute && subTitle
    }"
  >
      <h1 class="font-bold text-lg break-all pr-2 w-full" v-if="subTitle">{{subTitle}}</h1>
    <Link
        v-if="updateRoute"
        class="cursor-pointer bg-fculColor ml-10 py-2.5 px-4 lg:px-8 rounded-lg drop-shadow-xl shadow-black hover:bg-opacity-60 mr-2 lg:mr-6"
        :href="sanitizedUpdateRoute"
    >
        <FontAwesomeIcon :icon="faPencil" class="text-white" />
    </Link>

    <button
      v-if="toggleItemRoute && !itemActive"
      class="cursor-pointer bg-agree py-2.5 px-4 lg:px-8 rounded-lg drop-shadow-xl shadow-black hover:bg-opacity-60"
      @click="sanitizedToggleRoute"

    >
      <FontAwesomeIcon :icon="faCheck" class="text-white" />
    </button>
    <button
      v-if="toggleItemRoute && itemActive"
      class="cursor-pointer bg-warning py-2.5 px-4 lg:px-8  rounded-lg drop-shadow-xl shadow-black hover:bg-opacity-60 mr-0.5"
      @click="sanitizedToggleRoute"

    >
      <FontAwesomeIcon :icon="faXmark" class="text-white" />
    </button>
    <button
        v-if="deleteRoute"
        class="cursor-pointer bg-decline py-2.5 px-4 lg:px-8  rounded-lg drop-shadow-xl shadow-black hover:bg-opacity-60 ml-2 lg:ml-6"
        :class="{'ml-0': !toggleItemRoute}"
        @click="sanitizedDeleteRoute"

    >
      <FontAwesomeIcon :icon="faTrash" class="text-white" />
    </button>
  </div>
    <div class="w-1/6 flex justify-start mr-4 lg:mr-0" v-if="thirdTitle">
        {{thirdTitle}}
    </div>
</div>
</template>

<script setup>

import {faTrash, faPencil, faCheck, faXmark} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {router, Link} from "@inertiajs/vue3";
import {computed} from "vue";
import DOMPurify from "dompurify";

const props = defineProps({
    title: String,
    updateRoute: String,
    deleteRoute: String,
    toggleItemRoute: String,
    itemActive: Boolean,
    subTitle: String,
    thirdTitle: String
})

const sanitizedUpdateRoute = computed(() => {
    return DOMPurify.sanitize(props.updateRoute);
});

const sanitizedDeleteRoute = computed(() => {
    return DOMPurify.sanitize(props.deleteRoute);
});

const sanitizedToggleRoute = computed(() => {
    return DOMPurify.sanitize(props.toggleItemRoute);
});


const deleteItem = () => {
 router.delete(props.deleteRoute, {replace: true})
}

const toggleItem = () => {
    router.put(props.toggleItemRoute, {replace: true})
}
</script>
