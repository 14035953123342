const Authorizations = {
    ADMIN: 0,
    GESTOR_DE_SISTEMAS: 1,
    GESTOR_DE_CIENCIAS: 2,
    GESTOR_DE_PROJETOS: 3,
    GESTOR_DEPARTAMENTO: 4,
    UTILIZADOR_AUTENTICADO: 5,
    PUBLICO: 6
}

export function hasMyFundus(userRole) {

    return userRole !== Authorizations.PUBLICO
}

export function canChangeFullSettings(userRole) {
    return userRole === Authorizations.ADMIN
}

export function canChangeSettings(userRole) {
    return userRole === Authorizations.ADMIN || userRole === Authorizations.GESTOR_DE_SISTEMAS
}

export function canCreateProject(userRole) {
    return userRole === Authorizations.GESTOR_DE_PROJETOS
        || userRole === Authorizations.GESTOR_DE_SISTEMAS
        || userRole === Authorizations.ADMIN
}

export function canEditProjectCloseInfo(userRole) {
    return userRole === Authorizations.GESTOR_DE_PROJETOS
        || userRole === Authorizations.ADMIN
        || userRole === Authorizations.GESTOR_DE_SISTEMAS
}

export function canEditScientificInfo(userRole, userId, participants) {


    if (userRole === Authorizations.UTILIZADOR_AUTENTICADO) {
        return participants?.find(participant => participant.censusId === userId)?.isResponsible ?? false
    }

    return userRole === Authorizations.ADMIN
        || userRole === Authorizations.GESTOR_DE_SISTEMAS
        || userRole === Authorizations.GESTOR_DE_PROJETOS
        || userRole === Authorizations.GESTOR_DE_CIENCIAS
}

export function canGenerateExport(userRole) {
    return userRole === Authorizations.ADMIN
    || userRole === Authorizations.GESTOR_DE_SISTEMAS
    || userRole === Authorizations.GESTOR_DE_PROJETOS
    || userRole === Authorizations.GESTOR_DE_CIENCIAS
    || userRole === Authorizations.GESTOR_DEPARTAMENTO
}
