<template>
    <HorizontalList
        :list-name="$t('financing_origin')"
        :return-route="route('settings.index')"
        :add-route="route('settings.financing-origins.create')"
        :allow-search="true"
        @search="item => onDelayedSearch(item)"
    >
        <HorizontalListItem
            v-for="item in financingOrigins?.data"
            :title="item?.designation"
            :update-route="route('settings.financing-origins.edit', item?.id)"
            :delete-route=" route('settings.financing-origins.destroy', item?.id)"
            :toggle-item-route="route('settings.financing-origins.toggle',item?.id)"
            :item-active="item.active"
        />
        <HorizontalPagination :links="financingOrigins.links"/>
    </HorizontalList>

</template>

<script setup>
import HorizontalList from "../../../Components/HorizontalList/HorizontalList.vue";
import HorizontalListItem from "../../../Components/HorizontalList/HorizontalListItem.vue";
import HorizontalPagination from "../../../Components/HorizontalList/HorizontalPagination.vue";
import _ from "lodash";
import {router} from "@inertiajs/vue3";

const props = defineProps({
    financingOrigins: Object,
})

const onDelayedSearch =  _.throttle((value) => {
    router.get(route('settings.financing-origins.index'), {search: value}, {
        preserveState: true
    })
}, 1000)

</script>
