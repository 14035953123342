<template>
    <CreateForm
        :title="$t('fcul_decomposition_models_add')"
        :action-button-disabled="form.designation === '' || form.decompositionInputs.length === 0 "
        :return-route="route('settings.fcul-decomposition-models.index')"
        @submit="onSubmit"
    >
        <div class="flex flex-col justify-center w-full px-2">
            <Input
                :label="$t('fcul_decomposition_models_single')"
                :error="form.errors.designation"
                :model-value="form.designation"
                @on-change="onChange"
            />

            <label class="font-bold pl-2 mb-4">Decomposição</label>
            <button
                class="bg-fculColor w-16 py-2 rounded-lg drop-shadow-xl shadow-black font-bold text-white cursor-pointer hover:bg-opacity-60 mb-4"
                @click.prevent="increaseInput()"
            >
                +
            </button>
            <RecursiveInput
                :creation="true"
                v-for="input in form.decompositionInputs"
                :input="input"
                @remove-this="removeThisInput"
            />
        </div>
    </CreateForm>
</template>

<script setup>

import {useForm} from "@inertiajs/vue3";
import Input from "../../../Components/Input.vue";
import CreateForm from "../../../Components/Forms/CreateForm.vue";
import {ref} from "vue";
import RecursiveInput from "../../../Components/Forms/RecursiveInput.vue";

const form = useForm({
    designation: "",
    decompositionInputs: []
})

const onChange = (value) => {
  if (form.errors.designation) {
      form.errors.designation = null;
  }
  form.designation = value

}

const onSubmit = () => {
    form.post(route('settings.fcul-decomposition-models.store'))
}

const countId = ref(0)

const increaseInput = () => {
    form.decompositionInputs.push({
        id: countId.value++,
        name: "",
        child: []
    })
}

const removeThisInput = (thisId) => {
    form.decompositionInputs = form.decompositionInputs.filter((input) => input.id !== thisId)
}

</script>

<style>
textarea:focus, input:focus{
  outline: 0.19rem solid;
  outline-color: #2C3FB1;
}
</style>
