<template>
<div class="text-black w-full flex justify-between items-center bg-white py-4 px-4 rounded-lg drop-shadow-xl shadow-black mb-2 flex-col lg:flex-row">
  <h1 class=" text-lg" v-html="sanitizedTitle"></h1>
  <div class="flex items-center w-full lg:w-auto">
      <button
          v-if="hasEdit"
          :aria-label="$t('edit')"
          class="cursor-pointer bg-fculColor py-2 px-4 w-full lg:w-auto rounded-lg drop-shadow-xl shadow-black hover:bg-opacity-60 lg:ml-6 mt-4 lg:mt-0"
          @click.prevent="editItem"

      >
          <FontAwesomeIcon :icon="faPencil" class="text-white" />
      </button>
    <button
        :aria-label="$t('delete')"
        v-if="!notRemovable"
        class="cursor-pointer bg-decline py-2 px-4 w-full lg:w-auto rounded-lg drop-shadow-xl shadow-black hover:bg-opacity-60 lg:ml-6 mt-4 lg:mt-0"
        @click.prevent="deleteItem"

    >
      <FontAwesomeIcon :icon="faTrash" class="text-white" />
    </button>
  </div>
</div>
</template>

<script setup>

import {faPencil, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {computed} from "vue";
import DOMPurify from "dompurify";

const props = defineProps({
  title: String,
    notRemovable: Boolean,
    hasEdit: Boolean
})

const sanitizedTitle = computed(() => {
    return DOMPurify.sanitize(props.title);
})

const emit = defineEmits(['onDelete', 'onEdit'])

const deleteItem = () => {
 emit('onDelete')
}

const editItem = () => {
    emit('onEdit')
}

</script>
