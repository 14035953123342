<template xmlns:Link="http://www.w3.org/1999/html">
    <div class="w-full flex flex-col max-h-screen overflow-hidden">
        <div class="w-full pt-10 lg:pt-6  pb-8 lg:pb-4  pl-8 pr-8 bg-background flex flex-col lg:flex-row  items-center justify-between">
            <h1 class="font-bold text-3xl text-fculColor ">{{$t('projects_single')}}</h1>
            <div v-if="isAuthenticated" class="hidden lg:flex flex-col">
              <p class="font-bold text-fculColor ">{{ user?.userName }}</p>
              <p class="text-sm text-fculColor">{{ user?.userEmail }}</p>
            </div>
        </div>
        <div class="lg:pl-8 pl-2 lg:pr-6 pr-2 overflow-y-scroll pt-4 scrollbar ">
            <div class="pt-4 lg:pt-8 pb-4 lg:pb-10 lg:px-10 px-4 mb-4 bg-white rounded-lg drop-shadow-xl shadow-black">
                <div class=" flex justify-between items-center flex-col lg:flex-row">
                    <p class="text-2xl font-bold text-fculColor mb-4 lg:mb-0 break-words w-1/2">{{languagePt? (project.titlePt ?? project.titleEn)
                        : (project.titleEn ?? project.titlePt)}}
                    </p>
                    <div class="flex gap-x-4 flex-col lg:flex-row w-full lg:w-auto">
                        <Link
                            v-if="canEditProjectCloseInfo(user?.role)"
                            class="flex justify-between btn-custom bg-opacity-80 hover:bg-opacity-100 w-full mb-4 lg:mb-0 lg:w-32 py-3 px-4"
                            :href="sanitizedText(route('projects.edit.basic', project.id))"
                        >
                            {{$t('edit')}}
                            <FontAwesomeIcon
                                :icon="faPencil"
                                size="lg"
                                class="text-white"
                            />
                        </Link>
                        <Link
                            class="flex justify-between btn-custom w-full lg:w-48 py-3 px-4"
                            :href="sanitizedText(route('projects.show.public', project.id))"
                        >
                            {{$t('public_page')}}
                            <FontAwesomeIcon
                                :icon="faCircleArrowRight"
                                size="xl"
                                class="text-white"
                            />
                        </Link>
                    </div>
                </div>
                <div class="flex flex-wrap w-4/5 gap-4 gap-x-9 pl-8 pt-8 justify-between">
                    <InfoItem
                        :label="$t('Id FUNDUS')"
                        :value="`${project.id}`"
                    />
                    <InfoItem
                        :label="$t('acronym')"
                        :value="project.acronym"
                    />

                    <InfoItem
                        :label="$t('date_start')"
                        :value="project.dateStart"
                    />
                    <InfoItem
                        :label="$t('date_end')"
                        :value="project.dateEnd"
                        :with-popup="true"
                        :pop-up-name="$t('date_end')"
                    >
                        <div class="mt-2 flex flex-col w-full h-full max-h-32 overflow-y-scroll">
                            <ProjectProperty
                                v-for=" (date, index) in projectDates"
                                :key="index"
                                :clickable="true"
                                :value="`${index === 0? $t('current_date') + ':' : ''} ${date.dateEnd}`"
                                :second-value-label="`${$t('date_end_justification')}: `"
                                :second-value="date.showObsv?  date.observations : ''"
                                :col="true"
                                @onClick="() => {
                                    if(date.showObsv) {
                                        date.showObsv = undefined
                                    } else {
                                        date.showObsv = true
                                    }
                                }"
                            />
                        </div>
                    </InfoItem>
                    <InfoItem
                        :label="$t('state')"
                        :value="$t(project.state)"
                    />
                    <InfoItem
                        :label="$t('typologies_single')"
                        :value="project.typology"
                    />
                    <InfoItem
                        :label="$t('scope_single')"
                        :value="project.scope"
                    />
                    <InfoItem
                        v-if="project.financingOrigin"
                        :label="$t('financing_origin_single')"
                        :value="project.financingOrigin"
                    />
                    <InfoItem
                        v-if="project.doi"
                        :label="$t('doi')"
                        :value="project.doi"
                    />
                    <InfoItem
                        v-if="project.managementCoreFcul"
                        :label="$t('management_core_ciências')"
                        :value="project.managementCoreFcul"
                    />
                    <InfoItem
                        v-if="project.managementCoreFCiencias"
                        :label="$t('management_core_fciencias')"
                        :value="project.managementCoreFCiencias"
                    />
                </div>
            </div>
            <ProjectSection
            class="mb-8"
          >
            <div class="flex w-full justify-between mb-8 items-center flex-col lg:flex-row">
              <p class="font-bold text-2xl text-fculColor ">{{$t('logos')}}</p>
              <Link
                  v-if="canEditScientificInfo(user.role, user.userId, project.participantPersons)"
                  class="flex justify-between btn-custom bg-opacity-80 hover:bg-opacity-100 w-full lg:w-32 py-3 px-4 mt-8 lg:mt-0 mb-4 lg:mb-0"
                  :href="sanitizedText(route('projects.edit.logos', project.id))"
                  preserve-state
              >
                {{$t('edit')}}
                <FontAwesomeIcon
                    :icon="faPencil"
                    size="lg"
                    class="text-white"
                />
              </Link>
            </div>
            <div class="w-full max-w-full mb-6 flex flex-wrap gap-4 gap-x-9">
              <img v-for="logo in project.logos" :alt="logo.logo" class="h-12" :src="base64.decode(logo.logo)">
            </div>


          </ProjectSection>
            <Separator
                class="mb-8" :separator-name="$t('scientific_information')"
            >
              <div class="w-full pt-4 lg:pt-8 pb-4 lg:px-10 px-4  bg-white rounded-lg drop-shadow-xl shadow-black">
                <div
                    class="flex w-full justify-end items-center mb-6 flex-col lg:flex-row"
                >
                    <Link
                        v-if="canEditScientificInfo(user.role, user.userId, project.participantPersons)"
                        class="flex justify-between btn-custom bg-opacity-80 hover:bg-opacity-100 w-full  lg:w-32 py-3 px-4"
                        :href="sanitizedText(route('projects.edit.scientific', project.id))"
                    >
                        {{$t('edit')}}
                        <FontAwesomeIcon
                            :icon="faPencil"
                            size="lg"
                            class="text-white"
                        />
                    </Link>
                    <Link
                        v-if="canEditScientificInfo(user.role, user.userId, project.participantPersons)"
                        class="flex justify-between btn-custom hover:bg-opacity-100 w-full lg:ml-8 mt-2 lg:mt-0 lg:w-72 py-3 px-4"
                        :href="route('projects.notification.create', project.id)"

                    >
                        {{$t('create_notification')}}
                        <FontAwesomeIcon
                            :icon="faBell"
                            size="lg"
                            class="text-white"
                        />
                    </Link>
                    <Link
                        v-if="!project.scientificInfoValidated && canEditScientificInfo(user.role, user.userId, project.participantPersons)"
                        class="flex justify-between btn-custom bg-agree hover:bg-opacity-100 w-full lg:ml-8 mt-2 lg:mt-0 lg:w-72 py-3 px-4"
                        :href="sanitizedText(route('projects.validate.scientific', project.id))"

                    >
                        {{$t('validate_scientific')}}
                        <FontAwesomeIcon
                            :icon="faCheck"
                            size="lg"
                            class="text-white"
                        />
                    </Link>
                </div>
                <div class="mb-6" v-if="languagePt && project.simplifiedDescriptionPt">
                    <p class="font-bold text-fculColor">{{$t('simplified_description')}}</p>
                    <ShowHtmlContent :content="project.simplifiedDescriptionPt"/>
                </div>
                <div class="mb-6" >
                    <p class="font-bold text-fculColor">{{$t('simplified_description')}}</p>
                    <ShowHtmlContent :content="project.simplifiedDescriptionEn"/>
                </div>
                <div
                    class="mb-6"
                >
                  <div class="flex mb-2 lg:mt-0 mt-4 flex-col lg:flex-row">
                    <p class="w-full lg:w-1/2  font-bold text-fculColor">{{$t('keywords_single')}}</p>
                    <p class="w-full lg:w-1/2  font-bold text-fculColor lg:text-end">{{$t('keywords_single_other_idiom')}}</p>
                  </div>
                  <ProjectPropertyContainer class="text-black">
                    <ProjectKeyword
                        v-for="keyword in project.keywords"
                        :keyword="keyword"
                    />

                  </ProjectPropertyContainer>
                </div>
                <div class="mb-6" v-if="languagePt &&  project.scientificDescriptionPt">
                    <p class="font-bold text-fculColor">{{$t('scientific_description')}}</p>
                    <ShowHtmlContent :content="project.scientificDescriptionPt"/>
                </div>
                <div class="mb-8" v-else>
                    <p class="font-bold text-fculColor">{{$t('scientific_description')}}</p>
                    <ShowHtmlContent :content="project.scientificDescriptionEn"/>
                </div>
                <div class="flex justify-between w-full lg:w-1/3 mb-4 flex-col lg:flex-row">
                    <div class="flex mb-4 lg:mb-0">
                        <p class="font-bold text-fculColor">{{$t('start_trl')}}:</p>
                        <p class="ml-2 whitespace-nowrap text-black">{{project.startTRL}}</p>
                    </div>
                    <div class="flex">
                        <p class="font-bold text-fculColor">{{$t('target_trl')}}:</p>
                        <p class="ml-2 whitespace-nowrap text-black">{{project.targetTRL}}</p>
                    </div>
                </div>

                <ProjectPropertyContainer
                    :property-name="$t('scientific_areas')"
                    class="mb-6"
                >
                    <ProjectProperty
                        v-for="scientificArea in project.scientificAreas"
                        :value="scientificArea.designation"
                    />
                </ProjectPropertyContainer>

                <ProjectPropertyContainer
                    :property-name="$t('social_economical_categories')"
                    class="mb-6"
                >
                    <ProjectProperty
                        v-for="socialEconomyCategory in project.socialEconomyCategories"
                        :value="socialEconomyCategory.designation"
                    />
                </ProjectPropertyContainer>

                <ProjectPropertyContainer
                    :property-name="$t('sustainable_development_goals')"
                    class="mb-6"
                >
                    <ProjectProperty
                        v-for="sustainableGoal in project.sustainableDevelopmentGoals"
                        :value="sustainableGoal.designation"
                    />
                </ProjectPropertyContainer>

                <ProjectPropertyContainer
                    :property-name="$t('helpful_hyperlinks')"
                    class="mb-6"
                >
                    <ProjectProperty
                        v-for="helpfulHyperlink in project.helpfulHyperlinks"
                        :value="`${helpfulHyperlink.hyperlinkDesignation} - <a target='_blank' href='${helpfulHyperlink.helpfulHyperlink.includes('https://') || helpfulHyperlink.helpfulHyperlink.includes('http://')? helpfulHyperlink.helpfulHyperlink : 'https://' + helpfulHyperlink.helpfulHyperlink}' >${helpfulHyperlink.helpfulHyperlink}</a>`"
                    />
                </ProjectPropertyContainer>

                <ProjectPropertyContainer
                    :property-name="$t('rewards')"
                    class="mb-6"
                >
                    <ProjectProperty
                        v-for="reward in project.rewards"
                        :value="sanitizedText(`${reward.rewardDesignation} - <a href='${reward.rewardHyperlink.includes('https://')? reward.rewardHyperlink : 'https://' + reward.rewardHyperlink}' >${reward.rewardHyperlink}</a>`)"
                    />
                </ProjectPropertyContainer>

                <ProjectTextBlock
                    v-if="languagePt && project.impactPt"
                    :title="$t('impact')"
                    :text="project.impactPt"
                />

                <ProjectTextBlock
                    v-else
                    :title="$t('impact')"
                    :text="project.impactEn"
                />

                <ProjectTextBlock
                    v-if="languagePt && project.ethicPt"
                    :title="$t('ethic')"
                    :text="project.ethicPt"
                />

                <ProjectTextBlock
                    v-else
                    :title="$t('ethic')"
                    :text="project.ethicEn"
                />

                <ProjectTextBlock
                    v-if="languagePt && project.personalDataManagementPlanPt"
                    :title="$t('personal_data_management_plan')"
                    :text="project.personalDataManagementPlanPt"
                />

                <ProjectTextBlock
                    v-else
                    :title="$t('personal_data_management_plan')"
                    :text="project.personalDataManagementPlanEn"
                />
                <ProjectPropertyContainer
                    class="mb-8"
                    :property-name="$t('documents')"
                >
                  <ProjectProperty
                      v-for="document in project.documents"
                      :value="document.designation"
                      :clickable="true"
                      @on-click="args => downloadDocument(document)"
                  />

                </ProjectPropertyContainer>
            </div>
            </Separator>
            <Separator
              class="mb-8 w-full" :separator-name="$t('participants')"
          >
              <div class="flex w-full justify-end mb-4 items-center flex-col lg:flex-row">

                <Link
                    v-if="canEditProjectCloseInfo(user?.role)"
                    class="flex justify-between btn-custom  hover:bg-opacity-80 w-full lg:w-32 py-3 px-4 mt-8 lg:mt-0 mb-4 lg:mb-0"
                    :href="sanitizedText(route('projects.edit.participant-entities', project.id))"
                >
                  {{$t('edit')}}
                  <FontAwesomeIcon
                      :icon="faPencil"
                      size="lg"
                      class="text-white"
                  />
                </Link>
              </div>

              <ProjectSection
                class="mb-4"
                v-if="project.participantEntities && project.participantEntities.length > 0"
                v-for="entity in project.participantEntities"
                :section-title="entity.participantEntity"
            >

              <ProjectContainerProperty

              >
                <div class="flex flex-wrap w-full gap-y-2 gap-x-12 justify-between my-2 px-4 lg:px-8">
                  <div class="flex lg:items-center flex-col lg:flex-row" v-if="entity?.participantEntityFinancedValue && entity?.participantEntityFinancedValue !== ''">
                    <p>{{$t('participant_entity_financed_value')}}: </p>
                    <p class="pl-2 font-medium" >{{formater(entity.participantEntityFinancedValue)}} €</p>
                  </div>
                  <div class="flex lg:items-center flex-col lg:flex-row" v-if="entity?.participantEntityBudgetValue && entity?.participantEntityBudgetValue !== ''">
                    <p>{{$t('participant_entity_budget_value')}}: </p>
                    <p class="pl-2 font-medium" >{{formater(entity.participantEntityBudgetValue)}} €</p>
                  </div>
                  <div class="flex max-w-lg flex-col lg:flex-row" >
                    <p>{{$t('participation_role_single_entities')}}: </p>
                    <p class="pl-2 font-medium" >{{entity.participationEntityRole}}</p>
                  </div>
                  <div class="flex max-w-lg flex-col lg:flex-row" v-if="entity?.participationResponsibleId?.designation && entity?.participationResponsibleId?.designation !== ' '">
                    <p>{{$t('participant_entities_responsible')}}: </p>
                    <p class="pl-2 font-medium" >{{entity?.participationResponsibleId?.designation ?? ""}}</p>
                  </div>
                  <div class="flex lg:items-center flex-col lg:flex-row" v-if="entity.participationEntityOverheads && entity.participationEntityOverheads !=0">
                    <p>{{$t('participant_entity_participation_overheads')}}: </p>
                    <p class="pl-2 font-medium" >{{entity.participationEntityOverheads}} €</p>
                  </div>
                  <div class="flex lg:items-center flex-col lg:flex-row" v-if="entity?.participationResponsible?.designation">
                    <p>{{$t('participant_entities_responsible')}}: </p>
                    <p class="pl-2 font-medium" >{{entity?.participationResponsible?.designation}}</p>
                  </div>
                </div>
                <ProjectPropertyContainer
                    class="mt-4"
                    v-if="
                        project.participantEntities && project.participantEntities.length > 0 && (
                         project.participantEntities?.find(x => x.participantEntityId == fculEntity.id) && fculEntity.id == entity.participantEntityId
                        || project.participantEntities?.find(x => x.participantEntityId == fcienciasEntity.id) && project.participantEntities?.every(x => x.participantEntityId != fculEntity.id) && fcienciasEntity.id == entity.participantEntityId
                        || project.participantEntities?.find(x => x.participantEntityId === 3) && project.participantEntities?.find.every(x => x.participantEntityId !== fcienciasEntity.id && x.participantEntityId !== fculEntity.id) && 3 == entity.participantEntityId
                        )
                    "
                    :size="INPUT_SIZES.LARGE"
                >
                  <ProjectContainerProperty
                      v-for="person in project.participantPersons"
                  >
                    <div class="flex w-full justify-between mb-2 flex-col lg:flex-row">
                      <div class="flex max-w-lg flex-col lg:flex-row">
                        <p class="font-bold" >{{person.participantName}}</p>
                      </div>
                      <div class="flex lg:items-center flex-col lg:flex-row">
                        <p>{{$t('participation_type')}}: </p>
                        <p class="pl-2 font-medium" >{{person.participationType}}</p>
                      </div>
                    </div>
                    <div class="flex w-full justify-between flex-col lg:flex-row">
                      <div class="flex flex-col lg:flex-row">
                        <div class="flex max-w-lg flex-row">
                          <p>{{$t('from')}}: </p>
                          <p class="pl-2 font-medium" >{{person.dateStart}}</p>
                          <p class="pl-2">{{$t('to')}}</p>
                          <p class="pl-2 font-medium" >{{person.dateEnd}}</p>
                        </div>
                      </div>
                      <div class="flex max-w-lg ">
                        <p>{{$t('participant_dedication')}}: </p>
                        <p class="pl-2 font-medium" >{{person.dedicationPercentage}}%</p>
                      </div>
                    </div>
                  </ProjectContainerProperty>
                </ProjectPropertyContainer>
              </ProjectContainerProperty>
            </ProjectSection>
          </Separator>
            <Separator
              class="mb-8 w-full" :separator-name="$t('financing')"
          >
            <ProjectSection>
              <div class="flex w-full justify-end mb-4 items-center flex-col lg:flex-row">
                <Link
                    v-if="canEditProjectCloseInfo(user?.role)"
                    class="flex justify-between btn-custom bg-opacity-80 hover:bg-opacity-100 w-full lg:w-32 py-3 px-4 mt-8 lg:mt-0 mb-4 lg:mb-0"
                    :href="sanitizedText(route('projects.edit.financing', project.id))"
                >
                  {{$t('edit')}}
                  <FontAwesomeIcon
                      :icon="faPencil"
                      size="lg"
                      class="text-white"
                  />
                </Link>
              </div>
              <div>
                <div class="flex flex-col lg:flex-row w-full text-black mb-2">
                  <InfoItem
                      class="mb-4 lg:mb-0  mt-2 "
                      :label="$t('budget_value_total')"
                      :value="`${formater(project.totalBudgetValue)} €`"
                      :with-popup="true"
                      :pop-up-name="$t('budget_value_total')"
                  >
                      <div class="mt-2 flex flex-col w-full h-full max-h-32 overflow-y-scroll">
                          <ProjectProperty
                              v-for=" (value, index) in projectBudgetValues"
                              :key="index"
                              :value="`${index === 0? $t('current_value') : ''} ${value.value} € <br> ${value.modifyDate}`"
                              :col="true"
                              :clickable="true"
                              :second-value-label="`${$t('budget_value_total_observation')}: `"
                              :second-value="value.showObsv?  value.observations: ''"
                              @onClick="() => {
                                    if(value.showObsv) {
                                        value.showObsv = undefined
                                    } else {
                                        value.showObsv = true
                                    }
                                }"
                          />
                      </div>
                  </InfoItem>
                  <InfoItem
                      class="mb-4 lg:mb-0  mt-2 lg:ml-6"
                      :label="$t('financing_value_total')"
                      :value="`${formater(project.totalFinancingValue)} €`"
                      :with-popup="true"
                      :pop-up-name="$t('financing_value_total')"
                  >
                      <div class="mt-2 flex flex-col w-full h-full max-h-32 overflow-y-scroll">
                          <ProjectProperty
                              v-for=" (value, index) in projectFinancedValues"
                              :key="index"
                              :value="`${index === 0? $t('current_value') : ''} ${value.value} € <br> ${value.modifyDate}`"
                              :col="true"
                              :clickable="true"
                              :second-value-label="`${$t('financing_value_total_observation')}: `"
                              :second-value="value.showObsv?  value.observations : ''"
                              @onClick="() => {
                                    if(value.showObsv) {
                                        value.showObsv = undefined
                                    } else {
                                        value.showObsv = true
                                    }
                                }"
                          />
                      </div>
                  </InfoItem>
                </div>
                <ProjectPropertyContainer
                    :property-name="$t('financing_entities')"
                    class="mb-6"
                >
                  <ProjectProperty
                      v-for="entity in project.financingEntities"
                      :value="$t(entity.designation)"
                      :second-value-label="`${$t('financing_entities_percentage')}:`"
                      :second-value="entity.financingEntityPercentage? `${entity.financingEntityPercentage}%` : '' "
                      :clickable="true"
                      @on-click="() => {
                                if(entity.type === 'projeto') {
                                    router.visit(route('projects.show', entity.entityId), {
                                        preserveScroll: true
                                    });
                                } else {
                                    windows.location.href = sanitizedText(gremius + entity.entityId)
                                }
                            }"
                  />

                </ProjectPropertyContainer>
                <ProjectPropertyContainer
                    :property-name="$t('financing_programs')"
                    class="mb-4"
                >
                  <ProjectProperty
                      v-for="financingProgram in project.financingPrograms"
                      :value="financingProgram.designation"
                  />

                </ProjectPropertyContainer>

                <div
                    class="mb-6"
                    v-if="project.financingPrograms && project.financingPrograms.length > 0"
                >
                  <p class="text-fculColor font-bold ">{{$t('financing_programs_value_distribution')}}</p>
                  <div
                      class="pl-8 pt-6 pb-8 mb-2 rounded-lg bg-fculColor bg-opacity-80 w-full flex flex-col overflow-scroll  lg:overflow-hidden"
                      v-for="financingProgram in project.financingPrograms"
                  >
                    <ProjectRecursiveContainer
                        :title="`${financingProgram.designation}`"
                        :financing-program="financingProgram"
                    />
                  </div>
                </div>
                <div
                    class="mb-6 "
                >
                  <p class="text-fculColor font-bold ">{{$t('fcul_decomposition_models')}}</p>
                  <div
                      class="pl-8 pt-6 pb-8 mb-2 rounded-lg  bg-fculColor bg-opacity-80 w-full flex flex-col overflow-scroll lg:overflow-hidden"
                      v-for="decompositionModel in project.decompositionModels"
                  >
                    <ProjectRecursiveContainer
                        :title="`${decompositionModel.designation}`"
                        :financing-program="decompositionModel"
                    />
                  </div>
                </div>
                <div class="flex flex-col lg:flex-row">
                  <ProjectPropertyContainer
                      :property-name="$t('project_list_fcul')"
                      v-slot="listProps"
                      class="lg:mr-2 w-full h-full lg:w-1/3"
                  >
                    <ProjectProperty
                        v-for="costCenter in project.fculCostCenters"
                        :clickable="true"
                        :col="true"
                        :value="`${$t('project_id')}: ${costCenter.costCenterId}` + (listProps.childrenState.fcul === costCenter.costCenterId? `<br> ${$t('project_description')}: ${costCenter.projectDescription}` : '')"
                        @on-click="() => {
                                if(!listProps.childrenState.fcul){
                                    listProps.childrenState.fcul = costCenter.costCenterId
                                } else if (listProps.childrenState.fcul !== costCenter.costCenterId) {
                                    listProps.childrenState.fcul = costCenter.costCenterId
                                } else {
                                    delete listProps.childrenState.fcul
                                }
                            }"
                    />

                  </ProjectPropertyContainer>
                  <ProjectPropertyContainer
                      :property-name="$t('project_list_fciencias')"
                      v-slot="listProps"
                      class="lg:mx-2 w-full h-full lg:w-1/3 flex flex-col justify-between"
                  >
                    <ProjectProperty
                        v-for="costCenter in project.fCienciasCostCenters"
                        :clickable="true"
                        :col="true"
                        :value="`${$t('project_id')}: ${costCenter.costCenterId}` + (listProps.childrenState.fciencias === costCenter.costCenterId? `<br> ${$t('project_description')}: ${costCenter.projectDescription}` : '')"
                        @on-click="() => {
                                if(!listProps.childrenState.fciencias){
                                    listProps.childrenState.fciencias = costCenter.costCenterId
                                } else if (listProps.childrenState.fciencias !== costCenter.costCenterId) {
                                    listProps.childrenState.fciencias = costCenter.costCenterId
                                } else {
                                    delete listProps.childrenState.fciencias
                                }
                            }"
                    />
                  </ProjectPropertyContainer>
                  <ProjectPropertyContainer
                      :property-name="$t('project_list_ffcul')"
                      v-slot="listProps"
                      class="lg:mx-2 w-full h-full lg:w-1/3 flex flex-col justify-between"
                  >
                    <ProjectProperty
                        v-for="costCenter in project.fFculCostCenters"
                        :clickable="true"
                        :value="`${$t('project_id')}: ${costCenter.costCenterId}`"
                        :second-value-label="listProps.childrenState.ffcul === costCenter.costCenterId? $t('project_description') : undefined"
                        :second-value="listProps.childrenState.ffcul === costCenter.costCenterId? costCenter.projectDescription : undefined"
                        :col="true"
                        @on-click="() => {
                                if(!listProps.childrenState.ffcul){
                                    listProps.childrenState.ffcul = costCenter.costCenterId
                                } else if (listProps.childrenState.ffcul !== costCenter.costCenterId) {
                                    listProps.childrenState.ffcul = costCenter.costCenterId
                                } else {
                                    delete listProps.childrenState.ffcul
                                }
                            }"
                    />
                  </ProjectPropertyContainer>
                </div>
              </div>
            </ProjectSection>
          </Separator>
            <Separator
                class="mb-8 w-full" :separator-name="$t('structures_ulisboa')"
            >
              <div class="flex w-full justify-end mb-4 items-center lg:flex-row">
                <Link
                    v-if="canEditProjectCloseInfo(user?.role)"
                    class="flex justify-between btn-custom bg-opacity-80 hover:bg-opacity-100 w-full lg:w-32 py-3 px-4 mt-8 lg:mt-0 mb-4 lg:mb-0"
                    :href="sanitizedText(route('projects.edit.ulisboa-entities', project.id))"
                >
                  {{$t('edit')}}
                  <FontAwesomeIcon
                      :icon="faPencil"
                      size="lg"
                      class="text-white"
                  />
                </Link>
              </div>
              <ProjectSection class="mb-4">
                <div class="flex w-full justify-between mb-4 items-center flex-col lg:flex-row">
                    <p class="font-bold text-2xl text-fculColor ">{{$t('departments')}}</p>
                </div>
                <ProjectPropertyContainer
                >
                    <ProjectProperty
                        class="hover:bg-fculColor hover:bg-opacity-80 hover:text-white cursor-pointer"
                        v-for="department in project.departments"
                        :value="department.designation"
                        @click="() => navigateTo(department.id)"
                    />

                </ProjectPropertyContainer>
              </ProjectSection>
              <ProjectSection class="mb-4">
                <div class="flex w-full justify-between mb-4 items-center flex-col lg:flex-row">
                  <p class="font-bold text-2xl text-fculColor ">{{$t('i_and_d_structures')}}</p>
                </div>
                <ProjectPropertyContainer
                >
                  <ProjectProperty
                      class="hover:bg-fculColor hover:bg-opacity-80 hover:text-white cursor-pointer"
                      v-for="investigationStructure in project.investigationStructures"
                      :value="investigationStructure.designation"
                      @click="() => navigateTo(investigationStructure.id)"
                  />

                </ProjectPropertyContainer>
              </ProjectSection>
              <ProjectSection class="mb-4">
                <div class="flex w-full justify-between mb-4 items-center flex-col lg:flex-row">
                  <p class="font-bold text-2xl text-fculColor ">{{$t('service_unit_single')}}</p>
                </div>
                <ProjectPropertyContainer
                >
                  <ProjectProperty
                      class="hover:bg-fculColor hover:bg-opacity-80 hover:text-white cursor-pointer"
                      v-for="serviceUnit in project.serviceUnits"
                      :value="serviceUnit.designation"
                      @click="() => navigateTo(serviceUnit.id)"
                  />

                </ProjectPropertyContainer>
              </ProjectSection>
              <ProjectSection>
                <div class="flex w-full justify-between mb-4 items-center flex-col lg:flex-row">
                  <p class="font-bold text-2xl text-fculColor ">{{$t('other_internal_entities')}}</p>
                </div>
                <ProjectPropertyContainer
                >
                  <ProjectProperty
                      class="hover:bg-fculColor hover:bg-opacity-80 hover:text-white cursor-pointer"
                      v-for="otherInternalEntity in project.otherInternalEntities"
                      :value="otherInternalEntity.designation"
                      @click="() => navigateTo(otherInternalEntity.id)"
                  />

                </ProjectPropertyContainer>
              </ProjectSection>
            </Separator>

        </div>
    </div>
</template>

<script setup>
    import {getActiveLanguage} from "laravel-vue-i18n";
    import {getCurrentInstance, onMounted, onUnmounted, ref} from "vue";
    import {Link, router} from "@inertiajs/vue3";
    import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
    import {faBell, faCheck, faCircleArrowRight, faPencil} from "@fortawesome/free-solid-svg-icons";
    import InfoItem from "../../Components/Projeto/InfoItem.vue";
    import ProjectKeyword from "../../Components/Projeto/ProjectKeyword.vue";
    import ProjectProperty from "../../Components/Projeto/ProjectProperty.vue";
    import ProjectPropertyContainer from "../../Components/Projeto/ProjectPropertyContainer.vue";
    import ProjectTextBlock from "../../Components/Projeto/ProjectTextBlock.vue";
    import ProjectSection from "../../Components/Projeto/ProjectSection.vue";
    const {proxy} = getCurrentInstance()
    const emitter = proxy.emitter
    const windows = proxy.window
    const gremius = "https://gremius.ciencias.ulisboa.pt/entities/external/entity/"
    import {INPUT_SIZES} from "../../Components/Input.vue";
    import ProjectContainerProperty from "../../Components/Projeto/ProjectContainerProperty.vue";
    import download from "downloadjs";
    import ProjectRecursiveContainer from "../../Components/Projeto/ProjectRecursiveContainer.vue";
    import {useUser} from "../../Components/hooks/useUser.js";
    import {canEditProjectCloseInfo, canEditScientificInfo} from "../../Authorizations.js";
    import Separator from "../../Components/Projeto/Separator.vue";
    import DOMPurify from "dompurify";
    import base64 from "base-64";
    import ShowHtmlContent from "../../Components/ShowHtmlContent.vue";
    import {formater} from "../../Utils.js";

    const { user, isAuthenticated } = useUser()



    const props = defineProps({
        project: Object,
        fculEntity: Object,
        fcienciasEntity: Object,
        projectDates: Array,
        projectBudgetValues: Array,
        projectFinancedValues: Array
    })

    const languagePt = ref(getActiveLanguage() === 'pt')

    onMounted(() => {
        emitter.on('languageChange', () => {
            languagePt.value = getActiveLanguage() === 'pt'
        })
    })

    onUnmounted(() => {
        emitter.off('languageChange')
    })

    const downloadDocument = (document) => {

        download(base64.decode(document.document), `${document.designation}.${document.format}`)
    }

    const navigateTo = (href) => {
      window.location.href = sanitizedText('https://gremius.ciencias.ulisboa.pt/entities/internal/entity/' + href)
    }


    const sanitizedText = (text) => {
        return DOMPurify.sanitize(text);
    }

</script>

