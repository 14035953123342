<template>
  <div class=" w-full flex  flex-col justify-center items-center">
    <img :src="imagePath" alt="Error image" class="image" />
    <div class="flex items-center flex-col">
      <h1 class="font-bold text-8xl text-fculColor my-8">{{ statusCode }}</h1>
      <h1 class="font-bold text-4xl text-fculColor">
        {{ $t(errorMessage) }}
      </h1>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  statusCode: Number,
  errorMessage: String,
});

const imagePath = "/images/Error_image.png";
</script>

<style>
.image {
  width: 40%;
}
</style>
