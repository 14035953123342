<template>
    <div class="w-full flex flex-col">
        <div class="w-full pt-8 lg:pt-8  pb-4 lg:pb-4  pl-8 pr-4 lg:pr-8 bg-background flex flex-col lg:flex-row  items-end lg:items-center justify-between">
            <div class="flex items-center mb-2 lg:mb-0 justify-end">
                <Link
                    class="cursor-pointer hidden lg:block"
                    :href="sanitizedReturnTo"
                >
                    <FontAwesomeIcon :icon="faArrowLeft" class="mr-6 text-fculColor hover:text-opacity-60" size="lg"/>
                </Link>
                <div class="flex flex-wrap justify-end lg:justify-start w-11/12">
                    <p
                        v-for="word in listName.split(' ')"
                        class="font-bold text-3xl text-fculColor text-end ml-2"
                    >{{word}}</p>
                </div>
            </div>
            <SearchBar
                v-if="allowSearch"
                class="w-full lg:w-1/2 lg:ml-4 lg:mr-16 my-4 lg:my-0"
                :label="$t('search')"
                :model-value="searchValue"
                @on-search="(value) => emit('search', value)"
            />
            <Link class="py-1.5 px-8 bg-fculColor rounded-lg drop-shadow-xl shadow-black font-bold text-xl text-white cursor-pointer hover:bg-opacity-60"
                  :href="sanitizedAddRouteTo"
                  v-if="addRoute"
            >+</Link>
        </div>
        <div class="w-full h-full lg:pl-8 lg:mt-2 pl-4 pr-4 lg:pr-8 pt-2 overflow-y-scroll scrollbar">
           <slot></slot>
        </div>
    </div>
</template>

<script setup>
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {Link} from "@inertiajs/vue3";
import {computed} from "vue";
import DOMPurify from "dompurify";
import SearchBar from "../SearchBar.vue";

const props = defineProps({
    listName: String,
    returnRoute: String,
    addRoute: String,
    allowSearch: Boolean,
    searchValue: String
})

const emit = defineEmits(["search"]);

const sanitizedReturnTo = computed(() => {
    return DOMPurify.sanitize(props.returnRoute);
});

const sanitizedAddRouteTo = computed(() => {
    return DOMPurify.sanitize(props.addRoute);
});

</script>
