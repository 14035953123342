<template>
    <HorizontalList
        :list-name="$t('notifications')"
        :return-route="route('projects.index')"
    >
        <HorizontalListItem
            class="hover:bg-fculColor hover:text-white hover:bg-opacity-80 cursor-pointer white"
            v-for="notification in notifications"
            :title="`${$t(notification.designation)}${languagePt? (notification.projectNamePt ?? notification.projectNameEn) : (notification.projectNameEn ?? notification.projectNamePt)}`"
            @click="visitProject(notification.projectId)"
        />
    </HorizontalList>

</template>

<script setup>

import HorizontalListItem from "../../Components/HorizontalList/HorizontalListItem.vue";
import HorizontalList from "../../Components/HorizontalList/HorizontalList.vue";
import {getCurrentInstance, onMounted, onUnmounted, ref} from "vue";
import {getActiveLanguage} from "laravel-vue-i18n";
import {router} from "@inertiajs/vue3";
const {proxy} = getCurrentInstance()
const emitter = proxy.emitter
const windows = proxy.window

const languagePt = ref(getActiveLanguage() === 'pt')

onMounted(() => {
    emitter.on('languageChange', () => {
        languagePt.value = getActiveLanguage() === 'pt'
    })
})

onUnmounted(() => {
    emitter.off('languageChange')
})

const props = defineProps({
    notifications: Object,
})

const visitProject = (projectId) => {
    router.visit(route('projects.show', projectId))
}

</script>
