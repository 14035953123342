import {usePage} from "@inertiajs/vue3";
import {computed} from "vue";

export function useUser() {
    const page = usePage();

    const user = computed(() => page.props.auth.user)
    const isAuthenticated = computed(() => page.props.auth.isAuthenticated)

    return { user, isAuthenticated }
}
