<template>
  <div
      class="flex justify-between my-2 text-black first:mt-0 last:mb-0 w-full py-2 px-4 bg-white font-bold rounded-lg"
       :key="value"
      @click="onClick"
      :class="{
          'cursor-pointer': clickable,
          'hover:bg-fculColor': clickable,
          'hover:text-white': clickable,
          'lg:flex-row': !col,
          'lg:flex-col': col
      }"
  >
    <p class="overflow-hidden" v-html="value"></p>
    <p class="max-w-full break-all" v-if="secondValue">{{secondValueLabel ?? ""}} {{secondValue}}</p>
  </div>
</template>

<script setup>
    import {computed} from "vue";
    import DOMPurify from "dompurify";

    const props = defineProps({
        value: String,
        secondValueLabel: String,
        secondValue: String,
        clickable: Boolean,
        col: Boolean,
    })

    const emit = defineEmits(['onClick'])

    const onClick = () => {
        emit('onClick')
    }
</script>
