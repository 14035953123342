<template>
    <div class="w-full h-full overflow-hidden max-h-full flex flex-col ">
        <div class="w-full pt-10 lg:pt-6  pb-8 lg:pb-4  pl-8 pr-8 bg-background flex flex-col lg:flex-row  items-center justify-between">
            <h1 class="font-bold text-3xl text-fculColor ">{{$t('metrics')}}</h1>
          <div v-if="isAuthenticated" class="hidden lg:flex flex-col">
            <p class="font-bold text-fculColor ">{{ user?.userName }}</p>
            <p class="text-sm text-fculColor">{{ user?.userEmail }}</p>
          </div>
        </div>
        <div class="w-full flex pt-4 pb-8 lg:px-8 px-2 flex-wrap gap-8 overflow-y-scroll justify-evenly h-full scrollbar">
            <div class="bg-white w-full h-128 lg:h-96 px-6 py-4 rounded-lg drop-shadow-xl ">
                <div class="font-bold  flex justify-between items-center">
                    <div></div>
                    <p class="col-span-2">{{$t('project_scopes')}}</p>
                    <FontAwesomeIcon
                        :icon="faFilter"
                        class="btn-custom w-16 py-3 px-1 bg-fculColor text-white"
                        @click="() => scopesFiltersOpened = !scopesFiltersOpened"
                    />
                </div>
                <div v-if="scopesFiltersOpened" class="bg-background bg-opacity-80 absolute right-6 w-72 h-72 drop-shadow rounded-lg mt-2 py-2 pl-2  overflow-hidden">
                    <div class="h-full overflow-y-scroll">
                        <div class="flex justify-between ">
                            <p class="text-lg font-bold text-black">
                                {{$t('filters')}}
                            </p>
                            <div class="flex">

                                <button
                                    class="px-3  flex justify-center items-center bg-decline rounded-lg  font-bold text-sm text-white cursor-pointer hover:bg-opacity-60"
                                    @click="() => {
                                clearFilters()
                            }"
                                >
                                    {{$t('clear')}}
                                </button>
                            </div>
                        </div>
                        <div>
                            <label class="mb-1 mt-2 pl-2 font-bold text-black">{{$t('date_start')}}</label>
                            <input
                                class=" px-4 my-1.5 py-2 rounded-lg drop-shadow-xl bg-white shadow-black text-black w-full"
                                v-model="startDate"
                                @input="() => {
                                if(startDate === '') {
                                    onDelayedSearch('', 'startDate')
                                }

                                const date  = new Date(startDate);
                                if (date.getFullYear() > 1000) {
                                    onDelayedSearch(startDate, 'startDate')
                                }
                            }"
                                type="date"
                            >
                        </div>
                        <div>
                            <label class="mb-1 mt-2 pl-2 font-bold text-black">{{$t('date_end')}}</label>
                            <input
                                class=" px-4 my-1.5 py-2 rounded-lg drop-shadow-xl bg-white shadow-black text-black w-full"
                                v-model="endDate"
                                @input="() => {
                                if(endDate === '') {
                                    onDelayedSearch('', 'endDate')
                                }

                                const date  = new Date(endDate);
                                if (date.getFullYear() > 1000) {
                                    onDelayedSearch(endDate, 'endDate')
                                }
                            }"
                                type="date"
                            >
                        </div>
                        <MultiListFilter
                            :title="$t('scientific_areas')"
                            :model-value-search="scientificAreaSearch"
                            :list="scientificAreas"
                            :selected-on-list="selectedScientificArea"
                            @on-delay-search="(value) => onDelayedSearch(value, 'scientificArea')"
                            @on-search="(value) => onSearch(value, 'scientificAreas')"
                        />
                        <MultiListFilter
                            :title="$t('departments')"
                            :model-value-search="departmentSearch"
                            :list="departments"
                            :selected-on-list="selectedDepartments"
                            @on-delay-search="(value) => onDelayedSearch(value, 'department')"
                            @on-search="(value) => onSearch(value, 'departments')"
                        />
                        <MultiListFilter
                            :title="$t('i_and_d_structures')"
                            :model-value-search="investigationStructureSearch"
                            :list="investigationStructures"
                            :selected-on-list="selectedInvestigationStructures"
                            @on-delay-search="(value) => onDelayedSearch(value, 'investigationStructure')"
                            @on-search="(value) => onSearch(value, 'investigationStructures')"
                        />
                        <MultiListFilter
                            :title="$t('financing_programs')"
                            :model-value-search="financingProgramSearch"
                            :list="financingPrograms"
                            :selected-on-list="selectedFinancingProgram"
                            @on-delay-search="(value) => onDelayedSearch(value, 'financingProgram')"
                            @on-search="(value) => onSearch(value, 'financingPrograms')"
                        />
                    </div>
                </div>
                <div
                    class="flex w-full h-full pb-10  justify-center items-center chart-container"

                >
                    <LineChart
                        :labels="scopes.labels"
                        :data="scopes.scopes.map(item => ({
                            label: item.scopeDesignation,
                            backgroundColor: item.color,
                            borderColor: item.color,
                            data: item.projectsCount,
                            extra: item.scopeId,
                            hidden: disabledScopes.includes(item.scopeId)
                        }))"
                        @on-label-click="(value) => {
                            changeEnabledScopes(value)
                        }"
                        :y-axis-name="() => $t('number_projects')"
                    />
                </div>
            </div>
            <div class="bg-white w-128 h-64 px-6 py-4 rounded-lg drop-shadow-xl overflow-hidden">
                <div class="font-bold  grid grid-cols-3 justify-items-end items-center">
                <p class="col-span-2">{{$t('projects_values')}}</p>
                </div>
                <FinancingChart
                    :financing-values="financingValues"
                    @on-year-change="(e) => changeYear(e)"
                />
            </div>
            <div class="bg-white w-128 h-64 px-6 py-4 rounded-lg drop-shadow-xl"
            >
                <div class="font-bold h-full flex flex-col justify-items-end items-center">
                    <p class="col-span-2">{{$t('projects_country')}}</p>

                    <CountriesChart
                        :countries="countries"
                        y-axis-name="Número de projetos"
                    />
                </div>
            </div>
        </div>

    </div>
</template>

<script setup >
import {ref} from "vue";
import FinancingChart from "../../Components/Charts/FinancingChart.vue"
import {faFilter} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {router} from "@inertiajs/vue3";
import CountriesChart from "../../Components/Charts/CountriesChart.vue";
import LineChart from "../../Components/Charts/LineChart.vue";
import Input from "../../Components/Input.vue";
import _ from "lodash";
import MultiListFilter from "../../Components/Projeto/Filters/MultiListFilter.vue";
import {useUser} from "../../Components/hooks/useUser.js";

const { isAuthenticated, user } = useUser()

const props = defineProps({
    scopes: Array,
    disabledScopes: Array,
    startDate: String,
    endDate: String,
    financingValues: Object,
    financingYearSelected: Number,
    countries: Object,
    scientificAreas: Array,
    scientificAreaSearch: String,
    selectedScientificArea: Array,
    departments: Array,
    departmentSearch: String,
    selectedDepartments: Array,
    investigationStructures: Array,
    investigationStructureSearch: String,
    selectedInvestigationStructures: Array,
    financingPrograms: Array,
    financingProgramSearch: String,
    selectedFinancingProgram: Array,
})


const scopesFiltersOpened = ref(false)

const queryParameters = ref({
    disabledScopes: JSON.stringify(props.disabledScopes),
    startDate: props.startDate,
    endDate: props.endDate,
    departments: JSON.stringify(props.selectedDepartments),
    scientificAreas: JSON.stringify(props.selectedScientificArea),
    investigationStructures: JSON.stringify(props.selectedInvestigationStructures),
    financingPrograms: JSON.stringify(props.selectedFinancingProgram)
})

const disabledScopes = ref(props.disabledScopes ?? [])

const startDate = ref(props.startDate ?? '')
const endDate = ref(props.endDate ?? '')

const changeEnabledScopes = (value) => {

    if (disabledScopes.value.includes(value)) {
        disabledScopes.value = disabledScopes.value.filter(x => x !== value)
    } else {
        disabledScopes.value.push(value)
    }


    queryParameters.value['disabledScopes'] = JSON.stringify(disabledScopes.value)

    for (const key of Object.keys(queryParameters.value)) {

        if (!queryParameters.value[key]
            || queryParameters.value[key] === null
            ||queryParameters.value[key] === ""
            || queryParameters.value[key] === "[]"
        ) {
            delete queryParameters.value[key]
        }
    }

    router.get(route('dashboard.index'), queryParameters.value, {
        preserveState: true,
    })
}

const changeYear = (year) => {
    queryParameters.value['financingYear'] = year

    router.get(route('dashboard.index'), queryParameters.value, {
        preserveState: true,
    })
}

const onDelayedSearch =  _.throttle((value, property) => {



    if (queryParameters.value && value === ""){
        delete queryParameters.value[property]
    } else {
        queryParameters.value[property] = value
    }

    for (const key of Object.keys(queryParameters.value)) {

        if (!queryParameters.value[key]
            || queryParameters.value[key] === null
            ||queryParameters.value[key] === ""
            || queryParameters.value[key] === "[]"
        ) {
            delete queryParameters.value[key]
        }
    }


    router.get(route('dashboard.index'), queryParameters.value, {
        preserveState: true,
    })
}, 1000)

const onSearch = (value, property) => {



    if (queryParameters.value && value === ""){
        delete queryParameters.value[property]
    } else {
        queryParameters.value[property] = value
    }

    for (const key of Object.keys(queryParameters.value)) {

        if (!queryParameters.value[key]
            || queryParameters.value[key] === null
            ||queryParameters.value[key] === ""
            || queryParameters.value[key] === "[]"
        ) {
            delete queryParameters.value[key]
        }
    }

    router.get(route('dashboard.index'), queryParameters.value, {
        preserveState: true,
    })
}

const clearFilters = () => {
    queryParameters.value = {}
    onDelayedSearch('', '')
    startDate.value = ''
    endDate.value = ''

}


</script>


