<template>
  <EditForm
    :title="$t('scientific_areas_edit')"
    :action-button-disabled="wasChanged === form.designation"
    :return-route="route('settings.scientific-area.index')"
    @submit="onSubmit"
  >
    <Input
        class="px-2"
        :label="$t('scientific_areas_single')"
        :error="form.errors.designation"
        :model-value="form.designation"
        @on-change="onChange"
    />
  </EditForm>


</template>

<script setup>

import EditForm from "../../../Components/Forms/EditForm.vue";
import Input from "../../../Components/Input.vue";
import { useForm} from "@inertiajs/vue3";
import {ref} from "vue";

const props = defineProps({
  scientificArea: Object
});

const wasChanged = ref(props.scientificArea.designation)

const form = useForm({
    designation: props.scientificArea.designation
});

const onChange = (value) => {
  if (form.errors.designation) {
      form.errors.designation = null;
  }
  form.designation = value;
};

const onSubmit = () => {
    form.put(route('settings.scientific-area.update', props.scientificArea.id));
};

</script>

