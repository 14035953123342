<template>
    <div class="w-full  flex flex-col max-h-screen overflow-hidden ">
        <div class=" w-full flex flex-col lg:flex-row pt-10 lg:pt-6  pb-6  pl-8 pr-8 bg-background items-center justify-between">
            <h1 class="font-bold text-3xl text-fculColor pb-8 pl-8 lg:pl-0 lg:pb-0">{{$t('researchers')}}</h1>
            <SearchBar
                class="w-full mx-8"
                @on-search="item => onDelayedSearch(item)"
                :placeholder="$t('search_in').replace('${replace}', investigatorCount)"
            />
            <div v-if="isAuthenticated" class="hidden lg:flex flex-col">
              <h1 class="font-bold text-fculColor ">{{ user?.userName }}</h1>
              <p class="text-sm text-fculColor">{{ user?.userEmail }}</p>
            </div>
        </div>
        <div
            v-if="investigators.length > 0"
            class="w-full h-full px-6  pt-6 justify-center sm:justify-start gap-x-3  overflow-y-scroll scrollbar justify-items-center"
        >
            <HorizontalListItem
                class="hover:bg-fculColor hover:text-white cursor-pointer"
                v-for="investigator in investigators"
                :key="investigator.investigatorId"
                :title="investigator.investigatorName"
                :sub-title="`${$t('projects_count')}: ${investigator.projectsCount}`"
                @click="visit(investigator.investigatorId)"
            />

        </div>
        <div
            class="w-full h-full flex justify-center items-center"
            v-else
        >
            <FontAwesomeIcon
                :icon="faSearch"
                size="8x"
                class="text-fculColor opacity-80"
            />
        </div>

    </div>
</template>

<script setup >

import SquareListContainerItem from "../../Components/SquareListContainerItem.vue";
import base64 from "base-64";
import {useUser} from "../../Components/hooks/useUser.js";
import SearchBar from "../../Components/SearchBar.vue";
import _ from "lodash";
import {router} from "@inertiajs/vue3";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import HorizontalListItem from "../../Components/HorizontalList/HorizontalListItem.vue";

const { user, isAuthenticated} = useUser()

const props = defineProps({
    investigators: Array,
    investigatorCount: Number
})

const onDelayedSearch =  _.throttle((value) => {
    router.get(route('researchers.index'), {search: value}, {
        preserveState: true
    })
}, 1000)


const visit = (id) => {
    router.visit(route('researchers.show', base64.encode(id)))
}

</script>


