<template>
  <div
      class="flex flex-col justify-between my-3 text-black first:mt-0 last:mb-0 w-full py-2 px-4 bg-white font-bold rounded-lg "
  >
      <slot></slot>
  </div>
</template>

<script setup>

</script>
