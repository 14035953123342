<template>
    <HorizontalList
        :list-name="$t('participation_types_entities')"
        :return-route="route('settings.index')"
        :add-route="route('settings.participation-role-entities.create')"
        :allow-search="true"
        @search="item => onDelayedSearch(item)"
    >
        <HorizontalListItem
            v-for="item in participationRolesEntities?.data"
            :title="item?.designation"
            :update-route="route('settings.participation-role-entities.edit', item?.id)"
            :delete-route=" route('settings.participation-role-entities.destroy', item?.id)"
            :toggle-item-route="route('settings.participation-role-entities.toggle',item?.id)"
            :item-active="item.active"
        />
        <HorizontalPagination :links="participationRolesEntities.links"/>
    </HorizontalList>

</template>

<script setup>
import HorizontalList from "../../../Components/HorizontalList/HorizontalList.vue";
import HorizontalListItem from "../../../Components/HorizontalList/HorizontalListItem.vue";
import HorizontalPagination from "../../../Components/HorizontalList/HorizontalPagination.vue";
import _ from "lodash";
import {router} from "@inertiajs/vue3";

const props = defineProps({
    participationRolesEntities: Object,
})

const onDelayedSearch =  _.throttle((value) => {
    router.get(route('settings.participation-role-entities.index'), {search: value}, {
        preserveState: true
    })
}, 1000)
</script>
