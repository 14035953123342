<template>
    <HorizontalList
        :list-name="$t('user_permissions')"
        :return-route="route('settings.index')"
        :add-route="route('settings.user-permissions.create')"
        :allow-search="true"
        @search="item => onDelayedSearch(item)"
    >
        <HorizontalListItem
            v-for="item in usersPermissions.data"
            :title="item?.userName"
            :sub-title="item?.roleName"
            :update-route="route('settings.user-permissions.edit', item?.id)"
            :delete-route=" route('settings.user-permissions.destroy', item?.id)"
            :item-active="item.active"
        />
        <HorizontalPagination :links="usersPermissions.links"/>
    </HorizontalList>

</template>

<script setup>
import HorizontalList from "../../../Components/HorizontalList/HorizontalList.vue";
import HorizontalListItem from "../../../Components/HorizontalList/HorizontalListItem.vue";
import _ from "lodash";
import {router} from "@inertiajs/vue3";
import HorizontalPagination from "../../../Components/HorizontalList/HorizontalPagination.vue";

const props = defineProps({
  usersPermissions: Array
})

const onDelayedSearch =  _.throttle((value) => {
    router.get(route('settings.user-permissions.index'), {search: value}, {
        preserveState: true
    })
}, 1000)

</script>
