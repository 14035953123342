<template>
  <div
      class=" bg-fculColor rounded-lg mt-2 bg-opacity-60"
  >
    <div
        class="bg-fculColor bg-opacity-60  flex items-center cursor-pointer hover:bg-opacity-100 overflow-hidden"
        :class="{
          'h-10': opened,
          'h-12': !opened,
          'rounded-t-lg': opened,
          'rounded-lg': !opened,
          'text-nowrap': opened
        }"
        @click="() => opened = !opened"
    >
      <label class="mb-1 mt-2 px-2 font-bold text-white cursor-pointer ">{{title}}</label>
    </div>
    <div v-if="opened" class="w-full flex flex-col p-2 h-72 items-center">
        <div class="mb-2">
            <h1 class="w-full text-white font-bold"> {{$t('participant_name')}}</h1>
            <Dropdown
              :input-size="INPUT_SIZES.XSMALL"
              :list="listPersons"
              :model-value="selectedPerson"
              @on-search="(value) => emit('onDelaySearch', {property:'fculMember', value: value})"
              @on-change="(value) => {
                  selectedPerson = value
                  emit('onSearch', {property:'selectedFculMember', value: selectedPerson?.id? base64.encode(selectedPerson.id) : ''})
              }"
            />
        </div>
        <div class="flex flex-col items-center">
            <h1 class="w-full text-white font-bold"> {{$t('participation_types')}}</h1>
            <Dropdown
                :input-size="INPUT_SIZES.XSMALL"
                :list="listParticipationRoles"
                :model-value="selectedParticipationRole"
                @on-search="(value) => emit('onDelaySearch', {property:'participationRole', value: value})"
                @on-change="(value) => {
                  selectedParticipationRole = value
                  emit('onSearch', {property:'selectedParticipationRole', value: selectedParticipationRole?.id ?? ''})
              }"
            />
        </div>
    </div>
  </div>
</template>

<script setup>
import {ref} from "vue";
import Dropdown from "../../Dropdown.vue";
import {INPUT_SIZES} from "../../Input.vue";
import base64 from "base-64";

const props = defineProps({
  title: String,
  modelValueSearch: String,
  listPersons: Array,
  modelSelectedPerson: String,
  listParticipationRoles: Array,
    modelSelectedRole: String
})

const emit = defineEmits(['onDelaySearch', 'onSearch'])


const selectedPerson = ref(props.modelSelectedPerson? props.listPersons.find(person => person.id === base64.decode(props.modelSelectedPerson)) : undefined)
const selectedParticipationRole = ref(props.modelSelectedRole? props.listParticipationRoles.find(role => role.id === props.modelSelectedRole) : '')


const opened = ref(false)

</script>
