<template>
    <EditForm
        class=""
        :title="$t('projects_update_participant_entities')"
        :action-button-disabled="submitDisabled()"
        :return-route="route('projects.show', project.projectId)"
        @submit="onSubmit"
    >
        <div class="w-full flex flex-wrap justify-between px-2 mt-2 lg:px-0 lg:mt-0">
           <ComposedListAlternative
                class="mt-2 mb-8 bg-white text-black"
                v-slot="listProps"
                :label="$t('participants_list')"
                :error="form.errors.participantEntities"
                :list-size="INPUT_SIZES.OTHER"
                :has-edit="true"
                :is-required="true"
                :list-display-name="(item) =>`
                                <b>${$t('participant_entities')}:</b> ${item.participantEntity?.designation} </br>
                                ${item.participantEntityResponsible? (`<b>${$t('participant_entities_responsible')}:</b> ${item.participantEntityResponsible.designation}</br>`) : ''}
                                <b>${$t('participation_role_single_entities')}:</b> ${item.participantEntityRole.designation} </br>
                                ${item.participantEntityFinancedValue? (`<b>${$t('participant_entity_financed_value')}:</b> ${formater(item.participantEntityFinancedValue)} €  </br>`) : ''}
                                ${item.participantEntityBudgetValue? (`<b>${$t('participant_entity_budget_value')}:</b> ${formater(item.participantEntityBudgetValue)} €  </br>` ) : ''}
                                ${item.participantEntityOverheadsValue? (`<b>${$t('participant_entity_participation_overheads')}:</b> ${formater(item.participantEntityOverheadsValue)} €`) : ''}
                            `
                        "
                :button-disable="(addingValue) => !addingValue.participantEntity || ((addingValue.participantEntity?.id === fculEntity.id ||addingValue.participantEntity?.id === fcienciasEntity.id) && (!addingValue.participantEntityFinancedValue || !addingValue.participantEntityBudgetValue)) ||!addingValue.participantEntityRole"
                :model-value="project.participantEntities.map(item => ({
                    id: item.id,
                    participantEntity: {
                        id: item.participantEntityId,
                        designation: item.participantEntity,
                    },
                    participantEntityRole: {
                        id: item.participationEntityRoleId,
                        designation: item.participationEntityRole
                    },
                    participantEntityResponsible: item.participationResponsible,
                    participantEntityFinancedValue: item.participantEntityFinancedValue,
                    participantEntityBudgetValue: item.participantEntityBudgetValue,
                    participantEntityOverheadsValue: item.participationEntityOverheads
                }))"
                @on-list-update="(value) => {
                         onChange(value.map(item => (
                                {
                                    id: item.id ?? -1,
                                    participantEntity: item.participantEntity.id,
                                    participantEntityResponsible: item.participantEntityResponsible?.id,
                                    participantEntityResponsibleFrom: item.participantEntityResponsible?.from,
                                    participationEntityRole: item.participantEntityRole.id,
                                    participantEntityFinancedValue: item.participantEntityFinancedValue,
                                    participantEntityBudgetValue: item.participantEntityBudgetValue,
                                    participantEntityOverheadsValue: item.participantEntityOverheadsValue
                                }
                            )), 'participantEntities');
                            onSearch(
                                '',
                                'participantEntities',
                                form.participantEntities.map((item) => item.participantEntity)
                            );
                            }"
                @on-edit="(value, toAddContent) => {
                          toAddContent.participantEntity = value.participantEntity
                          onSearch(value.participantEntity.id, 'selectedParticipantEntity')

                          toAddContent.participantEntityRole = value.participantEntityRole
                          toAddContent.participantEntityResponsible = value.participantEntityResponsible
                          toAddContent.participantEntityFinancedValue = value.participantEntityFinancedValue
                          toAddContent.participantEntityBudgetValue = value.participantEntityBudgetValue
                          toAddContent.participantEntityOverheadsValue = value.participantEntityOverheadsValue
                }"
           >
               <div class="flex justify-between">
                   <button
                       class="w-2/5 mt-1.5 px-4 py-2 rounded-lg bg-fculColor text-white font-bold drop-shadow-2xl shadow-black hover:bg-opacity-60 disabled:bg-opacity-60"
                       @click.prevent="() => {
                                if(form.participantEntities.find(value =>  value.participantEntity == fculEntity.id)) {
                                    form.errors.participantEntities = 'fcul_already_existent'
                                    return
                                } else {
                                    delete form.errors.participantEntities
                                }
                                listProps.toAddContent.participantEntity = fculEntity
                                onSearch(fculEntity.id, 'selectedParticipantEntity')
                            }"
                   >
                       FCUL
                   </button>
                   <button
                       class="w-2/5 mt-1.5 px-4 py-2 rounded-lg bg-fculColor text-white font-bold drop-shadow-2xl shadow-black hover:bg-opacity-60 disabled:bg-opacity-60"
                       @click.prevent="() => {
                                if(form.participantEntities.find(value =>  value.participantEntity == fcienciasEntity.id)) {
                                    form.errors.participantEntities = 'fciencias_already_existent'
                                    return
                                } else {
                                    delete form.errors.participantEntities
                                }
                                listProps.toAddContent.participantEntity = fcienciasEntity
                                onSearch(fcienciasEntity.id, 'selectedParticipantEntity')
                            }"
                   >
                       FCiências.ID
                   </button>
               </div>
               <Dropdown
                   :label="$t('participant_entities_single')"
                   :model-value="listProps.toAddContent.participantEntity"
                   :list="participantEntities"
                   :search-value="participantEntityQuery"
                   :is-required="true"
                   :invert-color="true"
                   @on-change="(value) => {
                       delete form.errors.participantEntities
                        listProps.toAddContent.participantEntity = value
                        onSearch(value? value.id :'', 'selectedParticipantEntity')

                   }"
                   @on-search="(value) => onSearch(value, 'participantEntities', form.participantEntities.map((item) => item.participantEntity))"
               />
               <Dropdown
                   :label="$t('participant_entities_responsible')"
                   :model-value="listProps.toAddContent.participantEntityResponsible"
                   :list="participantEntityResponsibles"
                   :search-value="participantEntityResponsibleQuery"
                   :invert-color="true"
                   @on-change="(value) => listProps.toAddContent.participantEntityResponsible = value"
                   @on-search="(value) => {
                       onSearch(listProps.toAddContent.participantEntity.id, 'selectedParticipantEntity')
                       onSearch(value, 'participantEntityResponsible')
                   }"
               />
               <Dropdown
                   :label="$t('participation_role_single_entities')"
                   :model-value="listProps.toAddContent.participantEntityRole"
                   :list="financingProgramEntityRoles"
                   :search-value="participantEntityRoleQuery"
                   :is-required="true"

                   :invert-color="true"
                   @on-change="(value) => listProps.toAddContent.participantEntityRole = value"
                   @on-search="(value) => onSearch(value, 'participantEntityRole')"
               />
               <Input
                   :label="$t('participant_entity_financed_value')"
                   type="number"

                   :invert-color="true"
                   :model-value="listProps.toAddContent.participantEntityFinancedValue"
                   :is-required="listProps.toAddContent?.participantEntity?.id === fculEntity.id || listProps.toAddContent?.participantEntity?.id === fcienciasEntity.id"
                   @on-change="(value) => listProps.toAddContent.participantEntityFinancedValue = value"
               />
               <Input
                   :label="$t('participant_entity_budget_value')"
                   type="number"

                   :invert-color="true"
                   :model-value="listProps.toAddContent.participantEntityBudgetValue"
                   :is-required="listProps.toAddContent?.participantEntity?.id === fculEntity.id || listProps.toAddContent?.participantEntity?.id === fcienciasEntity.id"
                   @on-change="(value) => listProps.toAddContent.participantEntityBudgetValue = value"
               />
               <Input
                   :label="$t('participant_entity_participation_overheads')"
                   type="number"

                   :invert-color="true"
                   :model-value="listProps.toAddContent.participantEntityOverheadsValue"
                   @on-change="(value) => listProps.toAddContent.participantEntityOverheadsValue = value"
               />
            </ComposedListAlternative>

            <ComposedListAlternative
              class="mt-2 bg-white text-black"
              v-slot="listProps"
              :label="$t('participants_list')"
              :error="form.errors.fculMembers"
              :list-size="INPUT_SIZES.XXLARGE"
              :is-required="true"
              :list-display-name="(item) => (`<b>${item.fculMember.designation}</b> <br/> ${item.participationRole.designation?`<b>${$t('participation_types_single')}:</b> ${item.participationRole.designation} <br/>`: ''} ${item.startDate? `<b>${$t('date_start')}:</b> ${item.startDate} | ` : ''} ${item.endDate? `<b>${$t('date_end')}:</b> ${item.endDate} <br/>` : ''} ${ item.dedicationPercentage? `<b> ${$t('participant_dedication')}:</b> ${item.dedicationPercentage}%`: ''}`)"
              :button-disable="(addingValue) => !addingValue.fculMember?.designation || !addingValue.participationRole || form.errors.fculMembers "
              :model-value="project.participants.map(item => ({
                    id: item.id,
                    fculMember: {
                        id: item.censusId,
                        designation: item.participantName
                    },
                    participationRole: {
                        id: item.participationTypeId,
                        designation: item.participationType
                    },
                    startDate: item.dateStart,
                    endDate: item.dateEnd,
                    dedicationPercentage: item.dedicationPercentage,
                }))"
              :has-edit="true"
              @on-list-update="(value) => {
                         onChange(value.map(item => (
                            {
                                id: item.id ?? -1,
                                fculParticipantId: item.fculMember?.id,
                                participantRoleId: item.participationRole.id,
                                startDate: item.startDate,
                                endDate: item.endDate,
                                dedicationPercentage: item.dedicationPercentage
                            }
                        )), 'fculMembers');
                          onSearch(
                            '',
                            'fculMember'
                        );
                      }"
              @on-edit="(value, toAddContent) => {
                          toAddContent.fculMember = value.fculMember
                          toAddContent.participationRole = value.participationRole
                          toAddContent.startDate = value.startDate
                          toAddContent.endDate = value.endDate
                          toAddContent.dedicationPercentage = value.dedicationPercentage
              }"
          >
            <Dropdown
                :label="$t('participants_single')"
                :model-value="listProps.toAddContent.fculMember"
                :list="fculMembers"
                :search-value="fculMemberQuery"
                :is-required="true"

                :invert-color="true"
                @on-change="(value) => listProps.toAddContent.fculMember = value"
                @on-search="(value) => onSearch(value, 'fculMember')"
            />
            <Dropdown
                :label="$t('participation_types_single')"
                :model-value="listProps.toAddContent.participationRole"
                :list="participationRoles"
                :search-value="participationRoleQuery"
                :is-required="true"

                :invert-color="true"
                @on-change="(value) => listProps.toAddContent.participationRole = value"
                @on-search="(value) => onSearch(value, 'participationRole')"
            />
            <div class="flex w-full justify-between flex-col lg:flex-row">
              <Input
                  :label="$t('date_start')"
                  type="date"
                  :input-size="INPUT_SIZES.XSMALL"
                  :invert-color="true"
                  :model-value="listProps.toAddContent.startDate"
                  @on-change="(value) => {
                                if(listProps.toAddContent.endDate && (new Date(listProps.toAddContent.endDate) < new Date(value))) {
                                    form.errors.fculMembers = 'date_order_invalid';
                                } else if (form.startDate && new Date(form.startDate) > new Date(value)) {
                                    form.errors.fculMembers = 'date_prior_project';
                                } else {
                                    delete form.errors.fculMembers
                                    listProps.toAddContent.startDate = value
                                }
                            }"
              />
              <Input
                  :label="$t('date_end')"
                  type="date"
                  :input-size="INPUT_SIZES.XSMALL"
                  :model-value="listProps.toAddContent.endDate"
                  :invert-color="true"
                  @on-change="(value) => {
                                if(listProps.toAddContent.startDate && (new Date(listProps.toAddContent.startDate) > new Date(value))) {
                                    form.errors.fculMembers = 'date_order_invalid';
                                } else if (form.startDate && new Date(form.startDate) > new Date(value)) {
                                    form.errors.fculMembers = 'date_prior_project';
                                } else {
                                    delete form.errors.fculMembers
                                    listProps.toAddContent.endDate = value
                                }
                            }"
              />
            </div>
            <Input
                :label="$t('participant_dedication')"
                type="number"
                :invert-color="true"
                :model-value="listProps.toAddContent.dedicationPercentage"
                @on-change="(value) => listProps.toAddContent.dedicationPercentage = value"
            />
          </ComposedListAlternative>
        </div>


    </EditForm>
</template>

<script setup>
import {INPUT_SIZES} from "../../../Components/Input.vue";
import {router, useForm} from "@inertiajs/vue3";
import Dropdown from "../../../Components/Dropdown.vue";
import _ from "lodash";
import {ref} from "vue";
import EditForm from "../../../Components/Forms/EditForm.vue";
import ComposedListAlternative from "../../../Components/Projeto/ComposedList.vue";
import Input from "../../../Components/Input.vue";
import {formater} from "../../../Utils.js";

const props = defineProps({
    project: Object,
    fculEntity: Object,
    fcienciasEntity: Object,
    participantEntities: Array,
    participantEntityQuery: String,
    participantEntityResponsibles: Array,
    participantEntityResponsibleQuery: String,
    financingProgramEntityRoles: Array,
    participantEntityRoleQuery: String,
    fculMembers: Array,
    fculMemberQuery: String,
    participationRoles: Array,
    participationRoleQuery: String,
})


const form = useForm({
    type: "PARTICIPANT_ENTITIES",
    participantEntities: props.project.participantEntities.map(item => ({
        id: item.id,
        participantEntity: item.participantEntityId,
        participantEntityResponsible: item.participationResponsible?.id,
        participantEntityResponsibleFrom: item.participationResponsible?.from ?? [],
        participationEntityRole: -1,
        participantEntityFinancedValue: item.participantEntityFinancedValue,
        participantEntityBudgetValue: item.participantEntityBudgetValue,
        participantEntityOverheadsValue: item.participationEntityOverheads
    })),
    fculMembers: props.project.participants?.map(item => ({
      id: item.id,
      fculParticipantId: item.censusId,
      participantRoleId: -1,
      startDate: item.dateEnd,
      endDate: item.dateStart,
      dedicationPercentage: item.dedicationPercentage
    }))
});

const queryParameters = ref({})
const onSearch =  _.throttle((value, property, used) => {

    if (queryParameters.value && value === ""){
        delete queryParameters.value[property]
    } else {
        queryParameters.value[property] = value
    }

    if (used) {
        queryParameters.value[property + "Used"] = JSON.stringify(used)
    }

    router.get(route('projects.edit.participant-entities', props.project?.projectId), queryParameters.value, {
        preserveState: true,
    })
}, 1000)

const onChange = (value, propertyName) => {

    if (form.errors[propertyName]) {
        form.errors[propertyName] = null;
    }
    form[propertyName] = ((value !== undefined)?  value : null);
}

const onSubmit = () => {

    for (let prop in form.data()) {
        if (form[prop] instanceof Object && form[prop].id) {
            form[prop] = form[prop].id
        }
    }

    form.put(route('projects.update', props.project.projectId))
}


const submitDisabled = () => {

    return false
}

</script>
