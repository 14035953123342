<template>
  <div
      class="bg-fculColor rounded-lg mt-2 bg-opacity-60"
  >
    <div
        class="bg-fculColor bg-opacity-60   flex items-center cursor-pointer hover:bg-opacity-100 overflow-hidden"
        :class="{
          'h-10': opened,
          'h-12': !opened,
          'rounded-t-lg': opened,
          'rounded-lg': !opened,
          'text-nowrap': opened
        }"
        @click="() => opened = !opened"
    >
      <label class="mb-1 mt-2 px-2 font-bold text-white cursor-pointer ">{{title}}</label>
    </div>
    <div v-if="opened" class="flex flex-col p-2">
      <input
          class=" relative px-4 z-40 py-2 rounded-t-lg drop-shadow-xl shadow-black text-black w-full"
          v-model="search"
          @input="() => {
                            if(search === '') {
                                emit('onDelaySearch', '')
                            }
                            emit('onDelaySearch',search)
                        }"
          type="text"
          :placeholder="title"
      >
      <div class="bg-background h-48 rounded-b-lg p-2  max-h-48 overflow-y-scroll scrollbar">
        <div
            v-for="item in list"
            class="break-all drop-shadow-lg flex font-bold justify-center p-2 mb-2 cursor-pointer rounded-lg hover:bg-fculColor hover:text-white"
            :class="{
                                'bg-fculColor': selected.find(x => x === item.id),
                                'text-white': selected.find(x => x === item.id),
                                'bg-white': !selected.find(x => x === item.id)
                            }"
            @click="() => {

                                if(selected.find(x => x === item.id)) {
                                    selected = selected.filter(x => x !== item.id)
                                } else {
                                    selected.push(item.id)
                                }

                                emit('onSearch', JSON.stringify(selected))
                                emit('onDelaySearch', '')
                                search = ''
                            }"
        >
          {{item.designation}}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref} from "vue";

const props = defineProps({
  title: String,
  modelValueSearch: String,
  list: Array,
  selectedOnList: Array
})

const emit = defineEmits(['onDelaySearch', 'onSearch'])

const search = ref(props.modelValueSearch ?? '')

const selected = ref(props.selectedOnList)

const opened = ref(false)

</script>
