<template>
    <div class="w-full  flex flex-col h-full max-h-screen overflow-hidden ">
        <div class=" w-full flex flex-col lg:flex-row pt-10 lg:pt-6  pb-6  pl-8 pr-8 bg-background items-center justify-between">
            <h1 class="font-bold text-3xl text-fculColor pb-8 lg:pb-0">{{investigator.investigatorName}}</h1>
            <div v-if="isAuthenticated" class="hidden lg:flex flex-col">
              <h1 class="font-bold text-fculColor ">{{ user?.userName }}</h1>
              <p class="text-sm text-fculColor">{{ user?.userEmail }}</p>
            </div>
        </div>
        <div class="overflow-y-scroll scrollbar h-full">
            <div class="flex flex-col lg:flex-row justify-evenly items-center">
                <div class="bg-white w-96 xl:w-148 h-96 px-6 py-4 rounded-lg drop-shadow-xl mb-8 lg:mb-0">
                    <div class=" flex font-bold justify-between items-center ">
                      <div></div>
                        <h1 >{{$t('global_dedication_percentage')}}</h1>
                        <FontAwesomeIcon
                            :icon="faFilter"
                            class="btn-custom w-16 py-3 px-1 bg-fculColor text-white"
                            @click="() => dedicationFilters = !dedicationFilters"
                        />
                    </div>
                    <div v-if="dedicationFilters" class="bg-background bg-opacity-80 absolute right-6 w-72 h-72 drop-shadow rounded-lg mt-2 py-2  overflow-hidden px-2">

                        <div class="flex justify-between ">
                          <h1 class="text-lg font-bold text-black">
                            {{$t('filters')}}
                          </h1>
                          <div class="flex">

                            <button
                                class="px-3  flex justify-center items-center bg-decline rounded-lg  font-bold text-sm text-white cursor-pointer hover:bg-opacity-60"
                                @click="() => {
                                  clearFilters()
                              }"
                            >
                              {{$t('clear')}}
                            </button>
                          </div>
                        </div>
                        <div>
                          <label class="mb-1 mt-2 pl-2 font-bold text-black">{{$t('date_start')}}</label>
                          <input
                              class="border-0 focus:border-2 px-4 my-1.5 py-2 rounded-lg drop-shadow-xl bg-white shadow-black text-black w-full"
                              v-model="startDate"
                              @input="() => {
                                  if(startDate === '') {
                                      onDelayedSearch('', 'startDate')
                                  }

                                  const date  = new Date(startDate);
                                  if (date.getFullYear() > 1000) {
                                      onDelayedSearch(startDate, 'startDate')
                                  }
                              }"
                              type="date"
                          >
                        </div>
                        <div>
                          <label class="mb-1 mt-2 pl-2 font-bold text-black">{{$t('date_end')}}</label>
                          <input
                              class="border-0 focus:border-2 px-4 my-1.5 py-2 rounded-lg drop-shadow-xl bg-white shadow-black text-black w-full"
                              v-model="endDate"
                              @input="() => {
                                  if(endDate === '') {
                                      onDelayedSearch('', 'endDate')
                                  }

                                  const date  = new Date(endDate);
                                  if (date.getFullYear() > 1000) {
                                      onDelayedSearch(endDate, 'endDate')
                                  }
                              }"
                              type="date"
                          >
                        </div>
                    </div>
                    <div
                        class="flex w-full h-full pb-10 pt-4 justify-center items-center chart-container"

                    >
                      <LineChart
                          :labels="investigator.dedicationMonthLabels"
                          :data="[{
                            label: $t('participant_dedication'),
                            backgroundColor: investigator.dedicationPercentages.map(item => item < 100? '#2C3FB1' : '#F03F3F'),
                            borderColor: '#2C3FB1',
                            data: investigator.dedicationPercentages
                          }]"
                          :y-scale="{
                            min: 0,
                            max: yAxisMax,
                            ticks: {
                              callback: function(value) {
                                return value + '%';
                              }
                            }
                          }"
                          :hide-legends="true"
                          :y-axis-name="() => $t('number_projects')"
                      />
                    </div>
                </div>
                <div class="bg-white w-96 xl:w-128 h-64 px-6 py-4 rounded-lg drop-shadow-xl">
                    <div class=" flex font-bold justify-center items-center">
                        <h1 >{{$t('projects_overview')}}</h1>
                    </div>
                    <div
                        class="flex w-full h-full pb-10 pt-4 justify-center flex-col  "
                    >
                        <div class="flex mb-8">
                            <h1 class="text-lg mr-4 font-bold">{{$t('projects_count')}}:</h1>
                            <h1 class="text-lg"> {{investigator.projectsCount}}</h1>
                        </div>
                        <div class=" flex">
                            <h1 class="text-lg mr-4 font-bold">{{$t('projects_count_active')}}:</h1>
                            <h1 class="text-lg"> {{investigator.activeProjectsCount}}</h1>
                        </div>
                    </div>'
                </div>
            </div>

            <div class="mt-14 w-full h-full">
                <h1 class="font-bold text-3xl text-fculColor mb-4 pr-4 pl-8">
                    {{$t('projects')}}
                </h1>
                <div class="pr-4 pl-8 w-full flex flex-wrap gap-8 scrollbar">
                    <SquareListContainerItem
                        class=""
                        v-for="project in investigator.projects.data"
                        :key="project.id"
                        :title="activeLanguage === 'pt'? project.projectNamePt ?? project.projectNameEn : project.projectNameEn ?? project.projectNamePt"
                        :navigate-to="route('projects.show', project.projectId)"
                    >
                        <div class="px-2 w-full">
                            <div class="flex">
                                <p class="text-black font-bold">{{$t('acronym')}}:</p>
                                <p class="text-black ml-1">{{project.acronym}}</p>
                            </div>
                            <div class="flex">
                                <p class="text-black font-bold">{{$t('participation_type')}}:</p>
                                <p class="text-black ml-1">{{project.typeOfParticipation}}</p>
                            </div>
                            <div class="flex">
                                <p class="text-black font-bold">{{$t('state')}}:</p>
                                <p class="text-black ml-1">{{project.active? $t('active') : $t('finished')}}</p>
                            </div>
                        </div>
                    </SquareListContainerItem>
                    <HorizontalPagination class=" mb-0 " :links="investigator.projects.links"/>
                </div>
            </div>
        </div>

    </div>
</template>

<script setup >

    import {computed, getCurrentInstance, onMounted, onUnmounted, ref} from "vue";
    import {router} from "@inertiajs/vue3";
    import HorizontalListItem from "../../Components/HorizontalList/HorizontalListItem.vue";
    import {getActiveLanguage} from "laravel-vue-i18n";
    import {useUser} from "../../Components/hooks/useUser.js";
    import LineChart from "../../Components/Charts/LineChart.vue";
    import {faFilter} from "@fortawesome/free-solid-svg-icons";
    import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
    import _ from "lodash";
    import base64 from "base-64";
    import SquareListContainerItem from "../../Components/SquareListContainerItem.vue";
    import HorizontalPagination from "../../Components/HorizontalList/HorizontalPagination.vue";

    const {proxy} = getCurrentInstance()
    const emitter = proxy.emitter
    const windows = proxy.window

    const { user, isAuthenticated} = useUser()

    const activeLanguage = getActiveLanguage();

    const props = defineProps({
        investigator: Object,
        startDate: String,
        endDate: String,
    })

    const queryParameters = ref({
      startDate: props.startDate,
      endDate: props.endDate,
    })

    const maxDataValue = Math.max(...props.investigator.dedicationPercentages);
    const yAxisMax = computed(() => maxDataValue > 100 ? Math.ceil(maxDataValue / 10) * 10 + 10: 100);

    const dedicationFilters = ref(false)

    const startDate = ref(props.startDate ?? '')
    const endDate = ref(props.endDate ?? '')

    const onDelayedSearch =  _.throttle((value, property) => {



        if (queryParameters.value && value === ""){
          delete queryParameters.value[property]
        } else {
          queryParameters.value[property] = value
        }

        for (const key of Object.keys(queryParameters.value)) {

          if (!queryParameters.value[key]
              || queryParameters.value[key] === null
              ||queryParameters.value[key] === ""
              || queryParameters.value[key] === "[]"
          ) {
            delete queryParameters.value[key]
          }
        }


        router.get(route('researchers.show', base64.encode(props.investigator.investigatorId)), queryParameters.value, {
          preserveState: true,
        })
    }, 1000)

    const clearFilters = () => {
      queryParameters.value = {}
      onDelayedSearch('', '')
      startDate.value = ''
      endDate.value = ''
    }

    const languagePt = ref(getActiveLanguage() === 'pt')

    onMounted(() => {
        emitter.on('languageChange', () => {
            languagePt.value = getActiveLanguage() === 'pt'
        })
    })
    onUnmounted(() => {
        emitter.off('languageChange')
    })

    const visitProject = (projectId) => {
        router.visit(route('projects.show', projectId))
    }

</script>


