<template>
  <div class="w-full flex flex-col overflow-hidden max-h-full h-screen">
    <div class="w-full flex justify-end lg:justify-start">
        <div class=" lg:h-24 px-2 py-4 lg:pl-8 bg-background flex lg:flex-row flex-wrap justify-end lg:justify-start  w-9/12 -ml-2 items-center"
            :class="{
                'h-28': title.split(' ').length <= 2,
                'h-32': title.split(' ').length > 2
            }"
        >
          <h1
              v-for="words in title.split(' ')"
              class="font-bold text-3xl text-fculColor ml-2"
          >{{words}}</h1>
        </div>
    </div>
    <form class="w-full h-full lg:px-8 pb-6 flex flex-col focus:outline-fculColor overflow-y-scroll scrollbar" @submit.prevent="emit('submit')">
      <div class="mb-8 flex">
        <slot></slot>
      </div>
      <div class="w-full flex mb-2 px-2 lg:px-0">
        <button  @click="emit('extra')" :disabled="actionButtonDisabled" type="submit" class="w-1/2 mr-1 lg:mr-6 px-4 py-2 rounded-lg bg-fculColor text-white font-bold drop-shadow-2xl shadow-black hover:bg-opacity-60 disabled:bg-opacity-60">
            {{$t('store')}}
        </button>
        <Link class="w-1/2 ml-1 lg:ml-6 px-4 py-2 rounded-lg bg-decline text-white font-bold drop-shadow-2xl shadow-black hover:bg-opacity-60 text-center" :href="sanitizedReturnTo">
          {{$t('cancel')}}
        </Link>
      </div>
    </form>
  </div>
</template>

<script setup>

import {Link} from "@inertiajs/vue3";
import {computed} from "vue";
import DOMPurify from "dompurify";

const props = defineProps({
  title: String,
  actionButtonDisabled: Boolean,
  returnRoute: String
});

const emit = defineEmits(['submit', 'extra'])

const sanitizedReturnTo = computed(() => {
    return DOMPurify.sanitize(props.returnRoute);
});

</script>
