<template>
  <div class=" max-h-10 h-10 w-full flex items-center justify-center py-2 mb-8 bg-white drop-shadow-xl shadow-black rounded-lg">
    <Link
        as="button"
        v-for="link in links" class="mx-2 text-fculColor font-bold cursor-pointer hover:text-opacity-60"
        :disabled="link?.active "
        :class="{'text-opacity-60': link?.active}"
        :href="sanitizedRoute(link?.url ?? '')"
    >
      {{$t(link.label)}}
    </Link>
  </div>
</template>

<script setup>
  import {Link} from "@inertiajs/vue3";
  import DOMPurify from "dompurify";

  const props = defineProps({
    links: Array
  })

  const sanitizedRoute = (route) => {
      return DOMPurify.sanitize(route);
  };
</script>
