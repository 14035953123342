<template>
    <div class="ml-6 text-white"
    >
        <h1 v-if="input.child && input.child.length === 0" class="pl-2  ">{{ input.name }}: {{formater(values?.find(item => item.attributeId === input.id)?.value ?? 0)}} €</h1>
        <h1 v-else class="pl-2  ">{{ input.name }}: {{formater(calculateChild(input))}}</h1>
        <ProjectRecursiveInput
            v-for="inputChild in input.child"
            :input="inputChild"
            :values="values"
        />
    </div>
</template>

<script setup>

import {formater} from "../../Utils.js";

const props = defineProps({
  input: Object,
    values: Object
})


const  calculateChild = (child) => {


    if (!child) {
        return 0;
    }

    let total = props.values?.find(item => item.attributeId === child.id)?.value ?? 0;

    child.child.forEach((item) => total += calculateChild(item))


    return total;
}



</script>

