<template>
    <EditForm
        class=""
        :title="$t('create_notification')"
        :action-button-disabled="submitDisabled()"
        :return-route="route('projects.show', projectId)"
        @submit=onSubmit
        @extra="() => dateInput = true"
    >
            <ComposedListAlternative
            class="mt-8 bg-white  text-black drop-shadow-2xl"
            v-slot="listProps"
            :label="$t('notifications')"
            :list-display-name="(item) => `${item.designation}`"
            :button-disable="(addingValue) => !addingValue.designation || !addingValue.date"
            :list-size="INPUT_SIZES.XXLARGE"
            :model-value="form.notifications"
            @on-list-update="(value) => {
                onChange(value.map(item => ({id:item.id, notificationDesignation: item.designation, notificationMessage: item.message, notificationDate: item.date })), 'notifications')
            }"
        >
            <Input
                :label="$t('designation')"
                :model-value="listProps.toAddContent.designation"
                :is-required="true"
                @on-change="(value) => listProps.toAddContent.designation = value"
                :invert-color="true"
            />
            <Input
                :label="$t('message')"
                :model-value="listProps.toAddContent.message"
                @on-change="value =>  listProps.toAddContent.message = value"
                :input-size="INPUT_SIZES.XLARGE"
                :invert-color="true"
            />
            <Input
                :label="$t('date')"
                :model-value="listProps.toAddContent.date"
                @on-change="value =>  {
                    if(new Date(value) > new Date()) {
                        listProps.toAddContent.date =value
                    }
                }"
                type="date"
                :invert-color="true"
                :disabled="dateInput"
            />
        </ComposedListAlternative>

    </EditForm>
</template>

<script setup>

import {INPUT_SIZES} from "../../../Components/Input.vue";
import CreateForm from "../../../Components/Forms/CreateForm.vue";
import {useForm} from "@inertiajs/vue3";
import ComposedListAlternative from "../../../Components/Projeto/ComposedList.vue";
import Input from "../../../Components/Input.vue";
import {onMounted, ref} from "vue";
import EditForm from "../../../Components/Forms/EditForm.vue";

const dateInput = ref(false)


const props = defineProps({
    projectId: Number,
    notifications: Array
})

const form = useForm({
    projectId: String,
    notifications: props.notifications
});


const onSubmit = () => {
    for (let prop in form.data()) {
        if (form[prop] instanceof Object && form[prop].id) {
            form[prop] = form[prop].id
        }
    }

    form.post(route('projects.notification.post', props.projectId))
}

const onChange = (value, propertyName) => {

    if (form.errors[propertyName]) {
        form.errors[propertyName] = null;
    }
    form[propertyName] = ((value !== undefined)?  value : null);
}

const submitDisabled = () => {
    return props.notifications.filter(item => {
        return form.notifications.find(notification => notification.id === item.id)
    }).length === props.notifications.length && props.notifications.length === form.notifications.length;
}

</script>
