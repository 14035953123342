<template>
  <EditForm
    :title="$t('financing_programs_edit')"
    :action-button-disabled="wasChanged === form.designation && dataInputsWasChanged"
    :return-route="route('settings.financing-programs.index')"
    @submit="onSubmit"
  >
    <div class="flex flex-col justify-center w-full px-2">
      <div class="w-full flex flex-wrap justify-between">
        <Input
          :label="$t('financing_programs_single')"
          :error="form.errors.designation"
          :model-value="form.designation"
          @on-change="(value) => onChange(value, 'designation')"
        />
        <Dropdown
            :label="$t('financing_programs_parent')"
            :error="form.errors.financingProgramParent"
            :model-value="form.financingProgramParent"
            :list="financingPrograms"
            :search-value="financingProgramQuery"
            @on-change="(value) => onChange(value, 'financingProgramParent')"
            @on-search="(value) => onSearch(value, 'financingPrograms')"
            @on-click="(value) => onSearch(value, 'financingPrograms')"
        />
      </div>

        <ComposedList
            class=" mt-8 bg-fculColor bg-opacity-20 "
            v-slot="listProps"
            :label="$t('participation_types_entities')"
            :list-display-name="(item) => `${item.typeOfParticipation}`"
            :button-disable="(addingValue) => !addingValue.typeOfParticipation || !addingValue.systemEntityParticipationRole"
            :list-size="INPUT_SIZES.MEDIUM"
            :model-value="financingProgram.participationRoles"
            :not-removable="true"
            @on-list-update="(value) => {
                        onChange(value.map(item => ({typeOfParticipation: item.typeOfParticipation, systemEntityParticipationRole: item.systemEntityParticipationRole?.id ?? item.systemEntityParticipationRole })), 'participationRoles')
            }"
        >
            <Input
                :label="$t('participation_types_designation_entities')"
                :model-value="listProps.toAddContent.typeOfParticipation"
                @on-change="(value) => listProps.toAddContent.typeOfParticipation = value"
            />
            <Dropdown
                :label="$t('participation_types_system_entities')"
                :model-value="listProps.toAddContent.systemEntityParticipationRole"
                :list="systemEntityParticipationRoles"
                :search-value="systemEntityParticipationRoleQuery"
                @on-change="(value) =>listProps.toAddContent.systemEntityParticipationRole = value"
                @on-search="(value) => onSearch(value, 'systemParticipationRole')"
            />
        </ComposedList>

        <label class="font-bold pl-2 mb-4">Decomposição</label>
        <RecursiveInput
        v-for="input in form.decompositionInputs"
        :input="input"
        />
    </div>
  </EditForm>
</template>

<script setup>

import EditForm from "../../../Components/Forms/EditForm.vue";
import Input from "../../../Components/Input.vue";
import {router, useForm} from "@inertiajs/vue3";
import {ref, watch} from "vue";
import RecursiveInput from "../../../Components/Forms/RecursiveInput.vue";
import Dropdown from "../../../Components/Dropdown.vue";
import ComposedList from "../../../Components/Projeto/ComposedList.vue";
import {INPUT_SIZES} from "../../../Components/Input.vue";

const props = defineProps({
  financingProgram: Object,
  financingPrograms: Array,
  financingProgramQuery: String,
    systemEntityParticipationRoles: Array,
    systemEntityParticipationRoleQuery: String
});


const wasChanged = ref(props.financingProgram.designation)
const prevInputs = ref(props.financingProgram.decompositionInputs)

const dataInputsWasChanged = ref(true)

const form = useForm({
    designation: props.financingProgram.designation,
    decompositionInputs: props.financingProgram.decompositionInputs,
    financingProgramParent: props.financingProgram.parentFinancingProgram,
    logos: [],
    participationRoles: props.financingProgram.participationRoles
});


const onChange = (value, propertyName) => {
  if (form.errors[propertyName]) {
    form.errors[propertyName] = null;
  }
  form[propertyName] = ((value !== undefined)? (value.id? value.id : value) : null);

}

const queryParameters = ref({})

const onSearch = (value, property) => {

  if (queryParameters.value && value === ""){
    delete queryParameters.value[property]
  } else {
    queryParameters.value[property] = value
  }

  router.get(route('settings.financing-programs.edit', props.financingProgram.id), queryParameters.value, {
    preserveState: true,
  })
}


const onSubmit = () => {
    form.put(route('settings.financing-programs.update', props.financingProgram.id));
};

watch(form, ()=> {
    dataInputsWasChanged.value = JSON.stringify(prevInputs.value)===JSON.stringify(form.decompositionInputs) && props.financingProgram ===  form.financingProgramParent;
})


</script>

