<template>
    <div class="relative w-full lg:w-auto">
        <div class=" flex flex-col ">
            <label v-if="label" class="mb-1 mt-2 pl-2 font-bold">{{label}} {{isRequired? "*" : ""}}</label>
            <input
                class="w-full text-black flex px-4 my-1.5 py-2 rounded-lg drop-shadow-xl shadow-black  justify-between items-center"
                :class="{
                'lg:w-44': inputSize === INPUT_SIZES.XSMALL ,
                'lg:w-96': inputSize === INPUT_SIZES.MEDIUM || !inputSize,
                'lg:w-60': inputSize === INPUT_SIZES.SMALL,
                'bg-fculColor': invertColor,
                'bg-white': !invertColor,
                'bg-opacity-80': invertColor,
                'text-white': invertColor,
                'placeholder-gray-300': invertColor,
                }"
                :placeholder="placeholder || label"
                :value="selected?.designation && !isOpened? $t(selected?.designation) : inputValue"
                @click="changeDropdownState()"
                @input="onSearch"
            >
            <h1 class="ml-2 h-4 text-decline" v-if="error">{{$t(error)}}</h1>
        </div>
        <div
            class="absolute bg-white w-full text-black z-30 rounded-lg drop-shadow-xl shadow-black flex flex-col max-h-28 lg:max-h-44 overflow-y-scroll scrollbar"
            :class="{
                'lg:w-44': inputSize === INPUT_SIZES.XSMALL ,
                'lg:w-96': inputSize === INPUT_SIZES.MEDIUM || !inputSize,
                'lg:w-60': inputSize === INPUT_SIZES.SMALL
                }"
            v-if="isOpened"
        >
            <button
                class="w-full py-2 hover:bg-fculColor hover:text-white flex justify-between px-4"
                :class="{'bg-fculColor': isSelected(item), 'text-white': isSelected(item), 'bg-opacity-60': isSelected(item)}"
                v-for="item in finalList"
                :key="item.id"
                @click="selectOption(item)"
            >
                <span>{{$t(item.designation)}}</span>
                <span>{{(isSelected(item))? "Selected" :""}}</span>
            </button>
        </div>
    </div>
</template>


<script setup>

    import {computed, ref, watch} from "vue";
    import {INPUT_SIZES} from "./Input.vue";

    const emit = defineEmits(['onChange','onSearch']);

    const isSelected = (item) => {
      if (selected.value && selected.value.id === item.id) {

          if (selected.value.type) {
              return selected.value.type === item.type;
          }
          return true
      }
      return false
    }

    const props = defineProps({
        label: String,
        isRequired: Boolean,
        list: Array,
        placeholder: String,
        error: String,
        modelValue: String,
        forcedValue: Object,
        searchValue: String,
        inputSize: String,
        invertColor: Boolean
    })

    const inputValue = ref(props.searchValue)


    const selected = ref(props.modelValue);
    const isOpened = ref(false)
    const finalList = computed(() => {
        const array = props.list instanceof Array? props.list : Object.values(props.list)
        if (selected.value){
            if (array.find(element => element.id === selected.value.id)) {
                array.sort(function(x,y){
                    return x.id === selected.value.id ? -1 : y.id === selected.value.id ? 1 : 0;
                });
            } else {
                array.unshift(selected.value);
            }

        }
        return array
    })

    const prev = ref("");

    watch(props, () => {

        if (!props.modelValue) {

            selected.value = undefined
        }

        if (props.modelValue?.id && selected.value !== props.modelValue) {
            selected.value = props.modelValue
        }

        if (prev.value !== props.searchValue && props.searchValue === "") {
            inputValue.value = ""
        }
        prev.value = props.searchValue

    })

    const changeDropdownState = () => {
        isOpened.value = !isOpened.value
        if (isOpened.value) {
            inputValue.value = ""
        }
    }
    const selectOption = (item) => {
        if (selected.value && selected.value.id === item.id) {
            if (selected.value.type && selected.value.type !== item.type) {
                selected.value = item
                inputValue.value = ""
                emit('onChange', item)
            } else {
                selected.value = undefined
                inputValue.value = ""
                emit('onChange', undefined)
            }
        } else {
            selected.value = item
            inputValue.value = ""
            emit('onChange', item)
        }
        isOpened.value = false
        emit('onSearch', '')
    }

    const onSearch = (event) => {
        inputValue.value = event.target.value
        emit('onSearch', inputValue.value)
    }

    const onClickOutside = async () => {
      await new Promise(resolve => setTimeout(resolve, 100))
      isOpened.value = false
    }
</script>

