export function isEmpty(value) {
    return (value == null || (typeof value === "string" && value.trim().length === 0)) || (Array.isArray(value) && value.length === 0);
}

Array.prototype.addIfNotExists = function(newItem, predicate) {

    if (!newItem) {
        return this;
    }
    // Check if any item in the array has the same designation as the new item
    const exists = this.some(item => predicate(item, newItem));

    // If it doesn't exist, add the new item to the array
    if (!exists) {
        this.push(newItem);
    }
    return this;
};


export const formater = (value) => {
    if (!value || isNaN(parseFloat(value))) {
        return value
    } else {
        return Number.parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    }
}
