<template>
    <div>
        <div class="flex items-center justify-start w-full">
            <Input
                placeholder="Atributo"
                :model-value="input.name"
                @on-change="(text) => changeInputName(text)"
            />
            <div class="flex">
              <button
                  v-if="creation"
                  class="bg-fculColor w-16 py-2 rounded-lg drop-shadow-xl shadow-black font-bold text-white cursor-pointer hover:bg-opacity-60 mx-1.5"
                  @click.prevent="increaseInput()"
              >
                  +
              </button>
              <button
                  v-if="creation"
                  class="bg-decline w-16 py-2 rounded-lg drop-shadow-xl shadow-black font-bold text-white cursor-pointer hover:bg-opacity-60 "
                  @click.prevent="emit('removeThis', input.id)"
              >
                  <FontAwesomeIcon :icon="faTrash" class="text-white" />
              </button>
            </div>
        </div>
        <RecursiveInput
            class="ml-4"
            :creation="creation"
            v-for="input in input.child"
            :input="input"
            @remove-this="removeThisInput"
        />
    </div>
</template>

<script setup>

import Input from "../Input.vue";
import {ref} from "vue";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

const props = defineProps({
    input: Object,
    creation: Boolean
})
const emit = defineEmits(['removeThis'])

const countId = ref(0)

const increaseInput = () => {
    props.input.child.push({
        id: countId.value++,
        name: "",
        child: []
    })

}

const removeThisInput = (thisId) => {
    props.input.child = props.input.child.filter((input) => input.id !== thisId)
}

const changeInputName = (text) => {
    props.input.name = text
}

</script>

