<template>
    <QuillEditor
        ref="quillEditor"
        class="rounded-lg py-2 bg-fculColor bg-opacity-80 drop-shadow"
        theme="snow"
        :read-only="true"
        :options="{modules: {toolbar: false}}"
        v-model:content="value"
    />
</template>

<script setup>
import {Quill, QuillEditor} from "@vueup/vue-quill";
import {ref} from "vue";

    const props = defineProps({
        content: String
    })

    const value = ref()

    if (props.content) {
        const div = document.createElement('div');
        div.setAttribute('id', 'htmlToDelta');
        div.innerHTML = `<div id="quillEditor" style="display:none">${props.content}</div>`;
        document.body.appendChild(div);
        const quill = new Quill('#quillEditor', {
            theme: 'snow',
        });
        const delta = quill.getContents();
        document.getElementById('htmlToDelta').remove();
        value.value = delta
    }

</script>

