<template>
    <div
        class="flex flex-col lg:flex-row item relative"
    >
        <p class="font-bold text-fculColor whitespace-nowrap">{{label}}:</p>
        <div class="flex">
            <p class="ml-2 whitespace-nowrap">{{value}}</p>
            <FontAwesomeIcon
                class="ml-2 mt-1.5 text-fculColor hover:text-opacity-80 cursor-pointer"
                v-if="withPopup"
                :icon="faCircleInfo"
                @click="popUpOpened = true"
          />
        </div>
        <div
            v-if="withPopup && popUpOpened"
            class="absolute w-80 h-48 bg-background left-4 -top-24 -ml-12 z-30 rounded-lg px-4 py-2 drop-shadow-2xl "
        >
            <div class="flex justify-between">
                <p class="font-bold">{{popUpName}}</p>
                <div>
                    <button @click="popUpOpened=false" class="font-extrabold hover:text-decline">X</button>
                </div>
            </div>
            <slot></slot>
        </div>
    </div>
</template>

<script setup>

import {ref} from "vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {faCircleInfo} from "@fortawesome/free-solid-svg-icons";
import {formater} from "../../Utils.js";

const popUpOpened = ref(false)

const props = defineProps({
    label: String,
    value: String,
    withPopup: Boolean,
    popUpName: String
})




</script>
