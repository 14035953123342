
<template>
    <div
        class="mt-8 w-full h-full flex flex-col max-h-full bg-background px-4 lg:px-8 pt-4 pb-8 text-black rounded-lg drop-shadow-xl shadow-black overflow-hidden">
        <h1 class="text-lg font-bold">{{sectionName}} *</h1>
        <h1 class="ml-2 h-4 text-decline" v-if="error">{{error}}</h1>
        <div v-for="decompositionFinancing in decompositionsFinancing"
             class="bg-fculColor bg-opacity-20 py-4 rounded-lg mt-4 px-4 flex w-full max-w-full flex-col overflow-x-scroll h-full">
            <div class="overflow-x-scroll flex flex-col w-full">
                <div class="flex">
                    <h1 class="text-lg font-bold">{{labelName(decompositionFinancing)}}:</h1>
                    <h1 class="text-lg font-bold text-fculColor">{{sum}}</h1>
                </div>
                <RecursiveInputProject
                    v-for="input in decompositionFinancing.decompositionInputs"
                    :input="input"
                    @on-change="(value) => {
                        values[value.inputId] = value.inputValue
                        sum = Object.values(values).reduce((accumulator, currentValue) => (accumulator === ''? 0 : Number.parseInt(accumulator)) + (currentValue === ''? 0 : Number.parseInt(currentValue)))
                        onChange(value, decompositionFinancing.id)
                    }"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import RecursiveInputProject from "../Forms/RecursiveInputProject.vue";
import {ref} from "vue";

const props = defineProps({
    sectionName: String,
  decompositionsFinancing: Object,
    error: String,
  labelName: Function
})

const values = ref({})
const sum = ref(0)

const emit = defineEmits(['onChange'])

const decompositionModel = ref({})

const onChange = (value, decompositionModelId) => {
    if (!decompositionModel.value[decompositionModelId])
      decompositionModel.value[decompositionModelId] = {}

    decompositionModel.value[decompositionModelId][value.inputId] = value
    emit('onChange',  Object.values(decompositionModel.value).map((decompositionValue => Object.values(decompositionValue))))
}

</script>
