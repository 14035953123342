<template>
  <Link
      class="h-52 w-64 rounded-xl px-4 py-4 mb-6 bg-white drop-shadow-xl shadow-black cursor-pointer hover:bg-fculColor hover:bg-opacity-60 text-fculColor hover:text-white flex flex-col"
      :href="sanitizedNavigateTo"
  >
    <p class="w-full text-center font-bold mb-4 ">{{title}}</p>
    <p class="text-black  text-justify text-sm overflow-y-auto no-scrollbar">{{description}}</p>
  </Link>
</template>

<script setup>
import {Link} from "@inertiajs/vue3";
import {computed} from "vue";
import DOMPurify from "dompurify";

const props = defineProps({
    title: String,
    description: String,
    navigateTo: String
})

const sanitizedNavigateTo = computed(() => {
    return DOMPurify.sanitize(props.navigateTo);
});
</script>
