<template>
    <div class="mb-2 w-full">
        <p class="font-bold text-fculColor w-11/12" v-if="propertyName">{{ propertyName }}</p>
        <div
            class="w-full flex bg-fculColor bg-opacity-80 rounded-lg py-2 lg:py-6  pl-2 lg:pl-6 overflow-y-scroll drop-shadow-xl"
            :class="{
                'max-h-52': size !== INPUT_SIZES.LARGE,
                'max-h-96': size === INPUT_SIZES.LARGE
            }"
        >
            <div class="w-full h-full  scrollbar mr-2 lg:mr-6  overflow-y-scroll">
                    <slot :childrenState="childrenState"></slot>
            </div>
        </div>
    </div>
</template>
<script setup>

    import {INPUT_SIZES} from "../Input.vue";
    import {ref} from "vue";

    const childrenState = ref({})

    const props = defineProps({
        propertyName: String,
        size: INPUT_SIZES
    })
</script>
