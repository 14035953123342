

<template>
    <div
        class="flex h-36 w-full  justify-center flex-col"

    >
        <div class="flex items-center mb-4">
            <h1 class="mr-2 text-fculColor font-bold text-lg">{{$t('raised_value')}}:</h1>
            <h1>{{formater(financingValues.adquiredValue)}} €</h1>
        </div>
        <div class="flex items-center">
            <h1 class="mr-2 text-fculColor font-bold text-lg">{{$t('budget_value')}}:</h1>
            <h1>{{formater(financingValues.financedvalue)}} €</h1>
        </div>
    </div>
    <div class="flex items-center ">
        <FontAwesomeIcon
            class="mr-2 text-fculColor cursor-pointer"
            :icon="faCircleLeft"
            size="xl"
            @click="moveLeft(-100)"
        />
        <div
            class=" w-full flex overflow-x-hidden max-w-full flex-row-reverse pr-32 years-container"
            ref="yearsDiv"
        >
            <h1  v-for="(year, index) in yearsList()"
                 class="font-bold text-lg text-fculColor mx-2 cursor-pointer delay-100 transition"
                 :class="{
                            'text-opacity-60': year !== selectedYearFinancing,
                            'text-opacity-100': year === selectedYearFinancing,
                            'font-extrabold': year === selectedYearFinancing,
                            'text-xl': year === selectedYearFinancing
                         }"
                 @click="onYearClick(year)"
            >
                {{year}}
            </h1>
        </div>
        <FontAwesomeIcon
            class="mr-2 text-fculColor cursor-pointer"
            :icon="faCircleRight"
            size="xl"
            @click="moveLeft(100)"
        />
    </div>
</template>

<script setup>

import {faCircleLeft, faCircleRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {formater} from "../../Utils.js";

const emit = defineEmits(['onYearChange'])
const props = defineProps({
    financingValues: Object
})

const selectedYearFinancing = ref(props.financingYearSelected ?? new Date().getFullYear())

const yearsList = () => {
    const currentYear = new Date().getFullYear();

    const years = [];

    for (let year = currentYear; year >= 1990; year--) {
        years.push(year);
    }

    return years
}

const onYearClick = (year) => {
    selectedYearFinancing.value = year
    emit('onYearChange', year)
}

</script>

<script>
import {ref} from "vue";

const yearsDiv =  ref()

const moveLeft = (moveOffset) => {

    if (moveOffset > 0 && yearsDiv.value.scrollLeft === 0)
        return

    yearsDiv.value.scrollTo({
        left: yearsDiv.value.scrollLeft + moveOffset,
        behavior: 'smooth'
    });
}
</script>
