<template>
    <EditForm
        class=""
        :title="$t('projects_update_basic')"
        :action-button-disabled="submitDisabled()"
        :return-route="route('projects.show', project.id)"
        @submit="onSubmit"
    >
        <div class="w-full flex flex-wrap justify-between px-2 mt-2 lg:px-0 lg:mt-0">
            <FundusInput
                :label="`${$t('title_pt')} **`"
                :error="form.errors.titlePt"
                :model-value="form.titlePt"
                @on-change="(value) => onChange(value, 'titlePt')"
            />
            <FundusInput
                :label="`${$t('title_en')} **`"
                :error="form.errors.titleEn"
                :model-value="form.titleEn"
                @on-change="(value) => onChange(value, 'titleEn')"
            />
            <FundusInput
                :label="$t('acronym')"
                :is-required="true"
                :error="form.errors.acronym"
                :model-value="form.acronym"
                :input-size="INPUT_SIZES.SMALL"
                @on-change="(value) => onChange(value, 'acronym')"
            />
            <FundusInput
                :label="$t('external_reference')"
                :error="form.errors.externalReference"
                :model-value="form.externalReference"
                :input-size="INPUT_SIZES.SMALL"
                @on-change="(value) => onChange(value, 'externalReference')"
            />
            <Dropdown
                :label="$t('typologies_single')"
                :is-required="true"
                :error="form.errors.typology"
                :model-value="form.typology"
                :list="typologies"
                :search-value="typologyQuery"
                @on-change="(value) => onChange( value, 'typology')"
                @on-search="(value) => onSearch(value, 'typology')"
                @on-click="(value) => onSearch(value, 'typology')"
            />
            <Dropdown
                :label="$t('financing_origin_single')"
                :error="form.errors.financingOrigin"
                :model-value="form.financingOrigin"
                :list="financingOrigins"
                :search-value="categoryQuery"
                :is-required="true"
                @on-change="(value) => onChange( value, 'financingOrigin')"
                @on-search="(value) => onSearch(value, 'financingOrigin')"
                @on-click="(value) => onSearch(value, 'financingOrigin')"
            />
            <Dropdown
                :label="$t('scope_single')"
                :is-required="true"
                :error="form.errors.scope"
                :model-value="form.scope"
                :list="scopes"
                :search-value="scopeQuery"
                :input-size="INPUT_SIZES.SMALL"
                @on-change="(value) => onChange( value, 'scope')"
                @on-search="(value) => onSearch(value, 'scope')"
                @on-click="(value) => onSearch(value, 'scope')"
            />
            <FundusInput
                :label="$t('date_start')"
                :is-required="true"
                class="pr-6"
                :input-size="INPUT_SIZES.XSMALL"
                :error="form.errors.startDate"
                :model-value="form.startDate"
                @on-change="(value) => {
                            onChange(value, 'startDate')
                            delete form.errors.endDate
                            if(form.endDate && (new Date(form.endDate) < new Date(value))) {
                                        form.errors.startDate = 'date_order_invalid';
                            } else {
                                delete form.errors.startDate
                            }
                        }"
                type="date"
            />
            <FundusInput
                :label="$t('date_end')"
                :is-required="true"
                :input-size="INPUT_SIZES.XSMALL"
                :error="form.errors.endDate"
                :model-value="form.endDate"
                @on-change="(value) => {
                            onChange(value, 'endDate')
                            delete form.errors.startDate
                            if(form.startDate && (new Date(form.startDate) > new Date(value))) {
                                        form.errors.endDate = 'date_order_invalid';
                            } else {
                                delete form.errors.endDate
                            }
                        }"
                type="date"
            />
            <FundusInput
                v-if="changedEndDate"
                class="w-96"
                :notResizable="true"
                :label="$t('date_end_justification')"
                :error="form.errors.newEndDateObservation"
                :model-value="form.newEndDateObservation"
                :input-size="INPUT_SIZES.LARGE"
                @on-change="(value) => onChange(value, 'newEndDateObservation')"
            />
            <FundusInput
                :label="$t('doi')"
                :error="form.errors.doi"
                :model-value="form.doi"
                :input-size="INPUT_SIZES.SMALL"
                @on-change="(value) => onChange(value, 'doi')"
            />
            <Dropdown
                :label="$t('management_core_ciências_single')"
                :error="form.errors.managementCoreFcul"
                :model-value="form.managementCoreFcul"
                :list="managementCoresFcul"
                :search-value="managementCoreFculQuery"
                @on-change="(value) => onChange( value, 'managementCoreFcul')"
                @on-search="(value) => onSearch(value, 'managementCoreFcul')"
                @on-click="(value) => onSearch(value, 'managementCoreFcul')"
            />
            <Dropdown
                :label="$t('management_core_fciencias_single')"
                :error="form.errors.managementCoreFCiencias"
                :model-value="form.managementCoreFCiencias"
                :list="managementCoresFCiencias"
                :search-value="managementCoreFCienciasQuery"
                @on-change="(value) => onChange( value, 'managementCoreFCiencias')"
                @on-search="(value) => onSearch(value, 'managementCoreFCiencias')"
                @on-click="(value) => onSearch(value, 'managementCoreFCiencias')"
            />
        </div>
    </EditForm>
</template>

<script setup>
import FundusInput from "../../../Components/Input.vue"
import {INPUT_SIZES} from "../../../Components/Input.vue";
import {router, useForm} from "@inertiajs/vue3";
import Dropdown from "../../../Components/Dropdown.vue";
import _, {isEqual} from "lodash";
import {computed, ref} from "vue";
import {isEmpty} from "../../../Utils.js";
import EditForm from "../../../Components/Forms/EditForm.vue";

const props = defineProps({
    project: Object,
    fcienciasEntity: Object,
    scopes: Array,
    scopeQuery: String,
    typologies: Array,
    typologyQuery: String,
    financingOrigins: Array,
    categoryQuery: String,
    managementCoresFcul: Array,
    managementCoreFculQuery: String,
    managementCoresFCiencias: Array,
    managementCoreFCienciasQuery: String,
})


const form = useForm({
    type: "BASIC_INFO",
    titlePt: props.project?.titlePt ?? "",
    titleEn: props.project?.titleEn ?? "",
    acronym: props.project?.acronym ?? "",
    externalReference: props.project?.externalReference ?? "",
    doi: props.project?.doi ?? "",
    scope: props.project?.scope,
    typology: props.project?.typology,
    startDate: props.project?.startDate ?? "",
    endDate: props.project?.endDate ?? "",
    newEndDateObservation: "",
    financingOrigin: props.project?.financingOrigin,
    managementCoreFcul: props.project?.managementCoreFcul,
    managementCoreFCiencias: props.project?.managementCoreFCiencias,
});

const changedEndDate = computed(() => props.project.endDate !== form.endDate)

const queryParameters = ref({})

const onSearch =  _.throttle((value, property, used) => {

    if (queryParameters.value && value === ""){
        delete queryParameters.value[property]
    } else {
        queryParameters.value[property] = value
    }

    if (used) {
        queryParameters.value[property + "Used"] = JSON.stringify(used)
    }

    router.get(route('projects.edit.basic', props.project?.id), queryParameters.value, {
        preserveState: true,
    })
}, 1000)

const onChange = (value, propertyName) => {

    if (form.errors[propertyName]) {
        form.errors[propertyName] = null;
    }
    form[propertyName] = ((value !== undefined)?  value : null);
}

const onSubmit = () => {

    for (let prop in form.data()) {
        if (form[prop] instanceof Object && form[prop].id) {
            form[prop] = form[prop].id
        }
    }

    form.put(route('projects.update', props.project.id))
}


const submitDisabled = () => {
    const requiredFields =  (isEmpty(form.titlePt)
        && isEmpty(form.titleEn))
        || isEmpty(form.acronym)
        || isEmpty(form.scope)
        || isEmpty(form.typology)
        || isEmpty(form.startDate)
        || isEmpty(form.endDate)
        || isEmpty(form.financingOrigin)


    for (let prop in form.data()) {
        if (prop !== "type" && prop !== "newEndDateObservation" && !isEqual(form[prop],props.project[prop])) {
            return requiredFields
        }
    }

    return true

}

</script>
