<template>
  <div class="w-full flex flex-col max-h-screen overflow-y-hidden ">
    <div class="w-full pt-10 lg:pt-6  pb-8 lg:pb-4  pl-8 pr-8 bg-background flex flex-col lg:flex-row  items-center justify-between">
      <h1 class="font-bold text-3xl text-fculColor">{{$t('settings')}}</h1>
    </div>
    <div class="w-full h-fit lg:pl-6 grid grid-cols-[repeat(auto-fill,17.5rem)] justify-center items-center justify-items-center lg:items-start overflow-y-scroll ">
      <SquareListItem
          :title="$t('scientific_areas')"
          :description="$t('scientific_areas_description')"
          :navigate-to="route('settings.scientific-area.index')"
      />
      <SquareListItem
          :title="$t('social_economical_categories')"
          :description="$t('social_economical_categories_description')"
          :navigate-to="route('settings.social-economy-category.index')"
      />
      <SquareListItem
          :title="$t('fcul_decomposition_models')"
          :description="$t('fcul_decomposition_models_description')"
          :navigate-to="route('settings.fcul-decomposition-models.index')"
      />

      <SquareListItem
          :title="$t('keywords')"
          :description="$t('keywords_description')"
          :navigate-to="route('settings.keywords.index')"
      />

      <SquareListItem
          :title="$t('sustainable_development_goals')"
          :description="$t('sustainable_development_goals_description')"
          :navigate-to="route('settings.sustainable-development-goals.index')"
      />

      <SquareListItem
          :title="$t('typologies')"
          :description="$t('typologies_description')"
          :navigate-to="route('settings.typology.index')"
      />

      <SquareListItem
          :title="$t('scope')"
          :description="$t('scope_description')"
          :navigate-to="route('settings.scope.index')"
      />

      <SquareListItem
          :title="$t('participation_types')"
          :description="$t('participation_types_description')"
          :navigate-to="route('settings.participation-role.index')"
      />

        <SquareListItem
            :title="$t('participation_types_entities')"
            :description="$t('participation_types_description_entities')"
            :navigate-to="route('settings.participation-role-entities.index')"
        />

      <SquareListItem
          :title="$t('financing_origin')"
          :description="$t('financing_origin_description')"
          :navigate-to="route('settings.financing-origins.index')"
      />

      <SquareListItem
          :title="$t('management_core_ciências')"
          :description="$t('management_core_ciências_description')"
          :navigate-to="route('settings.fcul-management-core.index')"
      />

      <SquareListItem
          :title="$t('management_core_fciencias')"
          :description="$t('management_core_fciencias_description')"
          :navigate-to="route('settings.fciencias-management-core.index')"
      />
      <SquareListItem
          :title="$t('financing_programs')"
          :description="$t('financing_programs_single_description')"
          :navigate-to="route('settings.financing-programs.index')"
      />
      <SquareListItem
          v-if="canChangeFullSettings(user?.role)"
          :title="$t('user_permissions')"
          :description="$t('user_permissions_description')"
          :navigate-to="route('settings.user-permissions.index')"
      />
    </div>
  </div>
</template>

<script setup>

import SquareListItem from "../../Components/SquareListItem.vue";
import {canChangeFullSettings} from "../../Authorizations.js";
import {useUser} from "../../Components/hooks/useUser.js";

const { user } = useUser();
</script>

