<template>
  <EditForm
    :title="$t('keywords_edit')"
    :action-button-disabled="wasChangedDesignation === form.designation && wasChangedAlternativeDesignation === form.alternativeDesignation"
    :return-route="route('settings.keywords.index')"
    @submit="onSubmit"
  >
    <div class="flex flex-col lg:flex-row px-2 w-full">
        <Input
          :label="$t('keywords_single')"
          :error="form.errors.designation"
          :model-value="form.designation"
          @on-change="(value) => onChange(value, 'designation')"
        />
        <Input
            class="lg:ml-8"
          :label="$t('keywords_single')"
          :error="form.errors.alternativeDesignation"
          :model-value="form.alternativeDesignation"
          @on-change="(value) => onChange(value, 'alternativeDesignation')"
        />
    </div>
  </EditForm>


</template>

<script setup>

import EditForm from "../../../Components/Forms/EditForm.vue";
import Input from "../../../Components/Input.vue";
import { useForm} from "@inertiajs/vue3";
import {ref} from "vue";

const props = defineProps({
    keyword: Object
});

const wasChangedDesignation = ref(props.keyword.designation)
const wasChangedAlternativeDesignation = ref(props.keyword.alternativeDesignation)

const form = useForm({
  designation: props.keyword.designation,
    alternativeDesignation: props.keyword.alternativeDesignation
});

const onChange = (value, property) => {
  if (form.errors.designation) {
      form.errors.designation = null;
  }
  if (form.errors.alternativeDesignation) {
      form.errors.alternativeDesignation = null;
  }
  form[property] = value;
};

const onSubmit = () => {
    form.put(route('settings.keywords.update', props.keyword.id));
};

</script>

