<template>
    <CreateForm
        :title="$t('participation_types_add_entities')"
        :action-button-disabled="form.designation === ''"
        :return-route="route('settings.participation-role-entities.index')"
        @submit="onSubmit"
    >
        <Input
            class="px-2"
            :label="$t('participation_types_single_entities')"
            :error="form.errors.designation"
            :model-value="form.designation"
            @on-change="onChange"
        />
    </CreateForm>
</template>

<script setup>

import {useForm} from "@inertiajs/vue3";
import Input from "../../../Components/Input.vue";
import CreateForm from "../../../Components/Forms/CreateForm.vue";

const form = useForm({
    designation: ""
})

const onChange = (value) => {
  if (form.errors.designation) {
      form.errors.designation = null;
  }
  form.designation = value

}

const onSubmit = () => {
    form.post(route('settings.participation-role-entities.store'))
}

</script>

<style>
textarea:focus, input:focus{
  outline: 0.19rem solid;
  outline-color: #2C3FB1;
}
</style>
