<template>
    <HorizontalList
        :list-name="$t('scientific_areas')"
        :return-route="route('settings.index')"
        :add-route="route('settings.scientific-area.create')"
        :allow-search="true"
        :search-value="searchQuery"
        @search="(value) => onDelayedSearch(value)"
    >
        <HorizontalListItem
            v-for="item in listScientificAreas?.data"
            :title="item?.designation"
            :update-route="route('settings.scientific-area.edit', item?.id)"
            :delete-route=" route('settings.scientific-area.destroy', item?.id)"
            :toggle-item-route="route('settings.scientific-area.toggle',item?.id)"
            :item-active="item.active"
        />
        <HorizontalPagination :links="listScientificAreas.links"/>
    </HorizontalList>

</template>

<script setup>
import HorizontalList from "../../../Components/HorizontalList/HorizontalList.vue";
import HorizontalListItem from "../../../Components/HorizontalList/HorizontalListItem.vue";
import HorizontalPagination from "../../../Components/HorizontalList/HorizontalPagination.vue";
import _ from "lodash";
import {router} from "@inertiajs/vue3";

const props = defineProps({
    listScientificAreas: Object,
    searchQuery: String
})

const onDelayedSearch =  _.throttle((value) => {

    router.get(route('settings.scientific-area.index'), {search: value}, {
        preserveState: true
    })
}, 1000)

</script>
