<template>
    <CreateForm
        :title="$t('participation_types_add')"
        :action-button-disabled="form.designation === ''"
        :return-route="route('settings.participation-role.index')"
        @submit="onSubmit"
    >
        <Input
            class="px-2"
            :label="$t('participation_types_single')"
            :error="form.errors.designation"
            :model-value="form.designation"
            @on-change="onChange"
        />
        <div class="flex flex-col pl-10">
          <label
              class="mb-1 mt-2 pl-2 font-bold"
          >{{$t('is_responsible')}}</label>
          <input
              v-model="form.isResponsible"
              class="ml-2 mt-4 w-5 h-5 rounded-lg drop-shadow-xl shadow-black text-fculColor "
            type="checkbox"
          />
        </div>
    </CreateForm>
</template>

<script setup>

import {useForm} from "@inertiajs/vue3";
import Input from "../../../Components/Input.vue";
import CreateForm from "../../../Components/Forms/CreateForm.vue";

const form = useForm({
    designation: "",
    isResponsible: false
})

const onChange = (value) => {
  if (form.errors.designation) {
      form.errors.designation = null;
  }
  form.designation = value

}

const onSubmit = () => {
    form.post(route('settings.participation-role.store'))
}

</script>

<style>
textarea:focus, input:focus{
  outline: 0.19rem solid;
  outline-color: #2C3FB1;
}
</style>
