
<template>
  <div class="absolute lg:relative h-full w-80 py-8 z-50 pl-8">
    <div class="h-full w-full rounded-xl bg-white drop-shadow-xl shadow-black p-6 flex flex-col justify-between">
      <div class="w-full flex justify-between lg:justify-center  items-center">
        <Link
            class="flex items-center justify-center"
            :href="route('index')"
            @click="onClick"
        >
            <img class="w-5/6" :src="imagePath" alt="FCUL logo" >
        </Link>
        <FontAwesomeIcon :icon="faXmark" size="2xl" class="lg:hidden ml-4 text-fculColor cursor-pointer hover:text-opacity-60" @click="$emit('deactivate')"/>
      </div>
      <div class="pt-12 flex justify-center items-center ">
        <div class="flex justify-center items-center border-2 rounded-xl border-fculColor px-2 cursor-pointer text-fculColor hover:bg-fculColor hover:bg-opacity-60 hover:text-white hover:border-white"
             @click="setLocal()">
          <FontAwesomeIcon :icon="faGlobe" class="mr-2 "/>
          <p class=" font-bold">{{getActiveLanguage().toUpperCase()}}</p>
        </div>
      </div>
      <div class="h-full w-full py-12 flex flex-col items-start">
        <ButtonNavigation
            :text="$t('metrics')"
            :navigate-to="route('dashboard.index')"
            @click="onClick"
        >
          <FontAwesomeIcon :icon="faGaugeHigh" size="lg" class="pr-4 -ml-0.5" />
        </ButtonNavigation>
        <ButtonNavigation
            :text="$t('projects')"
            active-route="/projects"
            :navigate-to="route('projects.index')"
            @click="onClick"
        >
          <FontAwesomeIcon :icon="faSheetPlastic" size="lg" class="pr-4 mr-0.5" />
        </ButtonNavigation>
        <ButtonNavigation
            :text="$t('researchers')"
            active-route="/investigators"
            :navigate-to="route('researchers.index')"
            @click="onClick"
        >
          <FontAwesomeIcon :icon="faUserGraduate" size="lg" class="pr-4 " />
        </ButtonNavigation>
        <ButtonNavigation
            v-if="isAuthenticated && canChangeSettings(user?.role)"
            :text="$t('settings')"
            active-route="/settings"
            :navigate-to="route('settings.index')"
            @click="onClick"
        >
          <FontAwesomeIcon :icon="faToolbox" size="lg" class="pr-3 mr-0.5" />
        </ButtonNavigation>
      </div>
      <div class="w-full flex justify-center">
        <a
            v-if="isAuthenticated"
            class="btn-full"
            :href="route('auth.logout')"
        >
          {{ $t('logout') }}
        </a>
        <a
            v-else
            class="btn-full"
            :href="route('auth.login')"
        >
          {{ $t('login') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>

import {faGaugeHigh, faGlobe, faSheetPlastic, faToolbox, faUserGraduate, faXmark} from "@fortawesome/free-solid-svg-icons";
import {getActiveLanguage, loadLanguageAsync} from "laravel-vue-i18n";
import ButtonNavigation from "./ButtonNavigation.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import axios from "axios";
import {Link, usePage} from "@inertiajs/vue3";
import {getCurrentInstance} from "vue";
import {canChangeFullSettings, canChangeSettings} from "../Authorizations.js";
import {useUser} from "./hooks/useUser.js";

const emit = defineEmits(['onClick'])

const page = usePage();

const { user, isAuthenticated } = useUser()

const {proxy} = getCurrentInstance()

const imagePath = "/images/fclogo.png";

const emitter = proxy.emitter

const setLocal = async () => {
  try {
    const lang = await axios.post(`/loc/${getActiveLanguage() === 'pt'? 'en' : 'pt'}`)
    await loadLanguageAsync(lang.data)
    emitter.emit('languageChange')
    emitter.emit('onClick')
  } catch (e) {
    console.log(e);
  }
}

const onClick = () => {
    emit('onClick')
}

</script>


