<template>
  <EditForm
    :title="$t('financing_origin_edit')"
    :action-button-disabled="wasChanged === form.designation"
    :return-route="route('settings.financing-origins.index')"
    @submit="onSubmit"
  >
    <Input
        class="px-2"
      :label="$t('financing_origin_single')"
      :error="form.errors.designation"
      :model-value="form.designation"
      @on-change="onChange"
    />
  </EditForm>


</template>

<script setup>

import EditForm from "../../../Components/Forms/EditForm.vue";
import Input from "../../../Components/Input.vue";
import { useForm} from "@inertiajs/vue3";
import {ref} from "vue";

const props = defineProps({
    financingOrigin: Object
});


const wasChanged = ref(props.financingOrigin.designation)

const form = useForm({
    designation: props.financingOrigin.designation
});

const onChange = (value) => {
  if (form.errors.designation) {
      form.errors.designation = null;
  }
  form.designation = value;
};

const onSubmit = () => {
    form.put(route('settings.financing-origins.update', props.financingOrigin.id));
};

</script>

