<template>
    <CreateForm
        class=""
        :title="$t('projects_add')"
        :action-button-disabled="submitDisabled()"
        :return-route="route('projects.index')"
        @submit="onSubmit"
    >
        <div class="px-2 lg:px-0">
            <div class="w-full  flex flex-wrap justify-between ">
                <Input
                    :label="`${$t('title_pt')} **`"
                    :error="form.errors.titlePt"
                    :model-value="form.titlePt"
                    @on-change="(value) => onChange(value, 'titlePt')"
                />
                <Input
                    :label="`${$t('title_en')} **`"
                    :error="form.errors.titleEn"
                    :model-value="form.titleEn"
                    @on-change="(value) => onChange(value, 'titleEn')"
                />
                <Input
                    :label="$t('acronym')"
                    :is-required="true"
                    :error="form.errors.acronym"
                    :model-value="form.acronym"
                    :input-size="INPUT_SIZES.SMALL"
                    @on-change="(value) => onChange(value, 'acronym')"
                />
                <Input
                    :label="$t('external_reference')"
                    :error="form.errors.externalReference"
                    :model-value="form.externalReference"
                    :input-size="INPUT_SIZES.SMALL"
                    @on-change="(value) => onChange(value, 'externalReference')"
                />
                <Dropdown
                    :label="$t('typologies_single')"
                    :is-required="true"
                    :error="form.errors.typology"
                    :model-value="form.typology"
                    :list="typologies"
                    :search-value="typologyQuery"
                    @on-change="(value) => onChange(value, 'typology')"
                    @on-search="(value) => onSearch(value, 'typology')"
                    @on-click="(value) => onSearch(value, 'typology')"
                />
                <Dropdown
                    :label="$t('financing_origin_single')"
                    :error="form.errors.financingOrigin"
                    :model-value="form.financingOrigin"
                    :list="financingOrigins"
                    :search-value="categoryQuery"
                    :is-required="true"
                    @on-change="(value) => onChange(value, 'financingOrigin')"
                    @on-search="(value) => onSearch(value, 'financingOrigin')"
                    @on-click="(value) => onSearch(value, 'financingOrigin')"
                />
                <Dropdown
                    :label="$t('scope_single')"
                    :is-required="true"
                    :error="form.errors.scope"
                    :model-value="form.scope"
                    :list="scopes"
                    :search-value="scopeQuery"
                    :input-size="INPUT_SIZES.SMALL"
                    @on-change="(value) => onChange(value, 'scope')"
                    @on-search="(value) => onSearch(value, 'scope')"
                    @on-click="(value) => onSearch(value, 'scope')"
                />
                <Input
                    :label="$t('date_start')"
                    :is-required="true"
                    class="lg:pr-6"
                    :input-size="INPUT_SIZES.XSMALL"
                    :error="form.errors.startDate"
                    :model-value="form.startDate"
                    @on-change="(value) => {
                        onChange(value, 'startDate')
                        delete form.errors.endDate
                        if(form.endDate && (new Date(form.endDate) < new Date(value))) {
                                    form.errors.startDate = 'date_order_invalid';
                        } else {
                            delete form.errors.startDate
                        }
                    }"
                    type="date"
                />
                <Input
                    :label="$t('date_end')"
                    :is-required="true"
                    :input-size="INPUT_SIZES.XSMALL"
                    :error="form.errors.endDate"
                    :model-value="form.endDate"
                    @on-change="(value) => {
                        onChange(value, 'endDate')
                        delete form.errors.startDate
                        if(form.startDate && (new Date(form.startDate) > new Date(value))) {
                                    form.errors.endDate = 'date_order_invalid';
                        } else {
                            delete form.errors.endDate
                        }
                    }"
                    type="date"
                />
                <Input
                    :label="$t('doi')"
                    :error="form.errors.doi"
                    :model-value="form.doi"
                    @on-change="(value) =>  onChange(value, 'doi')"
                />
                <Dropdown
                    :label="$t('management_core_ciências_single')"
                    :error="form.errors.managementCoreFcul"
                    :model-value="form.managementCoreFcul"
                    :list="managementCoresFcul"
                    :search-value="managementCoreFculQuery"
                    @on-change="(value) => onChange(value, 'managementCoreFcul')"
                    @on-search="(value) => onSearch(value, 'managementCoreFcul')"
                    @on-click="(value) => onSearch(value, 'managementCoreFcul')"
                />
                <Dropdown
                    :label="$t('management_core_fciencias_single')"
                    :error="form.errors.managementCoreFCiencias"
                    :model-value="form.managementCoreFCiencias"
                    :list="managementCoresFCiencias"
                    :search-value="managementCoreFCienciasQuery"
                    @on-change="(value) => onChange(value, 'managementCoreFCiencias')"
                    @on-search="(value) => onSearch(value, 'managementCoreFCiencias')"
                    @on-click="(value) => onSearch(value, 'managementCoreFCiencias')"
                />
            </div>

            <Separator class="bg-opacity-80" :separator-name="$t('scientific_information')">
                <Input
                    :label="$t('scientific_description_pt')"
                    class="w-full"
                    :error="form.errors.scientificDescriptionPt"
                    :model-value="form.scientificDescriptionPt"
                    :input-size="INPUT_SIZES.XXLARGE"
                    :rich-text="true"
                    @on-change="(value) => onChange(value, 'scientificDescriptionPt')"
                />
                <Input
                    :label="$t('scientific_description_en')"
                    class="w-full"
                    :error="form.errors.scientificDescriptionEn"
                    :model-value="form.scientificDescriptionEn"
                    :input-size="INPUT_SIZES.XXLARGE"
                    :rich-text="true"
                    @on-change="(value) => onChange(value, 'scientificDescriptionEn')"
                />
                <Input
                    :label="$t('simplified_description_pt')"
                    class="w-full"
                    :error="form.errors.simplifiedDescriptionPt"
                    :model-value="form.simplifiedDescriptionPt"
                    :input-size="INPUT_SIZES.XLARGE"
                    :rich-text="true"
                    @on-change="(value) => onChange(value, 'simplifiedDescriptionPt')"
                />
                <Input
                    :label="$t('simplified_description_en')"
                    class="w-full"
                    :error="form.errors.simplifiedDescriptionEn"
                    :model-value="form.simplifiedDescriptionEn"
                    :input-size="INPUT_SIZES.XLARGE"
                    :rich-text="true"
                    @on-change="(value) => onChange(value, 'simplifiedDescriptionEn')"
                />
                <div class="flex w-full flex-col lg:flex-row lg:w-1/2 justify-between">
                    <Input
                        :label="$t('start_trl')"
                        :error="form.errors.startTRL"
                        :model-value="form.startTRL"
                        :input-size="INPUT_SIZES.SMALL"
                        type="number"
                        @on-change="(value) => {

                            onChange(value, 'startTRL')

                            let number = Number.parseInt(value)
                            delete form.errors.targetTRL
                            if(form.targetTRL && value !== ''&& Number.parseInt(form.targetTRL) < number) {
                                form.errors.startTRL = 'higher_than_target'
                            } else if(number < 0 || number > 10) {
                                form.errors.startTRL = 'invalid_trl'
                            } else {
                                delete form.errors.startTRL
                            }
                        }"
                    />
                    <Input
                        :label="$t('target_trl')"
                        :error="form.errors.targetTRL"
                        :model-value="form.targetTRL"
                        :input-size="INPUT_SIZES.SMALL"
                        type="number"
                        @on-change="(value) => {

                            onChange(value, 'targetTRL')

                            let number = Number.parseInt(value)
                            delete form.errors.startTRL
                            if(form.startTRL && value !== '' && Number.parseInt(form.startTRL) > number) {
                                form.errors.targetTRL = 'lower_than_start'
                            } else if (number < 0 || number > 10) {
                                form.errors.targetTRL = 'invalid_trl'
                            } else {
                                delete form.errors.targetTRL
                            }
                        }"
                    />
                </div>
                <ComposedListAlternative
                    class="mt-8"
                    v-slot="listProps"
                    :label="$t('keywords')"
                    :list-display-name="(item) => !item.keyword? item.keywordDesignation : item.keyword.designation"
                    :button-disable="(addingValue) => keywords.length === 0? !addingValue.keywordDesignation || !addingValue.keywordAlternativeDesignation : !addingValue.keyword"
                    :model-value="auxiliarListsStore.keywords"
                    @on-list-update="(value) => {
                          auxiliarListsStore.keywords = value
                          onChange(value.map( item =>  (item.keyword?
                              ({keywordId: item.keyword.id}) :
                              ({
                                  keywordDesignation: item.keywordDesignation,
                                   keywordAlternativeDesignation: item.keywordAlternativeDesignation
                              })
                          )), 'keywords')
                          onSearch('', 'keyword', form.keywords.filter((item) => { return item.keywordId && !isNaN(item.keywordId)}))
                      }"
                >
                    <Dropdown
                        :label="$t('keywords_single')"
                        :list="keywords"
                        :model-value="listProps.toAddContent.keyword"
                        :search-value="keywordQuery"
                        @on-change="(value) => listProps.toAddContent.keyword = value"
                        @on-search="(value) => {
                              listProps.toAddContent.keywordDesignation = value

                              onSearch(value, 'keyword', form.keywords.filter((item) => !isNaN(item)))
                            }"
                    />
                    <Input
                        v-if="keywords.length === 0"
                        :label="$t('keywords_single_other_idiom')"
                        :model-value="listProps.toAddContent.keywordAlternativeDesignation"
                        @on-change="(value) => listProps.toAddContent.keywordAlternativeDesignation = value"
                    />
                </ComposedListAlternative>
                <ComposedListAlternative
                    class="mt-8"
                    :label="$t('scientific_areas')"
                    v-slot="listProps"
                    :list-display-name="(item) => item.scientificArea.designation"
                    :button-disable="(addingValue) => !addingValue.scientificArea"
                    :list-size="INPUT_SIZES.MEDIUM"
                    :error="form.errors.scientificAreas"
                    :model-value="auxiliarListsStore.scientificAreas"
                    @on-list-update="(value) => {
                            auxiliarListsStore.scientificAreas = value
                            onChange(value.map((item) => item.scientificArea.id), 'scientificAreas')
                            onSearch('', 'scientificArea', form.scientificAreas)
                        }"
                >
                  <Dropdown
                      :label="$t('scientific_areas_single')"
                      :model-value="listProps.toAddContent.scientificArea"
                      :list="scientificAreas"
                      :search-value="scientificAreaQuery"
                      @on-change="(value) => listProps.toAddContent.scientificArea = value"
                      @on-search="(value) => onSearch(value, 'scientificArea', form.scientificAreas)"

                  />
                </ComposedListAlternative>
                <ComposedListAlternative
                    class="mt-8"
                    :label="$t('social_economical_categories')"
                    v-slot="listProps"
                    :list-display-name="(item) => item.socialEconomyCategory.designation"
                    :button-disable="(addingValue) => !addingValue.socialEconomyCategory"
                    :list-size="INPUT_SIZES.MEDIUM"
                    :model-value="auxiliarListsStore.socialEconomyCategories"
                    @on-list-update="(value) => {
                            auxiliarListsStore.socialEconomyCategories = value
                          onChange(value.map((item) => item.socialEconomyCategory.id), 'socialEconomyCategories')
                          onSearch('', 'socialEconomyCategory', form.socialEconomyCategories)
                      }"
                >
                  <Dropdown
                      :label="$t('social_economical_categories_single')"
                      :model-value="listProps.toAddContent.socialEconomyCategory"
                      :list="socialEconomyCategories"
                      :search-value="socialEconomyCategoryQuery"
                      @on-change="(value) => listProps.toAddContent.socialEconomyCategory = value"
                      @on-search="(value) => onSearch(value, 'socialEconomyCategory', form.socialEconomyCategories)"

                  />
                </ComposedListAlternative>
                <ComposedListAlternative
                    class="mt-8"
                    :label="$t('sustainable_development_goals')"
                    v-slot="listProps"
                    :list-display-name="(item) => item.sustainableDevelopmentGoal.designation"
                    :button-disable="(addingValue) => !addingValue.sustainableDevelopmentGoal"
                    :list-size="INPUT_SIZES.MEDIUM"
                    :model-value="auxiliarListsStore.sustainableDevelopmentGoals"
                    @on-list-update="(value) => {
                        auxiliarListsStore.sustainableDevelopmentGoals = value
                        onChange(value.map((item) => item.sustainableDevelopmentGoal.id), 'sustainableDevelopmentGoals')
                        onSearch('', 'sustainableDevelopmentGoal', form.sustainableDevelopmentGoals)
                    }"
                >
                    <Dropdown
                        :label="$t('sustainable_development_goals_single')"
                        :model-value="listProps.toAddContent.sustainableDevelopmentGoal"
                        :list="sustainableDevelopmentGoals"
                        :search-value="sustainableDevelopmentGoalQuery"
                        @on-change="(value) => listProps.toAddContent.sustainableDevelopmentGoal = value"
                        @on-search="(value) => onSearch(value, 'sustainableDevelopmentGoal', form.sustainableDevelopmentGoals)"

                    />
                </ComposedListAlternative>
                <ComposedListAlternative
                    class="mt-8"
                    v-slot="listProps"
                    :label="$t('helpful_hyperlinks')"
                    :list-display-name="(item) => item.hyperlinkDesignation"
                    :button-disable="(addingValue) => !addingValue.hyperlinkDesignation || !addingValue.hyperlink"
                    :list-size="INPUT_SIZES.MEDIUM"
                    :model-value="auxiliarListsStore.helpfulHyperlinks"
                    @on-list-update="(value) => {
                        auxiliarListsStore.helpfulHyperlinks = value
                        onChange(value, 'helpfulHyperlinks')
                    }"
                >
                    <Input
                        :label="$t('helpful_hyperlinks_designation')"
                        :model-value="listProps.toAddContent.hyperlinkDesignation"
                        @on-change="(value) => listProps.toAddContent.hyperlinkDesignation = value"
                    />
                    <Input
                        :label="$t('helpful_hyperlinks_single')"
                        :model-value="listProps.toAddContent.hyperlink"
                        @on-change="(value) => listProps.toAddContent.hyperlink = value"
                    />
                </ComposedListAlternative>
                <ComposedListAlternative
                    class="mt-8"
                    v-slot="listProps"
                    :label="$t('publications')"
                    :list-display-name="(item) => !item.publication? item.publicationDesignation : item.publication.designation"
                    :button-disable="(addingValue) => publications.length === 0? !addingValue.publicationDesignation || !addingValue.publicationHyperlink : !addingValue.publication"
                    :model-value="auxiliarListsStore.publications"
                    @on-list-update="(value) => {
                        auxiliarListsStore.publications = value
                        onChange(value.map( item =>  (item.publication?
                            ({publicationId: item.publication.id}) :
                            ({
                                publicationDesignation: item.publicationDesignation,
                                 publicationHyperlink: item.publicationHyperlink
                            })
                        )), 'publications')
                        onSearch('', 'publications', form.publications.filter((item) => !isNaN(item)))
                    }"
                >
                    <Dropdown
                        :label="$t('publication_designation')"
                        :list="publications"
                        :model-value="listProps.toAddContent.publication"
                        :search-value="publicationQuery"
                        @on-change="(value) => listProps.toAddContent.publication = value"
                        @on-search="(value) => {
                            listProps.toAddContent.publicationDesignation = value

                          onSearch(value, 'publications', form.publications.filter((item) => !isNaN(item)))
                        }"
                    />
                    <Input
                        v-if="publications.length === 0"
                        :label="$t('publications_single')"
                        :model-value="listProps.toAddContent.publicationHyperlink"
                        @on-change="(value) => listProps.toAddContent.publicationHyperlink = value"
                    />
                </ComposedListAlternative>
                <ComposedListAlternative
                    class="mt-8"
                    v-slot="listProps"
                    :label="$t('rewards')"
                    :list-display-name="(item) => item.rewardDesignation"
                    :button-disable="(addingValue) => !addingValue.rewardDesignation"
                    :list-size="INPUT_SIZES.MEDIUM"
                    :model-value="auxiliarListsStore.rewards"
                    @on-list-update="(value) => {
                        auxiliarListsStore.rewards = value
                        onChange(value, 'rewards')
                    }"
                >
                    <Input
                        :label="$t('rewards_single')"
                        :model-value="listProps.toAddContent.rewardDesignation"
                        @on-change="(value) => listProps.toAddContent.rewardDesignation = value"
                    />

                    <Input
                        :label="$t('reward_hyperlink')"
                        :model-value="listProps.toAddContent.rewardHyperlink"
                        @on-change="(value) => listProps.toAddContent.rewardHyperlink = value"
                    />
                </ComposedListAlternative>
                <Input
                    :label="$t('impact_pt')"
                    class="w-full"
                    :error="form.errors.impactPt"
                    :model-value="form.impactPt"
                    :input-size="INPUT_SIZES.XXLARGE"
                    @on-change="(value) => onChange(value, 'impactPt')"
                />
                <Input
                    :label="$t('impact_en')"
                    class="w-full"
                    :error="form.errors.impactEn"
                    :model-value="form.impactEn"
                    :input-size="INPUT_SIZES.XXLARGE"
                    @on-change="(value) => onChange(value, 'impactEn')"
                />
                <Input
                    :label="$t('ethic_pt')"
                    class="w-full"
                    :error="form.errors.ethicPt"
                    :model-value="form.ethicPt"
                    :input-size="INPUT_SIZES.XXLARGE"
                    @on-change="(value) => onChange(value, 'ethicPt')"
                />
                <Input
                    :label="$t('ethic_en')"
                    class="w-full"
                    :error="form.errors.ethicEn"
                    :model-value="form.ethicEn"
                    :input-size="INPUT_SIZES.XXLARGE"
                    @on-change="(value) => onChange(value, 'ethicEn')"
                />
                <Input
                    :label="$t('personal_data_management_plan_pt')"
                    class="w-full"
                    :error="form.errors.personalDataManagementPlanPt"
                    :model-value="form.personalDataManagementPlanPt"
                    :input-size="INPUT_SIZES.XXLARGE"
                    @on-change="(value) => onChange(value, 'personalDataManagementPlanPt')"
                />
                <Input
                    :label="$t('personal_data_management_plan_en')"
                    class="w-full"
                    :error="form.errors.personalDataManagementPlanEn"
                    :model-value="form.personalDataManagementPlanEn"
                    :input-size="INPUT_SIZES.XXLARGE"
                    @on-change="(value) => onChange(value, 'personalDataManagementPlanEn')"
                />
                <ComposedListAlternative
                    class="mt-8"
                    v-slot="listProps"
                    :label="$t('logos')"
                    :list-display-name="(item) => `${item.logoDesignation}`"
                    :button-disable="(addingValue) => !addingValue.logoDesignation || !addingValue.logo"
                    :list-size="INPUT_SIZES.MEDIUM"
                    :model-value="auxiliarListsStore.logos"
                    :error="form.errors.logos"
                    @on-list-update="(value) => {
                        auxiliarListsStore.logos = value
                        onChange(value.map(item => ({logoDesignation: item.logoDesignation, logo: item.logo })), 'logos')
                    }"
                >
                    <Input
                        :label="$t('logos_designation')"
                        :model-value="listProps.toAddContent.logoDesignation"
                        @on-change="(value) => listProps.toAddContent.logoDesignation = value"
                    />
                    <Input
                        :label="$t('logos_single')"
                        :model-value="listProps.toAddContent.logoLocation"
                        type="file"
                        accept="image/*"
                        @on-change="(binaryValue, location) => {
                            listProps.toAddContent.logoLocation = location
                            listProps.toAddContent.logo = binaryValue
                        }"
                        @error="(e) => {
                            form.errors.logos = $t(e)
                        }"
                    />
                </ComposedListAlternative>
                <ComposedListAlternative
                    class="mt-8"
                    v-slot="listProps"
                    :label="$t('documents')"
                    :list-display-name="(item) => `${item.documentDesignation}`"
                    :button-disable="(addingValue) => !addingValue.documentDesignation || !addingValue.document"
                    :list-size="INPUT_SIZES.MEDIUM"
                    :model-value="auxiliarListsStore.documents"
                    :error="form.errors.documents"
                    @on-list-update="(value) => {
                        auxiliarListsStore.documents = value
                        onChange(value.map(item => ({documentDesignation: item.documentDesignation, document: item.document, format: item.format })), 'documents')
                    }"
                >
                    <Input
                        :label="$t('documents_designation')"
                        :model-value="listProps.toAddContent.documentDesignation"
                        @on-change="(value) => listProps.toAddContent.documentDesignation = value"
                    />
                    <Input
                        :label="$t('documents_single')"
                        :model-value="listProps.toAddContent.documentLocation"
                        type="file"
                        @on-change="(binaryValue, location) => {
                          listProps.toAddContent.documentLocation = location
                          listProps.toAddContent.document = binaryValue
                          listProps.toAddContent.format = location.split('.').at(-1)
                        }"
                        @error="(e) => {
                            form.errors.documents = $t(e)
                        }"
                    />
                </ComposedListAlternative>
            </Separator>
            <Separator class="bg-opacity-80" :separator-name="$t('participation')">
            <ComposedListAlternative
                class="mt-8"
                v-slot="listProps"
                :label="$t('participants_list')"
                :list-display-name="(item) => (`<b>${item.fculMember.designation}</b> <br/> ${item.participationRole.designation?`<b>${$t('participation_types_single')}:</b> ${item.participationRole.designation} <br/>`: ''} ${item.startDate? `<b>${$t('date_start')}:</b> ${item.startDate} </br> ` : ''} ${item.endDate? `<b>${$t('date_end')}:</b> ${item.endDate} <br/>` : ''} ${ item.dedicationPercentage? `<b> ${$t('participant_dedication')}:</b> ${item.dedicationPercentage}%`: ''}`)"
                :button-disable="(addingValue) => !addingValue.fculMember?.designation || !addingValue.participationRole || form.errors.fculMembers "
                :list-size="INPUT_SIZES.XXLARGE"
                :error="form.errors.fculMembers"
                :is-required="true"
                :model-value="auxiliarListsStore.fculMembers"
                :has-edit="true"
                @on-list-update="(value) => {
                        auxiliarListsStore.fculMembers = value
                        onChange(value.map(item => (
                            {
                                fculParticipantId: item.fculMember?.id,
                                participantRoleId: item.participationRole.id,
                                startDate: item.startDate,
                                endDate: item.endDate,
                                dedicationPercentage: item.dedicationPercentage
                            }
                        )), 'fculMembers');
                        onSearch(
                            '',
                            'fculMember'
                        );
                    }"
                @on-edit="(value, toAddContent) => {
                          toAddContent.fculMember = value.fculMember
                          toAddContent.participationRole = value.participationRole
                          toAddContent.startDate = value.startDate
                          toAddContent.endDate = value.endDate
                          toAddContent.dedicationPercentage = value.dedicationPercentage
                }"
            >
              <Dropdown
                  :label="$t('participants_single')"
                  :model-value="listProps.toAddContent.fculMember"
                  :list="fculMembers"
                  :search-value="fculMemberQuery"
                  :is-required="true"
                  @on-change="(value) => listProps.toAddContent.fculMember = value"
                  @on-search="(value) => onSearch(value, 'fculMember')"
              />
              <Dropdown
                  :label="$t('participation_types_single')"
                  :model-value="listProps.toAddContent.participationRole"
                  :list="participationRoles"
                  :search-value="participationRoleQuery"
                  :is-required="true"
                  @on-change="(value) => listProps.toAddContent.participationRole = value"
                  @on-search="(value) => onSearch(value, 'participationRole')"
              />
              <div class="flex w-full justify-between flex-col lg:flex-row">
                <Input
                    :label="$t('date_start')"
                    type="date"
                    :input-size="INPUT_SIZES.XSMALL"
                    :model-value="listProps.toAddContent.startDate"
                    @on-change="(value) => {
                                if(listProps.toAddContent.endDate && (new Date(listProps.toAddContent.endDate) < new Date(value))) {
                                    form.errors.fculMembers = 'date_order_invalid';
                                } else if (form.startDate && new Date(form.startDate) > new Date(value)) {
                                    form.errors.fculMembers = 'date_prior_project';
                                } else {
                                    delete form.errors.fculMembers
                                    listProps.toAddContent.startDate = value
                                }
                            }"
                />
                <Input
                    :label="$t('date_end')"
                    type="date"
                    :input-size="INPUT_SIZES.XSMALL"
                    :model-value="listProps.toAddContent.endDate"
                    @on-change="(value) => {
                                if(listProps.toAddContent.startDate && (new Date(listProps.toAddContent.startDate) > new Date(value))) {
                                    form.errors.fculMembers = 'date_order_invalid';
                                } else if (form.startDate && new Date(form.startDate) > new Date(value)) {
                                    form.errors.fculMembers = 'date_prior_project';
                                } else {
                                    delete form.errors.fculMembers
                                    listProps.toAddContent.endDate = value
                                }
                            }"
                />
              </div>
              <Input
                  :label="$t('participant_dedication')"
                  type="number"
                  :model-value="listProps.toAddContent.dedicationPercentage"
                  @on-change="(value) => listProps.toAddContent.dedicationPercentage = value"
              />
            </ComposedListAlternative>
            <ComposedListAlternative
                class="mt-8"
                v-slot="listProps"
                :label="$t('i_and_d_structures')"
                :list-display-name="(item) => item.investigationStructure.designation"
                :button-disable="(addingValue) => !addingValue.investigationStructure"
                :list-size="INPUT_SIZES.MEDIUM"
                :model-value="auxiliarListsStore.investigationStructures"
                @on-list-update="(value) => {
                        auxiliarListsStore.investigationStructures = value
                        onChange(value.map((item) => item.investigationStructure.id), 'investigationStructures')
                        onSearch('', 'investigationStructure', form.investigationStructures)
                    }"
            >
              <Dropdown
                  :label="$t('i_and_d_structures_single')"
                  :model-value="listProps.toAddContent.investigationStructure"
                  :list="investigationStructures"
                  :search-value="investigationStructureQuery"
                  @on-change="(value) => listProps.toAddContent.investigationStructure = value"
                  @on-search="(value) => onSearch(value, 'investigationStructure', form.investigationStructures)"
              />
            </ComposedListAlternative>
            <ComposedListAlternative
                class="mt-8"
                v-slot="listProps"
                :label="$t('departments')"
                :list-display-name="(item) => item.department.designation"
                :button-disable="(addingValue) => !addingValue.department"
                :list-size="INPUT_SIZES.MEDIUM"
                :model-value="auxiliarListsStore.departments"
                @on-list-update="(value) => {
                        auxiliarListsStore.departments = value
                        onChange(value.map((item) => item.department.id), 'departments')
                        onSearch('', 'department', form.departments)
                    }"
            >
              <Dropdown
                  :label="$t('departments_single')"
                  :model-value="listProps.toAddContent.department"
                  :list="departments"
                  :search-value="departmentQuery"
                  @on-change="(value) => listProps.toAddContent.department = value"
                  @on-search="(value) => onSearch(value, 'department', form.departments)"
              />
            </ComposedListAlternative>
            <ComposedListAlternative
                class="mt-8"
                v-slot="listProps"
                :label="$t('service_unit')"
                :list-display-name="(item) => item.serviceUnits.designation"
                :button-disable="(addingValue) => !addingValue.serviceUnits"
                :list-size="INPUT_SIZES.MEDIUM"
                :model-value="auxiliarListsStore.serviceUnits"
                @on-list-update="(value) => {
                        auxiliarListsStore.serviceUnits = value
                        onChange(value.map((item) => item.serviceUnits.id), 'serviceUnits')
                        onSearch('', 'serviceUnit', form.serviceUnits)
                    }"
            >
              <Dropdown
                  :label="$t('service_unit_single')"
                  :model-value="listProps.toAddContent.serviceUnits"
                  :list="serviceUnits"
                  :search-value="serviceUnitQuery"
                  @on-change="(value) => listProps.toAddContent.serviceUnits = value"
                  @on-search="(value) => onSearch(value, 'serviceUnit', form.serviceUnits)"
              />
            </ComposedListAlternative>
            <ComposedListAlternative
                class="mt-8"
                v-slot="listProps"
                :label="$t('other_internal_entities_single')"
                :list-display-name="(item) => item.otherInternalEntity.designation"
                :button-disable="(addingValue) => !addingValue.otherInternalEntity"
                :list-size="INPUT_SIZES.MEDIUM"
                :model-value="auxiliarListsStore.otherInternalEntities"
                @on-list-update="(value) => {
                        auxiliarListsStore.otherInternalEntities = value
                        onChange(value.map((item) => item.otherInternalEntity.id), 'otherInternalEntities')
                        onSearch('', 'otherInternalEntity', form.otherInternalEntities)
                    }"
            >
              <Dropdown
                  :label="$t('other_internal_entities')"
                  :model-value="listProps.toAddContent.otherInternalEntity"
                  :list="otherInternalEntities"
                  :search-value="otherInternalEntityQuery"
                  @on-change="(value) => listProps.toAddContent.otherInternalEntity = value"
                  @on-search="(value) => onSearch(value, 'otherInternalEntity', form.otherInternalEntities)"
              />
            </ComposedListAlternative>
          </Separator>
            <Separator class="bg-opacity-80" :separator-name="$t('financing')">
                <div class="flex flex-col lg:flex-row w-full">
                    <Input
                        :label="$t('financing_value_total')"
                        type="number"
                        :error="form.errors.totalFinancingValue"
                        :model-value="form.totalFinancingValue"
                        :is-required="true"
                        @on-change="(value) => onChange(value, 'totalFinancingValue')"
                    />
                    <Input
                        class="lg:ml-4"
                        :label="$t('budget_value_total')"
                        type="number"
                        :error="form.errors.totalBudgetValue"
                        :model-value="form.totalBudgetValue"
                        :is-required="true"
                        @on-change="(value) => onChange(value, 'totalBudgetValue')"
                    />
                </div>
                <ComposedListAlternative
                    class="mt-8"
                    v-slot="listProps"
                    :label="$t('financing_entities')"
                    :list-display-name="(item) => `${$t(item.financingEntity.designation)} &nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;${item.financingEntityPercentage}%`"
                    :button-disable="(addingValue) => !addingValue.financingEntity || !addingValue.financingEntityPercentage"
                    :list-size="INPUT_SIZES.MEDIUM"
                    :error="form.errors.financingEntities"
                    :model-value="auxiliarListsStore.financingEntities"
                    :has-edit="true"
                    @on-list-update="(value) => {
                        auxiliarListsStore.financingEntities = value
                        onChange(value.map((item) => ({financingEntity: item.financingEntity.id, financingEntityPercentage: item.financingEntityPercentage, entityType: item.financingEntity.type})), 'financingEntities')
                        onSearch('', 'financingEntities', form.financingEntities)
                    }"
                    @on-edit="(value, toAddContent) => {
                        toAddContent.financingEntity = value.financingEntity
                        toAddContent.financingEntityPercentage = value.financingEntityPercentage
                }   "
                >
                    <Dropdown
                        :label="$t('financing_entities_single')"
                        :model-value="listProps.toAddContent.financingEntity"
                        :list="financingEntities"
                        :search-value="financingEntityQuery"
                        @on-change="(value) => listProps.toAddContent.financingEntity = value"
                        @on-search="(value) => onSearch(value, 'financingEntities', form.financingEntities)"
                    />
                    <Input
                        :label="$t('financing_entities_percentage')"
                        type="number"
                        :model-value="listProps.toAddContent.financingEntityPercentage"
                        @on-change="(value) => listProps.toAddContent.financingEntityPercentage = value"
                    />
                </ComposedListAlternative>
                <ComposedListAlternative
                    class="mt-8"
                    v-slot="listProps"
                    :label="$t('financing_programs')"
                    :list-display-name="(item) =>item.financingProgram.designation"
                    :button-disable="(addingValue) => !addingValue.financingProgram"
                    :list-size="INPUT_SIZES.MEDIUM"
                    :error="form.errors.financingPrograms"
                    :is-required="true"
                    :model-value="auxiliarListsStore.financingPrograms"
                    @on-list-update="(value) => {
                        auxiliarListsStore.financingPrograms = value
                        onChange(value.map((item) => item.financingProgram.id), 'financingPrograms')
                        onSearch('', 'financingPrograms', form.financingPrograms)
                    }"
                >
                    <Dropdown
                        :label="$t('financing_programs_single')"
                        :model-value="listProps.toAddContent.financingProgram"
                        :list="financingPrograms"
                        :search-value="financingProgramQuery"
                        :is-required="true"
                        @on-change="(value) => listProps.toAddContent.financingProgram = value"
                        @on-search="(value) => onSearch(value, 'financingPrograms', form.financingPrograms)"

                    />
                </ComposedListAlternative>
                <FundsDecomposition
                    v-if="form.financingPrograms.length !== 0"
                    :section-name="$t('financing_programs_value_distribution')"
                    :error="form.errors.financingProgramValueDecomposition"
                    :decompositions-financing="financingDecompositionInputs"
                    :label-name="(value) => value.financingProgramName"
                    @on-change="(value) => onChange(value, 'financingProgramValueDecomposition')"
                />
                <ComposedListAlternative
                    class="mt-8"
                    v-slot="listProps"
                    :label="$t('fcul_decomposition_models')"
                    :list-display-name="(item) =>item.fculDecompositionModels.designation"
                    :button-disable="(addingValue) => !addingValue.fculDecompositionModels"
                    :list-size="INPUT_SIZES.MEDIUM"
                    :model-value="auxiliarListsStore.fculDecompositionModels"
                    @on-list-update="(value) => {
                          auxiliarListsStore.fculDecompositionModels = value
                          onChange(value.map((item) => item.fculDecompositionModels.id), 'fculDecompositionModels')
                          onSearch('', 'fculDecompositionModel', form.fculDecompositionModels)
                      }"
                >
                    <Dropdown
                        :label="$t('fcul_decomposition_models_single')"
                        :model-value="listProps.toAddContent.fculDecompositionModels"
                        :list="fculDecompositionModels"
                        :search-value="fculDecompositionModelsQuery"
                        @on-change="(value) => listProps.toAddContent.fculDecompositionModels = value"
                        @on-search="(value) => onSearch(value, 'fculDecompositionModel', form.fculDecompositionModels)"

                    />
                </ComposedListAlternative>
                <FundsDecomposition
                    v-if="form.fculDecompositionModels.length !== 0"
                    :section-name="$t('fcul_decomposition_models_distribution')"
                    :decompositions-financing="fculDecompositionModelsInputs"
                    :error="form.errors.fculDecompositionModelsDecomposition"
                    :label-name="(value) => value.designation"
                    @on-change="(value) => onChange(value, 'fculDecompositionModelsDecomposition')"
                />
                <ComposedListAlternative
                      class="mt-8"
                      v-slot="listProps"
                      :label="$t('participant_entities')"
                      :has-edit="true"
                      :list-display-name="(item) =>`
                                <b>${$t('participant_entities')}:</b> ${item.participantEntity?.designation} </br>
                                ${item.participantEntityResponsible? (`<b>${$t('participant_entities_responsible')}:</b> ${item.participantEntityResponsible.designation}</br>`) : ''}
                                <b>${$t('participation_role_single_entities')}:</b> ${item.participantEntityRole.designation} </br>
                                ${item.participantEntityFinancedValue? (`<b>${$t('participant_entity_financed_value')}:</b> ${formater(item.participantEntityFinancedValue)} €  </br>`) : ''}
                                ${item.participantEntityBudgetValue? (`<b>${$t('participant_entity_budget_value')}:</b> ${formater(item.participantEntityBudgetValue)} €  </br>`) : ''}
                                ${item.participantEntityOverheadsValue? (`<b>${$t('participant_entity_participation_overheads')}:</b> ${formater(item.participantEntityOverheadsValue)} €`) : ''}
                            `
                        "
                      :button-disable="(addingValue) => !addingValue.participantEntity || ((addingValue.participantEntity?.id === fculEntity.id ||addingValue.participantEntity?.id === fcienciasEntity.id) && (!addingValue.participantEntityFinancedValue || !addingValue.participantEntityBudgetValue)) ||!addingValue.participantEntityRole"
                      :list-size="INPUT_SIZES.XXLARGE"
                      :is-required="true"
                      :error="form.errors.participantEntities"
                      :model-value="auxiliarListsStore.participantEntities"
                      @on-list-update="(value) => {
                            auxiliarListsStore.participantEntities = value
                            onChange(value.map(item => (
                                {
                                    participantEntity: item.participantEntity.id,
                                    participantEntityResponsible: item.participantEntityResponsible?.id,
                                    participantEntityResponsibleFrom: item.participantEntityResponsible?.from,
                                    participationEntityRole: item.participantEntityRole.id,
                                    participantEntityFinancedValue: item.participantEntityFinancedValue,
                                    participantEntityBudgetValue: item.participantEntityBudgetValue,
                                    participantEntityOverheadsValue: item.participantEntityOverheadsValue
                                }
                            )), 'participantEntities');
                            onSearch(
                                '',
                                'participantEntities',
                                form.participantEntities.map((item) => item.participantEntity)
                            );
                            onSearch(value.id, 'selectedParticipantEntity')
                      }"
                      @on-edit="(value, toAddContent) => {
                          auxiliarListsStore.participantEntities = value
                          toAddContent.participantEntity = value.participantEntity
                          onSearch(value.participantEntity.id, 'selectedParticipantEntity')

                          toAddContent.participantEntityRole = value.participantEntityRole
                          toAddContent.participantEntityResponsible = value.participantEntityResponsible
                          toAddContent.participantEntityFinancedValue = value.participantEntityFinancedValue
                          toAddContent.participantEntityBudgetValue = value.participantEntityBudgetValue
                          toAddContent.participantEntityOverheadsValue = value.participantEntityOverheadsValue
                      }"
                >
                    <div class="flex justify-between">
                        <button
                            class="w-2/5 mt-1.5 px-4 py-2 rounded-lg bg-fculColor text-white font-bold drop-shadow-2xl shadow-black hover:bg-opacity-60 disabled:bg-opacity-60"
                            @click.prevent="() => {
                                listProps.toAddContent.participantEntity = fculEntity
                                queryParameters.entityType = 'entidade'
                                onSearch(fculEntity.id, 'selectedParticipantEntity')
                            }"
                        >
                            CIÊNCIAS
                        </button>
                        <button
                            class="w-2/5 mt-1.5 px-4 py-2 rounded-lg bg-fculColor text-white font-bold drop-shadow-2xl shadow-black hover:bg-opacity-60 disabled:bg-opacity-60"
                            @click.prevent="() => {
                                listProps.toAddContent.participantEntity = fcienciasEntity
                                queryParameters.entityType = 'entidade'
                                onSearch(fcienciasEntity.id, 'selectedParticipantEntity')
                            }"
                        >
                            FCiências.ID
                        </button>
                    </div>
                    <Dropdown
                        :label="$t('participant_entities_single')"
                        :model-value="listProps.toAddContent.participantEntity"
                        :list="participantEntities"
                        :search-value="participantEntityQuery"
                        :is-required="true"
                        @on-change="(value) => {
                            listProps.toAddContent.participantEntity = value
                            if(value) {
                                queryParameters.entityType = value?.type
                            }
                            onSearch(value? value.id :'', 'selectedParticipantEntity')
                        }"
                        @on-search="(value) => onSearch(value, 'participantEntities', form.participantEntities.map((item) => item.participantEntity))"
                    />
                    <Dropdown
                        :label="$t('participant_entities_responsible')"
                        :model-value="listProps.toAddContent.participantEntityResponsible"
                        :list="participantEntityResponsibles"
                        :search-value="participantEntityResponsibleQuery"
                        @on-change="(value) => listProps.toAddContent.participantEntityResponsible = value"
                        @on-search="(value) => {
                            onSearch(listProps.toAddContent.participantEntity.id, 'selectedParticipantEntity')
                            onSearch(value, 'participantEntityResponsible')
                        }"
                    />
                    <Dropdown
                        :label="$t('participation_role_single_entities')"
                        :model-value="listProps.toAddContent.participantEntityRole"
                        :list="financingProgramEntityRoles"
                        :search-value="participantEntityRoleQuery"
                        :is-required="true"
                        @on-change="(value) => listProps.toAddContent.participantEntityRole = value"
                        @on-search="(value) => onSearch(value, 'participantEntityRole')"
                    />
                    <Input
                        :label="$t('participant_entity_financed_value')"
                        type="number"
                        :model-value="listProps.toAddContent.participantEntityFinancedValue"
                        :is-required="listProps.toAddContent?.participantEntity?.id === fculEntity.id || listProps.toAddContent?.participantEntity?.id === fcienciasEntity.id"
                        @on-change="(value) => listProps.toAddContent.participantEntityFinancedValue = value"
                    />
                    <Input
                        :label="$t('participant_entity_budget_value')"
                        type="number"
                        :model-value="listProps.toAddContent.participantEntityBudgetValue"
                        :is-required="listProps.toAddContent?.participantEntity?.id === fculEntity.id || listProps.toAddContent?.participantEntity?.id === fcienciasEntity.id"
                        @on-change="(value) => listProps.toAddContent.participantEntityBudgetValue = value"
                    />
                    <Input
                        :label="$t('participant_entity_participation_overheads')"
                        type="number"
                        :model-value="listProps.toAddContent.participantEntityOverheadsValue"
                        @on-change="(value) => listProps.toAddContent.participantEntityOverheadsValue = value"
                    />
                </ComposedListAlternative>
            </Separator>
            <Separator class="bg-opacity-80" :separator-name="`${$t('project_ids')} ${$t('cost_center')}`">
            <ComposedListAlternative
                class="mt-8"
                :label="$t('project_list_fcul')"
                v-slot="listProps"
                :list-display-name="(item) => item.costCenterId"
                :button-disable="(addingValue) => !addingValue.costCenterId"
                :model-value="auxiliarListsStore.listProjectFcul"
                @on-list-update="(value) => {
                        auxiliarListsStore.listProjectFcul = value
                        onChange(value, 'listProjectFcul')
                    }"
            >
              <Input
                  :label="$t('project_id')"
                  :model-value="listProps.toAddContent.costCenterId"
                  @on-change="(value) => listProps.toAddContent.costCenterId = value"
              />
              <Input
                  :label="$t('project_description')"
                  :model-value="listProps.toAddContent.projectDescription"
                  :input-size="INPUT_SIZES.XLARGE"
                  :not-resizable="true"
                  @on-change="(value) => listProps.toAddContent.projectDescription = value"
              />
            </ComposedListAlternative>
            <ComposedListAlternative
                class="mt-8"
                :label="$t('project_list_fciencias')"
                v-slot="listProps"
                :list-display-name="(item) => item.costCenterId"
                :button-disable="(addingValue) => !addingValue.costCenterId"
                :model-value="auxiliarListsStore.listProjectFCiencias"
                @on-list-update="(value) => {
                        auxiliarListsStore.listProjectFCiencias = value
                        onChange(value, 'listProjectFCiencias')
                    }"
            >
              <Input
                  :label="$t('project_id')"
                  :model-value="listProps.toAddContent.costCenterId"
                  @on-change="(value) => listProps.toAddContent.costCenterId = value"
              />
              <Input
                  :label="$t('project_description')"
                  :model-value="listProps.toAddContent.projectDescription"
                  :input-size="INPUT_SIZES.XLARGE"
                  :not-resizable="true"
                  @on-change="(value) => listProps.toAddContent.projectDescription = value"
              />
            </ComposedListAlternative>
            <ComposedListAlternative
                class="mt-8"
                :label="$t('project_list_ffcul')"
                v-slot="listProps"
                :list-display-name="(item) => item.costCenterId"
                :button-disable="(addingValue) => !addingValue.costCenterId"
                :model-value="auxiliarListsStore.listProjectFFcul"
                @on-list-update="(value) => {
                        auxiliarListsStore.listProjectFFcul = value
                        onChange(value, 'listProjectFFcul')
                    }"
            >
              <Input
                  :label="$t('project_id')"
                  :model-value="listProps.toAddContent.costCenterId"
                  @on-change="(value) => listProps.toAddContent.costCenterId = value"
              />
              <Input
                  :label="$t('project_description')"
                  :model-value="listProps.toAddContent.projectDescription"
                  :input-size="INPUT_SIZES.XLARGE"
                  :not-resizable="true"
                  @on-change="(value) => listProps.toAddContent.projectDescription = value"
              />
            </ComposedListAlternative>
          </Separator>
            <div class="mt-2" v-if="Object.keys(form.errors).length > 0">
                <p class="font-bold">{{$t('errors')}}:</p>
                <p class="text-decline font-bold ml-4" v-for="error in form.errors">{{$t(error)}}</p>
            </div>
        </div>
    </CreateForm>
</template>

<script setup>

import {router, useForm} from "@inertiajs/vue3";
import Input from "../../Components/Input.vue";
import Dropdown from "../../Components/Dropdown.vue"
import CreateForm from "../../Components/Forms/CreateForm.vue";
import {ref} from "vue";
import {INPUT_SIZES} from "../../Components/Input.vue";
import Separator from "../../Components/Projeto/Separator.vue";
import ComposedListAlternative from "../../Components/Projeto/ComposedList.vue";
import FundsDecomposition from "../../Components/Projeto/FundsDecomposition.vue";
import _ from "lodash";
import {value} from "lodash/seq.js";
import {formater, isEmpty} from "../../Utils.js";

//Internal Properties
const queryParameters = ref({})
const auxiliarListsStore = ref({})

//External Properties
const props = defineProps({
    fculEntity: Object,
    fcienciasEntity: Object,
    scopes: Array,
    scopeQuery: String,
    typologies: Array,
    typologyQuery: String,
    financingOrigins: Array,
    categoryQuery: String,
    managementCoresFcul: Array,
    managementCoreFculQuery: String,
    managementCoresFCiencias: Array,
    managementCoreFCienciasQuery: String,
    scientificAreas: Array,
    scientificAreaQuery: String,
    socialEconomyCategories: Array,
    socialEconomyCategoryQuery: String,
    sustainableDevelopmentGoals: Array,
    sustainableDevelopmentGoalQuery: String,
    keywords: Array,
    keywordQuery: String,
    publications: Array,
    publicationQuery: String,
    financingEntities: Array,
    financingEntityQuery: String,
    financingDecompositionInputs: Array,
    financingPrograms: Array,
    financingProgramQuery: String,
    financingProgramEntityRoles: Array,
    participantEntityRoleQuery: String,
    participantEntities: Array,
    participantEntityQuery: String,
    participantEntityResponsibles: Array,
    participantEntityResponsibleQuery: String,
    fculDecompositionModels: Array,
    fculDecompositionModelsQuery: String,
    fculDecompositionModelsInputs: Array,
    fculMembers: Array,
    fculMemberQuery: String,
    participationRoles: Array,
    participationRoleQuery: String,
    investigationStructures: Array,
    investigationStructureQuery: String,
    departments: Array,
    departmentQuery: String,
    serviceUnits: Array,
    serviceUnitQuery: String,
    otherInternalEntities: Array,
    otherInternalEntityQuery: String
})

const form = useForm({
    titlePt: "",
    titleEn: "",
    acronym: "",
    externalReference: "",
    scope: null,
    typology: null,
    startDate: "",
    endDate: "",
    financingOrigin: null,
    doi: "",
    managementCoreFcul: null,
    managementCoreFCiencias: null,
    startTRL: "",
    targetTRL: "",
    scientificDescriptionPt: "",
    simplifiedDescriptionPt: "",
    scientificDescriptionEn: "",
    simplifiedDescriptionEn: "",
    listProjectFcul: [],
    listProjectFCiencias: [],
    listProjectFFcul: [],
    scientificAreas: [],
    socialEconomyCategories: [],
    sustainableDevelopmentGoals: [],
    helpfulHyperlinks: [],
    rewards: [],
    impactPt: "",
    impactEn: "",
    ethicPt: "",
    ethicEn: "",
    personalDataManagementPlanPt: "",
    personalDataManagementPlanEn: "",
    logos: [],
    documents: [],
    keywords: [],
    publications: [],
    totalFinancingValue: "",
    totalBudgetValue: "",
    financingEntities: [],
    financingPrograms: [],
    financingProgramValueDecomposition: [],
    participantEntities: [],
    fculDecompositionModels: [],
    fculDecompositionModelsDecomposition: [],
    fculMembers: [],
    investigationStructures: [],
    departments: [],
    serviceUnits: [],
    otherInternalEntities: []
})

//Functions
const onSearch =  _.throttle((value, property, used) => {

    if (queryParameters.value && value === ""){
        delete queryParameters.value[property]
    } else {
        queryParameters.value[property] = value
    }

    if (used) {
        queryParameters.value[property + "Used"] = JSON.stringify(used)
    }

    router.get(route('projects.create'), queryParameters.value, {
        preserveState: true,
    })
}, 1000)

const onChange = (value, propertyName) => {
    if (form.errors[propertyName]) {
        form.errors[propertyName] = null;
    }
    form[propertyName] = ((value !== undefined)? (value.id? value.id : value) : null);
}

const onSubmit = () => {
    form.post(route('projects.store'))
}



const submitDisabled = () => {
    return (isEmpty(form.titlePt)
        && isEmpty(form.titleEn))
        || isEmpty(form.acronym)
        || isEmpty(form.scope)
        || isEmpty(form.typology)
        || isEmpty(form.startDate)
        || isEmpty(form.endDate)
        || isEmpty(form.totalFinancingValue)
        || isEmpty(form.participantEntities)
        || isEmpty(form.fculMembers)
        || isEmpty(form.financingOrigin)
}



</script>

<style>
textarea:focus, input:focus{
  outline: 0.19rem solid;
  outline-color: #2C3FB1;
}
</style>
