<template>
    <EditForm
        class=""
        :title="$t('projects_update_scientific')"
        :action-button-disabled="submitDisabled()"
        :return-route="route('projects.show', project.id)"
        @submit="onSubmit"
    >
        <div class="w-full flex flex-wrap justify-between px-2 lg:px-0">
            <FundusInput
                :label="$t('scientific_description_pt')"
                class="w-full"
                :error="form.errors.scientificDescriptionPt"
                :model-value="form.scientificDescriptionPt"
                :input-size="INPUT_SIZES.XXLARGE"
                :rich-text="true"
                @on-change="(value) => onChange(value, 'scientificDescriptionPt')"
            />
            <FundusInput
                :label="$t('scientific_description_en')"
                class="w-full"
                :error="form.errors.scientificDescriptionEn"
                :model-value="form.scientificDescriptionEn"
                :input-size="INPUT_SIZES.XXLARGE"
                :rich-text="true"
                @on-change="(value) => onChange(value, 'scientificDescriptionEn')"
            />
            <FundusInput
                :label="$t('simplified_description_pt')"
                class="w-full"
                :error="form.errors.simplifiedDescriptionPt"
                :model-value="form.simplifiedDescriptionPt"
                :input-size="INPUT_SIZES.XLARGE"
                :rich-text="true"
                @on-change="(value) => onChange(value, 'simplifiedDescriptionPt')"
            />
            <FundusInput
                :label="$t('simplified_description_en')"
                class="w-full"
                :error="form.errors.simplifiedDescriptionEn"
                :model-value="form.simplifiedDescriptionEn"
                :input-size="INPUT_SIZES.XLARGE"
                :rich-text="true"
                @on-change="(value) => onChange(value, 'simplifiedDescriptionEn')"
            />
            <div class="flex w-full lg:w-1/2 justify-between flex-col lg:flex-row">
                <FundusInput
                    :label="$t('start_trl')"
                    :error="form.errors.startTRL"
                    :model-value="form.startTRL"
                    :input-size="INPUT_SIZES.SMALL"
                    type="number"
                    @on-change="(value) => {

                            onChange(value, 'startTRL')

                            let number = Number.parseInt(value)
                            delete form.errors.targetTRL
                            if(form.targetTRL && value !== ''&& Number.parseInt(form.targetTRL) < number) {
                                form.errors.startTRL = 'higher_than_target'
                            } else if(number < 0 || number > 10) {
                                form.errors.startTRL = 'invalid_trl'
                            } else {
                                delete form.errors.startTRL
                            }
                        }"
                />
                <FundusInput
                    :label="$t('target_trl')"
                    :error="form.errors.targetTRL"
                    :model-value="form.targetTRL"
                    :input-size="INPUT_SIZES.SMALL"
                    type="number"
                    @on-change="(value) => {

                            onChange(value, 'targetTRL')

                            let number = Number.parseInt(value)
                            delete form.errors.startTRL
                            if(form.startTRL && value !== '' && Number.parseInt(form.startTRL) > number) {
                                form.errors.targetTRL = 'lower_than_start'
                            } else if (number < 0 || number > 10) {
                                form.errors.targetTRL = 'invalid_trl'
                            } else {
                                delete form.errors.targetTRL
                            }
                        }"
                />
            </div>
            <FundusInput
                :label="$t('impact_pt')"
                class="w-full"
                :error="form.errors.impactPt"
                :model-value="form.impactPt"
                :input-size="INPUT_SIZES.XXLARGE"
                :rich-text="true"
                @on-change="(value) => onChange(value, 'impactPt')"
            />
            <FundusInput
                :label="$t('impact_en')"
                class="w-full"
                :error="form.errors.impactEn"
                :model-value="form.impactEn"
                :input-size="INPUT_SIZES.XXLARGE"
                :rich-text="true"
                @on-change="(value) => onChange(value, 'impactEn')"
            />
            <FundusInput
                :label="$t('ethic_pt')"
                class="w-full"
                :error="form.errors.ethicPt"
                :model-value="form.ethicPt"
                :input-size="INPUT_SIZES.XXLARGE"
                :rich-text="true"
                @on-change="(value) => onChange(value, 'ethicPt')"
            />
            <FundusInput
                :label="$t('ethic_en')"
                class="w-full"
                :error="form.errors.ethicEn"
                :model-value="form.ethicEn"
                :input-size="INPUT_SIZES.XXLARGE"
                :rich-text="true"
                @on-change="(value) => onChange(value, 'ethicEn')"
            />
            <FundusInput
                :label="$t('personal_data_management_plan_pt')"
                class="w-full"
                :error="form.errors.personalDataManagementPlanPt"
                :model-value="form.personalDataManagementPlanPt"
                :input-size="INPUT_SIZES.XXLARGE"
                :rich-text="true"
                @on-change="(value) => onChange(value, 'personalDataManagementPlanPt')"
            />
            <FundusInput
                :label="$t('personal_data_management_plan_en')"
                class="w-full"
                :error="form.errors.personalDataManagementPlanEn"
                :model-value="form.personalDataManagementPlanEn"
                :input-size="INPUT_SIZES.XXLARGE"
                :rich-text="true"
                @on-change="(value) => onChange(value, 'personalDataManagementPlanEn')"
            />

            <ComposedListAlternative
                class="mt-2 bg-fculColor bg-opacity-60 text-white"
                v-slot="listProps"
                :label="$t('keywords')"
                :list-display-name="(item) => !item.keyword? item.keywordDesignation : item.keyword.designation"
                :button-disable="(addingValue) => keywords.length === 0? !addingValue.keywordDesignation || !addingValue.keywordAlternativeDesignation : !addingValue.keyword"
                :model-value="project.keywords.map(item => ({keyword: item}))"
                @on-list-update="(value) => {
                            onChange(value.map( item =>  (item.keyword?
                                ({keywordId: item.keyword.id}) :
                                ({
                                    keywordDesignation: item.keywordDesignation,
                                     keywordAlternativeDesignation: item.keywordAlternativeDesignation
                                })
                            )), 'keywords')
                            onSearch('', 'keyword', form.keywords.filter((item) => item.keywordId && !isNaN(item.keywordId)).map(item => item.keywordId))
                        }"
            >
              <Dropdown
                  :label="$t('keywords_single')"
                  :list="keywords"
                  :model-value="listProps.toAddContent.keyword"
                  :search-value="keywordQuery"
                  @on-change="(value) => listProps.toAddContent.keyword = value"
                  @on-search="(value) => {
                                    listProps.toAddContent.keywordDesignation = value

                                    onSearch(value, 'keyword', form.keywords.filter((item) => item.keywordId && !isNaN(item.keywordId)).map(item => item.keywordId))
                                  }"
              />
              <Input
                  v-if="keywords.length === 0"
                  :label="$t('keywords_single_other_idiom')"
                  :model-value="listProps.toAddContent.keywordAlternativeDesignation"
                  @on-change="(value) => listProps.toAddContent.keywordAlternativeDesignation = value"
              />
            </ComposedListAlternative>
            <ComposedListAlternative
                class="mt-8 bg-fculColor bg-opacity-60 text-white"
                :label="$t('scientific_areas')"
                v-slot="listProps"
                :list-display-name="(item) => item.scientificArea.designation"
                :button-disable="(addingValue) => !addingValue.scientificArea"
                :list-size="INPUT_SIZES.MEDIUM"
                :error="form.errors.scientificAreas"
                :model-value="project.scientificAreas.map(item => ({scientificArea: item}))"
                @on-list-update="(value) => {
                            onChange(value.map((item) => item.scientificArea.id), 'scientificAreas')
                            onSearch('', 'scientificArea', form.scientificAreas)
                        }"
            >
                <Dropdown
                    :label="$t('scientific_areas_single')"
                    :model-value="listProps.toAddContent.scientificArea"
                    :list="scientificAreas"
                    :search-value="scientificAreaQuery"
                    @on-change="(value) => listProps.toAddContent.scientificArea = value"
                    @on-search="(value) => onSearch(value, 'scientificArea', form.scientificAreas)"

                />
            </ComposedListAlternative>
            <ComposedListAlternative
                class="mt-8 bg-fculColor bg-opacity-60 text-white"
                :label="$t('social_economical_categories')"
                v-slot="listProps"
                :list-display-name="(item) => item.socialEconomyCategory.designation"
                :button-disable="(addingValue) => !addingValue.socialEconomyCategory"
                :list-size="INPUT_SIZES.MEDIUM"
                :model-value="project.socialEconomyCategories.map(item => ({socialEconomyCategory: item}))"
                @on-list-update="(value) => {
                          onChange(value.map((item) => item.socialEconomyCategory.id), 'socialEconomyCategories')
                          onSearch('', 'socialEconomyCategory', form.socialEconomyCategories)
                      }"
            >
                <Dropdown
                    :label="$t('social_economical_categories_single')"
                    :model-value="listProps.toAddContent.socialEconomyCategory"
                    :list="socialEconomyCategories"
                    :search-value="socialEconomyCategoryQuery"
                    @on-change="(value) => listProps.toAddContent.socialEconomyCategory = value"
                    @on-search="(value) => onSearch(value, 'socialEconomyCategory', form.socialEconomyCategories)"

                />
            </ComposedListAlternative>
            <ComposedListAlternative
                class="mt-8 bg-fculColor bg-opacity-60 text-white"
                :label="$t('sustainable_development_goals')"
                v-slot="listProps"
                :list-display-name="(item) => item.sustainableDevelopmentGoal.designation"
                :button-disable="(addingValue) => !addingValue.sustainableDevelopmentGoal"
                :list-size="INPUT_SIZES.MEDIUM"
                :model-value="project.sustainableDevelopmentGoals.map(item => ({sustainableDevelopmentGoal: item}))"
                @on-list-update="(value) => {
                        onChange(value.map((item) => item.sustainableDevelopmentGoal.id), 'sustainableDevelopmentGoals')
                        onSearch('', 'sustainableDevelopmentGoal', form.sustainableDevelopmentGoals)
                    }"
            >
                <Dropdown
                    :label="$t('sustainable_development_goals_single')"
                    :model-value="listProps.toAddContent.sustainableDevelopmentGoal"
                    :list="sustainableDevelopmentGoals"
                    :search-value="sustainableDevelopmentGoalQuery"
                    @on-change="(value) => listProps.toAddContent.sustainableDevelopmentGoal = value"
                    @on-search="(value) => onSearch(value, 'sustainableDevelopmentGoal', form.sustainableDevelopmentGoals)"

                />
            </ComposedListAlternative>
            <ComposedListAlternative
                class="mt-8 bg-fculColor bg-opacity-60 text-white"
                v-slot="listProps"
                :label="$t('helpful_hyperlinks')"
                :list-display-name="(item) => item.hyperlinkDesignation"
                :button-disable="(addingValue) => !addingValue.hyperlinkDesignation || !addingValue.hyperlink"
                :list-size="INPUT_SIZES.MEDIUM"
                :model-value="form.helpfulHyperlinks"
                @on-list-update="(value) => {
                          onChange(value, 'helpfulHyperlinks')
                      }"
            >
                <Input
                    :label="$t('helpful_hyperlinks_designation')"
                    :model-value="listProps.toAddContent.hyperlinkDesignation"
                    @on-change="(value) => listProps.toAddContent.hyperlinkDesignation = value"
                />
                <Input
                    :label="$t('helpful_hyperlinks_single')"
                    :model-value="listProps.toAddContent.hyperlink"
                    @on-change="(value) => listProps.toAddContent.hyperlink = value"
                />
            </ComposedListAlternative>

            <ComposedListAlternative
                class="mt-8 bg-fculColor bg-opacity-60 text-white"
                v-slot="listProps"
                :label="$t('rewards')"
                :list-display-name="(item) => item.rewardDesignation"
                :button-disable="(addingValue) => !addingValue.rewardDesignation"
                :list-size="INPUT_SIZES.MEDIUM"
                :model-value="form.rewards"

                @on-list-update="(value) => {
                          onChange(value, 'rewards')
                }"
            >
                <Input
                    :label="$t('rewards_single')"
                    :model-value="listProps.toAddContent.rewardDesignation"
                    @on-change="(value) => listProps.toAddContent.rewardDesignation = value"
                />

                <Input
                    :label="$t('reward_hyperlink')"
                    :model-value="listProps.toAddContent.rewardHyperlink"
                    @on-change="(value) => listProps.toAddContent.rewardHyperlink = value"
                />
            </ComposedListAlternative>
            <ComposedListAlternative
                class="mt-8 bg-fculColor bg-opacity-60 text-white"
                v-slot="listProps"
                :label="$t('documents')"
                :list-display-name="(item) => `${item.documentDesignation}`"
                :button-disable="(addingValue) => !addingValue.documentDesignation || !addingValue.document"
                :list-size="INPUT_SIZES.MEDIUM"
                :model-value="project.documents.map(item => ({
                      id: item.id,
                      documentDesignation: item.designation
                  }))"
                :error="form.errors.documents"
                @on-list-update="(value) => {
                          onChange(value.map(item => ({id: item.id ?? -1, documentDesignation: item.documentDesignation, document: item.document, format: item.format })), 'documents')
                  }"
            >
              <Input
                  :label="$t('documents_designation')"
                  :model-value="listProps.toAddContent.documentDesignation"
                  @on-change="(value) => listProps.toAddContent.documentDesignation = value"
              />
              <Input
                  :label="$t('documents_single')"
                  :model-value="listProps.toAddContent.documentLocation"
                  type="file"
                  @on-change="(binaryValue, location) => {
                            listProps.toAddContent.documentLocation = location
                            listProps.toAddContent.document = binaryValue
                            listProps.toAddContent.format = location.split('.').at(-1)
                          }"
                  @error="(e) => {
                            form.errors.documents = $t(e)
                        }"

              />
            </ComposedListAlternative>
        </div>
    </EditForm>
</template>

<script setup>
import FundusInput from "../../../Components/Input.vue"
import {INPUT_SIZES} from "../../../Components/Input.vue";
import {router, useForm} from "@inertiajs/vue3";
import Dropdown from "../../../Components/Dropdown.vue";
import _, {isEqual} from "lodash";
import {ref} from "vue";
import Input from "../../../Components/Input.vue";
import ComposedListAlternative from "../../../Components/Projeto/ComposedList.vue";
import EditForm from "../../../Components/Forms/EditForm.vue";

const props = defineProps({
    project: Object,
    scientificAreas: Array,
    scientificAreaQuery: String,
    socialEconomyCategories: Array,
    socialEconomyCategoryQuery: String,
    sustainableDevelopmentGoals: Array,
    sustainableDevelopmentGoalQuery: String,
    keywords: Array,
    keywordQuery: String,

})



const form = useForm({
    type: "SCIENTIFIC_INFO",
    scientificDescriptionPt: props.project?.scientificDescriptionPt ?? "",
    scientificDescriptionEn: props.project?.scientificDescriptionEn ?? "",
    simplifiedDescriptionPt: props.project?.simplifiedDescriptionPt ?? "",
    simplifiedDescriptionEn: props.project?.simplifiedDescriptionEn ?? "",
    startTRL: props.project?.startTRL ?? "",
    targetTRL: props.project?.targetTRL ?? "",
    socialEconomyCategories: props.project?.socialEconomyCategories?.map(item => item.id) ?? [],
    scientificAreas: props.project?.scientificAreas?.map(item => item.id) ?? [],
    sustainableDevelopmentGoals: props.project?.sustainableDevelopmentGoals?.map(item => item.id) ?? [],
    impactPt: props.project?.impactPt ?? "",
    impactEn: props.project?.impactEn ?? "",
    ethicPt: props.project?.ethicPt ?? "",
    ethicEn: props.project?.ethicEn ?? "",
    personalDataManagementPlanPt: props.project?.personalDataManagementPlanPt ?? "",
    personalDataManagementPlanEn: props.project?.personalDataManagementPlanEn ?? "",
    helpfulHyperlinks: props.project?.helpfulHyperlinks ?? [],
    rewards: props.project?.rewards ?? [],
    keywords: props.project?.keywords ?? [],
    documents: props.project?.documents?.map(item => ({
      id: item.id,
      documentDesignation: item.designation
    }))
});

const queryParameters = ref({})

const onSearch =  _.throttle((value, property, used) => {

    if (queryParameters.value && value === ""){
        delete queryParameters.value[property]
    } else {
        queryParameters.value[property] = value
    }

    if (used) {
        queryParameters.value[property + "Used"] = JSON.stringify(used)
    }

    router.get(route('projects.edit.scientific', props.project?.id), queryParameters.value, {
        preserveState: true,
    })
}, 1000)

const onChange = (value, propertyName) => {

    if (form.errors[propertyName]) {
        form.errors[propertyName] = null;
    }
    form[propertyName] = ((value !== undefined)?  value : null);
}

const onSubmit = () => {

    for (let prop in form.data()) {
        if (form[prop] instanceof Object && form[prop].id) {
            form[prop] = form[prop].id
        }
    }

    form.put(route('projects.update', props.project.id))
}


const submitDisabled = () => {

    for (let prop in form.data()) {
        if (prop !== "type" && !Array.isArray(form[prop]) && !isEqual(form[prop],props.project[prop])) {

            if (props.project[prop] == null && form[prop] === "") {
                continue;
            }
                return false

        } else if (Array.isArray(form[prop])) {
            if(!((form[prop].length === props.project[prop].length)
                && form[prop].every((val, index) => props.project[prop].find(item => val.id? item.id === val.id : item.id === val)))) {
                return false
            }
        }
    }

    return true

}

</script>
