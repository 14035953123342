<template>
    <div
        class="w-full h-full flex"
        v-if="data && data.length > 0"
    >
        <div
            class="w-full"
        >
            <Line aria-label="line graphic" :data="{labels: props.labels, datasets: props.data }" :options="options" ref="chartRef"/>
        </div>
    </div>
    <div v-else class="w-full h-full flex justify-center items-center">
        <p>{{$t('no_data_available')}}</p>
    </div>
</template>

<script setup>
import {Line} from "vue-chartjs";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  LinearScale,
  CategoryScale
} from 'chart.js'
import {ref} from "vue";

ChartJS.register(ArcElement, Tooltip, Legend, PointElement, LineElement, LinearScale, CategoryScale)

const props = defineProps({
    colors: Array,
    data: Array,
    labels: Array,
    hideLabelButtons: Boolean,
    yScale: Object,
    hideLegends: Boolean,
    yAxisName: String
})

const chartRef = ref(null);


const emit = defineEmits(['onLabelClick'])

const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        y: props.yScale? props.yScale : {
            min: 0,
            ticks: {
                // Forces the ticks to be integers only
                callback: function(value) {
                    if (!Number.isInteger(value)){
                        return null
                    }

                    return (value >= 0)? value : null;
                },
                stepSize: 1, // Ensures the interval is 1 unit
            },
            title: {
                display: true,
                text: props.yAxisName,  // Title for Y-Axis
                color: '#000'
            }
        },
    },
    plugins: {
        tooltip: {
            callbacks: {
                label: function(context) {
                    return context.dataset.label + ': ' + context.raw;
                }
            }
        },
        legend: {
            display: (props.hideLegends? !props.hideLegends : true),
            onClick: (e, legendItem, legend) => {
                ChartJS.defaults.plugins.legend.onClick(e, legendItem, legend)

                const index = legendItem.datasetIndex;
                const chart = legend.chart;
                const dataset = chart.data.datasets[index];

                emit('onLabelClick', dataset.extra)
            },
        },
    },
}

</script>
