<template>
    <div
        class="flex flex-col w-full lg:w-auto py-2"
        :class="{
            'lg:w-full': inputSize === INPUT_SIZES.XXLARGE || inputSize === INPUT_SIZES.XLARGE || inputSize === INPUT_SIZES.LARGE
        }"
    >
        <label :for="label + random" v-if="label" class="mb-1 mt-2 pl-2 font-bold">{{label}} {{isRequired? "*" : ""}} </label>
        <input
            :id="label + random"
            v-if="!inputSize || (inputSize !== INPUT_SIZES.XXLARGE && inputSize !== INPUT_SIZES.XLARGE && inputSize !== INPUT_SIZES.LARGE)"
            class=" px-4  py-2 rounded-lg drop-shadow-xl shadow-black text-black w-full"
            :class="{
                'outline': error,
                'outline-decline': error,
                'lg:w-40': inputSize === INPUT_SIZES.XSMALL,
                'lg:w-96': inputSize === INPUT_SIZES.MEDIUM || !inputSize,
                'lg:w-60': inputSize === INPUT_SIZES.SMALL,
                'bg-fculColor': invertColor,
                'bg-opacity-80': invertColor,
                'text-white': invertColor,
                'placeholder-gray-300': invertColor,
            }"
            :placeholder="label || placeholder"
            :value="modelValue"
            @input="onValueUpdate"
            :type="type? type : 'text'"
            :accept="accept"
            :disabled="disabled"
            step="0.01"
        >
        <textarea
            v-if="!richText && (inputSize === INPUT_SIZES.XXLARGE || inputSize === INPUT_SIZES.XLARGE || inputSize === INPUT_SIZES.LARGE)"
            class=" px-4 my-1.5 py-2 rounded-lg drop-shadow-xl shadow-black text-black w-full border-0 focus:border-2"
            :class="{
                'outline': error,
                'outline-decline': error,
                'min-h-48': inputSize === INPUT_SIZES.XXLARGE,
                'min-h-36': inputSize === INPUT_SIZES.XLARGE,
                'min-h-24': inputSize === INPUT_SIZES.LARGE,
                'resize-y': !notResizable,
                'resize-none': notResizable,
                'bg-fculColor': invertColor,
                'bg-opacity-80': invertColor,
                'text-white': invertColor,
                'placeholder-gray-300': invertColor,
            }"
            :placeholder="label || placeholder"
            :value="modelValue"
            @input="onValueUpdate"
            :disabled="disabled"
            :required="isRequired"
        />
        <QuillEditor
            ref="quillEditor"
            class="rounded-b-lg px-4 my-1.5 py-2 drop-shadow-xl shadow-black bg-white text-black w-full border-0 focus:border-2 overflow-x-scroll"
            :class="{
                'outline': error,
                'outline-decline': error,
                'min-h-48': inputSize === INPUT_SIZES.XXLARGE,
                'min-h-36': inputSize === INPUT_SIZES.XLARGE,
                'min-h-24': inputSize === INPUT_SIZES.LARGE,
                'max-h-48': inputSize === INPUT_SIZES.XXLARGE,
                'max-h-36': inputSize === INPUT_SIZES.XLARGE,
                'max-h-24': inputSize === INPUT_SIZES.LARGE,
                'bg-fculColor': invertColor,
                'bg-opacity-80': invertColor,
                'text-white': invertColor,
                'placeholder-gray-300': invertColor,
            }"
            v-if="richText"
            v-model:content="value"
            theme="snow"
        />
        <h1 class="ml-2 h-4 text-decline" v-if="error">{{$t(error)}}</h1>
    </div>
</template>
designation

<script setup>

    import DOMPurify from "dompurify";
    import {Delta, Quill, QuillEditor} from "@vueup/vue-quill";
    import {ref, watch} from "vue";

    const emit = defineEmits(['onChange', 'error']);

    const props = defineProps({
        label: String,
        isRequired: Boolean,
        placeholder: String,
        error: String,
        modelValue: String,
        inputSize: String,
        type: String,
        accept: String,
        notResizable: Boolean,
        disabled: Boolean,
        invertColor: Boolean,
        richText: Boolean
    })

    const value = ref(props.modelValue)

    const maxSize = 4 * 1024 * 1024 * 1024;
    const onValueUpdate = (event) => {
        if (props.type === "file") {
            if (event.target.files[0].size >= maxSize) {
                emit('error', 'maxSizeReached')
            } else {
                let fileReader = new FileReader();
                fileReader.readAsDataURL(event.target.files[0]);

                fileReader.onload = (e) => {
                    emit('onChange', e.target.result, event.target.value)
                };
            }
        } else  {
            emit('onChange', DOMPurify.sanitize(event.target.value))
        }
    }

    const quillEditor = ref()

    if (props.modelValue) {
        const div = document.createElement('div');
        div.setAttribute('id', 'htmlToDelta');
        div.innerHTML = `<div id="quillEditor" style="display:none">${props.modelValue}</div>`;
        document.body.appendChild(div);
        const quill = new Quill('#quillEditor', {
            theme: 'snow',
        });
        const delta = quill.getContents();
        document.getElementById('htmlToDelta').remove();
        value.value = delta
    }

    watch(value, (val) => {
        var tempCont = document.createElement("div");
        (new Quill(tempCont)).setContents(val);
        const html = tempCont.getElementsByClassName("ql-editor")[0].innerHTML;
        emit('onChange', html)
    })
    const random = Math.floor(Math.random() * 1000);

</script>

<script>
    export const INPUT_SIZES = {
        XSMALL: "xsmall",
        SMALL: "small",
        MEDIUM: "medium",
        LARGE: "large",
        XLARGE: "xlarge",
        XXLARGE: "xxlarge",
        OTHER: "other"
    }
</script>

<style scoped>
/* Target the quill toolbar and apply a rounded style */
::v-deep .ql-toolbar {
    border-radius: 0.5rem 0.5rem 0rem 0rem; /* Adjust for more or less roundness */
    background-color: #ffffff;
    border: 0 #ffffff;
}
</style>
