<template>
    <div class="flex flex-col w-max overflow-x-scroll">
        <h1 class="text-white w-full font-bold mb-2 whitespace-nowrap pr-8">{{title}}: {{formater(multipleLevelsCalculator())}} €</h1>
        <ProjectRecursiveInput
            v-for="attribute in financingProgram.attributesList"
            :input="attribute"
            :values="financingProgram.decompositionInputs"
        />
    </div>
</template>

<script setup>

import ProjectRecursiveInput from "./ProjectRecursiveInput.vue";
import {formater} from "../../Utils.js";

const props = defineProps({
    title: String,
    financingProgram: Object
})

const multipleLevelsCalculator = () => {
    return props.financingProgram.decompositionInputs.filter(program => (program.financingProgramId === props.financingProgram.id) || (program.decompositionModelId === props.financingProgram.id)).reduce((currentValue, previousValue) => (previousValue?.value + currentValue), 0)
}


</script>
