
<template>
    <div
        class="mt-8 w-full flex flex-col bg-background px-8 pt-4  pb-8 text-black rounded-lg drop-shadow-xl shadow-black">
        <h1 class="text-lg font-bold">{{sectionName}} *</h1>
        <h1 class="ml-2 h-4 text-decline" v-if="error">{{error}}</h1>
        <div v-for="decompositionFinancing in decompositionsFinancing"
             class="bg-fculColor bg-opacity-20 py-4 rounded-lg mt-4  flex w-full max-w-full  overflow-x-scroll">
            <div class="ml-6">
                <div class="flex">
                    <h1 class="text-lg font-bold">{{labelName(decompositionFinancing)}}:</h1>
                    <h1 class="text-lg font-bold text-fculColor">{{sum?.find(item => item.id === decompositionFinancing.id)?.value}}</h1>
                </div>
                <RecursiveInputProject
                    v-for="input in decompositionFinancing.decompositionInputs"
                    :input="input"
                    :model-value="modelValue"
                    @on-change="(value) => {

                        if(!values[decompositionFinancing.id]){
                            values[decompositionFinancing.id] = {}
                        }
                        values[decompositionFinancing.id][value.inputId] = value.inputValue

                        const currentSum = sum?.find(item => item.id === decompositionFinancing.id) ?? {}


                        currentSum.value = Object.values(values[decompositionFinancing.id]).reduce((accumulator, currentValue) => (accumulator === ''? 0 : Number.parseInt(accumulator)) + (currentValue === ''? 0 : Number.parseInt(currentValue)))
                        onChange(value, decompositionFinancing.id)
                    }"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import RecursiveInputProject from "../Forms/RecursiveInputProject.vue";
import {ref} from "vue";

const props = defineProps({
  sectionName: String,
  decompositionsFinancing: Object,
  error: String,
  labelName: Function,
  modelValue: Array,
  editValues: Array
})


const startValues = () => {
  const obj = {}

  props.decompositionsFinancing.map(model => {
    obj[model.id] = {}
  })

  props.modelValue[0]?.forEach((value) => {
      if (obj[value.aggregatorId])
        obj[value.aggregatorId][value.inputId] = value.inputValue
  })

  return obj
}

const values = ref(startValues())



const calculateSum = (input) => {
  if (!input?.child?.length) {
    return props.modelValue[0].find(i => input.id === i.inputId)?.inputValue;
  }

  let sum = 0;

  for (let child of input.child) {

    sum += calculateSum(child);

  }

  return sum;
}

const sum = ref(
    props.decompositionsFinancing.map(item => ({
      id: item.id,
      value: item.decompositionInputs.map(value =>calculateSum(value)).filter(Number.isInteger).reduce((p, c) => p + c, 0)
    }))
)


const emit = defineEmits(['onChange'])

const decompositionModel = ref(props.editValues)


const onChange = (value, decompositionModelId) => {

    const existent =  decompositionModel.value.find(item => item.inputId === value.inputId)

    if (!existent) {
        decompositionModel.value.push(value)
    } else {
        existent.inputValue = value.inputValue
    }


    emit('onChange', decompositionModel)
}

</script>
