<template>
    <EditForm
        class=""
        :title="$t('projects_update_departments')"
        :action-button-disabled="false"
        :return-route="route('projects.show', project.id)"
        @submit="onSubmit"
    >
        <div class="w-full flex flex-wrap justify-between px-2 lg:px-0 lg:mt-0">
            <ComposedListAlternative
                class="mt-2 bg-white text-black"
                v-slot="listProps"
                :label="$t('departments')"
                :list-display-name="(item) => item.department.designation"
                :button-disable="(addingValue) => !addingValue.department"
                :list-size="INPUT_SIZES.MEDIUM"
                :model-value="project.departments.map(item => ({
                    department: {
                        id: item.id,
                        designation: item.designation
                    }
                }))"
                @on-list-update="(value) => {
                        onChange(value.map((item) => item.department.id), 'departments')
                        onSearch('', 'department', form.departments)
                    }"
            >
                <Dropdown
                    :label="$t('departments_single')"
                    :model-value="listProps.toAddContent.department"
                    :list="departments"
                    :search-value="departmentQuery"
                    :invert-color="true"
                    @on-change="(value) => listProps.toAddContent.department = value"
                    @on-search="(value) => onSearch(value, 'department', form.departments)"
                />
            </ComposedListAlternative>
            <ComposedListAlternative
              class="mt-8 bg-white text-black"
              v-slot="listProps"
              :label="$t('i_and_d_structures')"
              :list-display-name="(item) => item.investigationStructure.designation"
              :button-disable="(addingValue) => !addingValue.investigationStructure"
              :list-size="INPUT_SIZES.MEDIUM"
              :model-value="project.investigationStructures.map(item =>  ({
                    investigationStructure: {
                        id: item.id,
                        designation: item.designation
                    }
                }))"
              @on-list-update="(value) => {
                        onChange(value.map((item) => item.investigationStructure.id), 'investigationStructures')
                        onSearch('', 'investigationStructure', form.investigationStructures)
                    }"
          >
            <Dropdown
                :label="$t('i_and_d_structures_single')"
                :model-value="listProps.toAddContent.investigationStructure"
                :list="investigationStructures"
                :search-value="investigationStructureQuery"
                :invert-color="true"
                @on-change="(value) => listProps.toAddContent.investigationStructure = value"
                @on-search="(value) => onSearch(value, 'investigationStructure', form.investigationStructures)"
            />
          </ComposedListAlternative>
            <ComposedListAlternative
              class="mt-8 bg-white text-black"
              v-slot="listProps"
              :label="$t('service_unit')"
              :list-display-name="(item) => item.serviceUnits.designation"
              :button-disable="(addingValue) => !addingValue.serviceUnits"
              :list-size="INPUT_SIZES.MEDIUM"
              :model-value="project.serviceUnits.map(item => ({
                    serviceUnits: {
                        id: item.id,
                        designation: item.designation
                    }
                }))"
              @on-list-update="(value) => {
                        onChange(value.map((item) => item.serviceUnits.id), 'serviceUnits')
                        onSearch('', 'serviceUnit', form.serviceUnits)
                }"
          >
            <Dropdown
                :label="$t('service_unit_single')"
                :model-value="listProps.toAddContent.serviceUnits"
                :list="serviceUnits"
                :search-value="serviceUnitQuery"
                :invert-color="true"
                @on-change="(value) => listProps.toAddContent.serviceUnits = value"
                @on-search="(value) => onSearch(value, 'serviceUnit', form.serviceUnits)"
            />
          </ComposedListAlternative>
            <ComposedListAlternative
              class="mt-8 bg-white text-black"
              v-slot="listProps"
              :label="$t('other_internal_entities_single')"
              :list-display-name="(item) => item.otherInternalEntity.designation"
              :button-disable="(addingValue) => !addingValue.otherInternalEntity"
              :list-size="INPUT_SIZES.MEDIUM"
              :model-value="project.otherEntities.map(item => ({
                    otherInternalEntity: {
                        id: item.id,
                        designation: item.designation
                    }
                }))"
              @on-list-update="(value) => {
                        onChange(value.map((item) => item.otherInternalEntity.id), 'otherInternalEntities')
                        onSearch('', 'otherInternalEntity', form.otherInternalEntities)
                    }"
          >
            <Dropdown
                :label="$t('other_internal_entities')"
                :model-value="listProps.toAddContent.otherInternalEntity"
                :list="otherInternalEntities"
                :search-value="otherInternalEntityQuery"
                :invert-color="true"
                @on-change="(value) => listProps.toAddContent.otherInternalEntity = value"
                @on-search="(value) => onSearch(value, 'otherInternalEntity', form.otherInternalEntities)"
            />
          </ComposedListAlternative>
        </div>
    </EditForm>
</template>

<script setup>
import {INPUT_SIZES} from "../../../Components/Input.vue";
import {router, useForm} from "@inertiajs/vue3";
import Dropdown from "../../../Components/Dropdown.vue";
import _ from "lodash";
import {ref} from "vue";
import EditForm from "../../../Components/Forms/EditForm.vue";
import ComposedListAlternative from "../../../Components/Projeto/ComposedList.vue";

const props = defineProps({
    project: Object,
    departments: Array,
    departmentQuery: String,
    investigationStructures: Array,
    investigationStructureQuery: String,
    serviceUnits: Array,
    serviceUnitQuery: String,
    otherInternalEntities: Array,
    otherInternalEntityQuery: String
})


const form = useForm({
    type: "ULISBON_ENTITIES",
    departments: props.project.departments.map(item => item.id) ?? [],
    investigationStructures: props.project.investigationStructures.map(item => item.id) ?? [],
    serviceUnits: props.project.serviceUnits.map(item => item.id),
    otherInternalEntities: props.project.otherEntities.map(item => item.id)
});


const queryParameters = ref({})

const onSearch =  _.throttle((value, property, used) => {

    if (queryParameters.value && value === ""){
        delete queryParameters.value[property]
    } else {
        queryParameters.value[property] = value
    }

    if (used) {
        queryParameters.value[property + "Used"] = JSON.stringify(used)
    }

    router.get(route('projects.edit.ulisboa-entities', props.project?.id), queryParameters.value, {
        preserveState: true,
    })
}, 1000)

const onChange = (value, propertyName) => {

    if (form.errors[propertyName]) {
        form.errors[propertyName] = null;
    }
    form[propertyName] = ((value !== undefined)?  value : null);
}

const onSubmit = () => {

    for (let prop in form.data()) {
        if (form[prop] instanceof Object && form[prop].id) {
            form[prop] = form[prop].id
        }
    }

    form.put(route('projects.update', props.project.id))
}




</script>
