<template>
  <Link
      class="w-64 h-56 rounded-xl px-4 py-4 bg-white drop-shadow-xl shadow-black cursor-pointer hover:bg-fculColor hover:bg-opacity-60 text-fculColor hover:text-white flex flex-col overflow-hidden"
      :href="sanitizedNavigateTo"
  >
    <p class="w-full text-lg font-bold ">{{title.length <= 24 ? title : `${title.slice(0, 18)}...`}}</p>
    <slot></slot>
  </Link>
</template>

<script setup>
import {Link} from "@inertiajs/vue3";
import {computed} from "vue";
import DOMPurify from "dompurify";

const props = defineProps({
    title: String,
    description: String,
    navigateTo: String
  })

const sanitizedNavigateTo = computed(() => {
    return DOMPurify.sanitize(props.navigateTo);
});
</script>
