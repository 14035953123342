<template>
  <EditForm
    :title="$t('user_permissions_edit')"
    :action-button-disabled="notChanged()"
    :return-route="route('settings.user-permissions.index')"
    @submit="onSubmit"
  >
    <div class="flex flex-wrap w-full gap-x-16 pl-2">
      <Input
          class=""
          :label="$t('fcul_member')"
          :model-value="userPermission.userName"
          :disabled="true"
      />
      <Dropdown

          :label="$t('roles')"
          :model-value="form.role ?? props.roles.find(role => role.id === props.userPermission.roleId)"
          :list="roles"
          :search-value="roleSearch"
          forced-value=""
          :is-required="true"
          @on-change="(value) => form.role = value?.id ?? ''"
          @on-search="(value) => onSearch(value, 'role')"
      />
    </div>
  </EditForm>


</template>

<script setup>

import EditForm from "../../../Components/Forms/EditForm.vue";
import Input from "../../../Components/Input.vue";
import {router, useForm} from "@inertiajs/vue3";
import {ref} from "vue";
import Dropdown from "../../../Components/Dropdown.vue";
import _ from "lodash";

const props = defineProps({
    userPermission: Object,
    roles: Array,
    roleSearch: String
});

const form = useForm({
  role:   undefined
})

const queryParameters = ref({})

const onSearch =  _.throttle((value, property) => {

  if (queryParameters.value && value === ""){
    delete queryParameters.value[property]
  } else {
    queryParameters.value[property] = value
  }

  router.get(route('settings.user-permissions.edit', props.userPermission.id), queryParameters.value, {
    preserveState: true,
  })
}, 1500)

const onSubmit = () => {
    form.put(route('settings.user-permissions.update', props.userPermission.id));
};

const notChanged = () => {
  return !form.role || form.role === props.userPermission.roleId
}

</script>

