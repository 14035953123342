<template>
    <div class="ml-6 w-full">
        <Input
            v-if="input.child && input.child.length === 0"
            :label="input.name"
            :input-size="INPUT_SIZES.SMALL"
            :model-value="inputValue"
            type="number"
            :is-required="true"
            @on-change="(value) => {
                inputValue = value

            }"
        />
        <div v-else class="flex" >
            <h1 class="font-bold">- {{ input.name }}* :</h1>
            <h1 class="pl-1 font-bold text-fculColor">{{sum}}</h1>
        </div>

        <RecursiveInputProject
            v-for="inputChild in input.child"
            :input="inputChild"
            :model-value="modelValue"
            @on-change="(value) =>{

                values[value.inputId] = value.inputValue
                sum = Object.values(values).reduce((accumulator, currentValue) => (accumulator === ''? 0 : Number.parseInt(accumulator)) + (currentValue === ''? 0 : Number.parseInt(currentValue)))
                emit('onChange', value)
            }"
        />
    </div>
</template>

<script setup>
import Input from "../Input.vue"
import {INPUT_SIZES} from "../../Components/Input.vue";
import {ref, watch} from "vue";

const props = defineProps({
  input: Object,
  modelValue: Array
})


const emit = defineEmits(['onChange'])


const values = ref({})



const inputValue = ref(props.modelValue? props.modelValue[0]?.find(element => element.inputId === props.input.id)?.inputValue ?? "" : "")







watch(inputValue, () => {
  emit('onChange', {
    inputId: props.input.id,
    inputValue: inputValue.value,
  })
})
const calculateSum = (input) => {
  if (!input?.child?.length) {
    return props.modelValue? props.modelValue[0]?.find(i => input.id === i.inputId)?.inputValue ?? 0 : 0;
  }

  let sum = 0;

  for (let child of input.child) {

    sum += calculateSum(child);

  }

  return sum;
}

const sum = ref(calculateSum(props.input))

</script>

