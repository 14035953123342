<template>
  <Link
      class="btn-nav"
      :class="{
        'text-white': active,
        'bg-fculColor': active,
        'hover:bg-opacity-60': !active,
        'cursor-default': active,
      }"
      :href="sanitizedNavigateTo"
  >
    <slot></slot>
    <p>{{text}}</p>
  </Link>

</template>

<script setup>
import {computed} from "vue"
import {usePage, Link} from "@inertiajs/vue3";
import DOMPurify from "dompurify"

const page = usePage()

const props = defineProps({
  text: String,
  activeRoute: String,
  navigateTo: String
});

const sanitizedNavigateTo = computed(() => {
    return DOMPurify.sanitize(props.navigateTo);
});

const active = computed(() => page.url.startsWith(props.activeRoute))
</script>
