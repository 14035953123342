<template>
  <div class="w-full  flex flex-col bg-background px-4 pt-4 pb-8 text-black rounded-lg drop-shadow-xl shadow-black">
    <h1 class="text-lg font-bold">{{ label }} {{isRequired? "*" : ""}}</h1>
    <div class="flex flex-col-reverse lg:flex-row w-full lg:px-4">
      <div>
        <slot :toAddContent="toAddContent" ></slot>
        <button
            :disabled="buttonDisable(toAddContent)"
            class="w-full mt-2.5 px-4 py-2 rounded-lg bg-fculColor text-white font-bold drop-shadow-2xl shadow-black hover:bg-opacity-60 disabled:bg-opacity-60"
            @click.prevent="onItemAdd"
        >
          {{$t('add')}}
        </button>
          <h1 class="ml-2 h-4 text-decline" v-if="error">{{$t(error)}}</h1>
      </div>
      <div class="w-full  rounded-lg lg:ml-8 mb-8 lg:mb-0 mt-2 lg:mt-0 p-4 overflow-hidden bg-fculColor bg-opacity-20 ">
        <div class=" overflow-y-scroll  scrollbar "
             :class="{
                  'h-148': listSize && listSize === INPUT_SIZES.OTHER,
                  'max-h-full': listSize && listSize === INPUT_SIZES.OTHER,
                  'h-full': listSize && listSize === INPUT_SIZES.XXLARGE,
                  'max-h-full': listSize && listSize === INPUT_SIZES.XXLARGE,
                  'h-72': listSize && listSize === INPUT_SIZES.XLARGE,
                  'max-h-72': listSize && listSize === INPUT_SIZES.XLARGE,
                  'h-64': !listSize || listSize === INPUT_SIZES.LARGE,
                  'max-h-64': !listSize || listSize === INPUT_SIZES.LARGE,
                  'h-48':  listSize && listSize === INPUT_SIZES.MEDIUM,
                  'max-h-48':  listSize && listSize === INPUT_SIZES.MEDIUM,
                  'h-40':  listSize && listSize === INPUT_SIZES.SMALL,
                  'max-h-40':  listSize && listSize === INPUT_SIZES.SMALL
               }"
        >
          <ComposedListItem v-for="item in listOfElements"
                            :title="listDisplayName(item)"
                            :not-removable="notRemovable"
                            @on-delete="onItemRemoveClick(item)"
                            @on-edit="onItemEdit(item)"
                            :hasEdit="hasEdit"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import {INPUT_SIZES} from "../Input.vue";
  import ComposedListItem from "./ComposedListItem.vue";
  import {ref} from "vue";

  const props = defineProps({
    label: String,
    isRequired: Boolean,
    listSize: INPUT_SIZES,
    error: String,
    modelValue: Array,
    listDisplayName: Function,
    buttonDisable: Function,
    notRemovable: Boolean,
    hasEdit: Boolean
  })

  const emit = defineEmits(['onListUpdate', 'onEdit'])

  const listOfElements = ref(props.modelValue ?? []);

  const toAddContent = ref({})

  const onItemAdd = () => {
    listOfElements.value.push(toAddContent.value)
    toAddContent.value = {}
    emit('onListUpdate', listOfElements.value)
  }

  const onItemRemoveClick = (itemToRemove) => {
    listOfElements.value = listOfElements.value.filter(item => JSON.stringify(item) !== JSON.stringify(itemToRemove))
    emit('onListUpdate', listOfElements.value)
  }

  const onItemEdit = (item) => {
      emit('onEdit', item, toAddContent.value)
      onItemRemoveClick(item)
  }
</script>
