

<template>
  <div
      v-if="countries.length > 0"
      class="pt-2 w-full h-full"
  >
    <Bar
        :data="activeOptions"
        :options="options"
    />
  </div>
  <div v-else class="w-full h-full flex justify-center items-center">
    <h1>{{$t('no_data_available')}}</h1>
  </div>
</template>

<script setup>

import {Bar} from "vue-chartjs";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement} from "chart.js";
import {ref} from "vue";

ChartJS.register(CategoryScale, LinearScale, BarElement)

const props = defineProps({
    countries: Object,
    yAxisName: String
})

const activeOptions = ref(
    {
      labels: props.countries.map(item => item.namePt),
      datasets: [
        {
          backgroundColor: '#2C3FB1',
          data: props.countries.map(item => item.count),
        }
      ]
    }
)

const options = {
  scales: {
    x: {
      grid: {
        display: false,
      },
      reverse: true
    },
    y: {
      display: true,
      grid: {
        display: false
      },
        min: 0,
        ticks: {
            // Forces the ticks to be integers only
            callback: function(value) {
                if (!Number.isInteger(value)){
                    return null
                }

                return (value >= 0)? value : null;
            },
            stepSize: 1, // Ensures the interval is 1 unit
        },
        title: {
            display: true,
            text: props.yAxisName,  // Title for Y-Axis
            color: '#000'
        }
    }
  },
  borderRadius: 16,
  barThickness: 50,
  plugins: {
    legend: {
      display: false
    },
  }

}

</script>
