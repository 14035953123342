import { createApp, h } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3'
import '../css/app.css'
import { ZiggyVue } from "ziggy-js";
import {i18nVue} from "laravel-vue-i18n";
import MainLayout from "./Layout/MainLayout.vue";
import mitt from "mitt";

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';


createInertiaApp({
    resolve: name => {
        const pages = import.meta.glob('./Pages/**/*.vue', { eager: true })
        const page = pages[`./Pages/${name}.vue`];


        if (name !== 'Project/ShowPublic')
            page.default.layout = MainLayout

        return page
    },
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(i18nVue, {
                resolve: (lang) => import(`../lang/${lang}.json`)
            })
            .use(ZiggyVue)
        app.component('QuillEditor', QuillEditor)
        app.config.globalProperties.emitter = mitt()
        app.config.globalProperties.window = window
        app.mount(el)
    },


})
