<template>
  <div class="w-full  flex flex-col max-h-screen overflow-hidden ">
    <div class=" w-full flex flex-col lg:flex-row pt-10 lg:pt-6  pb-2  pl-8 pr-8 bg-background items-center justify-between">
        <h1 class="font-bold text-3xl text-fculColor pb-8 lg:pb-0">{{$t('projects')}}</h1>
        <SearchBar
            class="w-full lg:w-1/2"
            :label="$t('search')"
            :model-value="projectQuery"
            @on-search="(value) => onDelayedSearch(value, 'project')"
        />
        <div v-if="isAuthenticated" class="hidden lg:flex flex-col">
          <p class="font-bold text-fculColor ">{{ user?.userName }}</p>
          <p class="text-sm text-fculColor">{{ user?.userEmail }}</p>
        </div>
      <div v-else></div>
    </div>
    <div class="pl-8 pr-8 flex justify-end">
      <div v-if="isAuthenticated && hasMyFundus(user?.role)" class="bg-white w-full p-2  rounded-lg drop-shadow-xl shadow-black overflow-x-scroll no-scrollbar">
        <p class="font-bold text-fculColor mb-1">{{$t('my_fundus')}}</p>
        <div class="flex w-full">
          <Link
              class="w-1/4 mr-2 py-2 lg:px-4 px-2 bg-fculColor cursor-pointer hover:bg-opacity-100 bg-opacity-80 rounded-lg flex justify-center items-center"
              :href="route('notifications.index')"
          >
              <FontAwesomeIcon
                  :icon="faBell"
                  class="text-white "
              />
              <p class="ml-2 lg:ml-4 text-sm font-bold text-white">{{notificationBarData.notificationsCount}}</p>
              <p class="hidden xl:block ml-2 text-sm font-bold text-white "> {{$t('notifications')}}</p>
          </Link>
          <div
              class="w-1/4 mr-2 py-2 lg:px-4 px-2 cursor-pointer hover:bg-opacity-100 bg-fculColor rounded-lg flex justify-center items-center"
              :class="{
                'bg-opacity-100': activeProjectsActive,
                'bg-opacity-80': !activeProjectsActive
              }"
              @click="filterByMyActive()"
          >
              <FontAwesomeIcon
                  :icon="faCheck"
                  class="text-white "
              />
              <p class="ml-2 lg:ml-4 text-sm font-bold text-white">{{notificationBarData.activeProjectsCount}}</p>
              <p class="hidden xl:block ml-2 text-sm font-bold text-white">{{$t('active_projects')}}</p>
          </div>
          <div
              class="w-1/4 mr-2 py-2 lg:px-4 px-2 bg-fculColor cursor-pointer hover:bg-opacity-100 rounded-lg flex items-center"
              :class="{
                'bg-opacity-100': closeEnd,
                'bg-opacity-80': !closeEnd
              }"
              @click="filterCloseToEnd()"
          >
              <FontAwesomeIcon
                  :icon="faClock"
                  class="text-white "
              />
              <p class="ml-2 lg:ml-4  text-sm font-bold text-white">{{notificationBarData.closeEnd}}</p>
              <p class="hidden xl:block ml-2 text-sm font-bold text-white">{{$t('close_finish')}}</p>
          </div>
          <div
              class="w-1/4 mr-2 py-2 lg:px-4 px-2 bg-fculColor rounded-lg cursor-pointer hover:bg-opacity-100  flex items-center"
              :class="{
                'bg-opacity-100': incomplete,
                'bg-opacity-80': !incomplete
              }"
              @click="filterByIncomplete()"
          >
              <FontAwesomeIcon
                  :icon="faListCheck"
                  class="text-white "
              />
              <p class="ml-2 lg:ml-4 text-sm font-bold text-white">{{notificationBarData.notValidatedCount}}</p>
              <p class="hidden xl:block ml-2 text-sm font-bold text-white">{{$t('incomplete')}}</p>
          </div>
          <Link
              v-if="canCreateProject(user?.role)"
              class="mr-2 px-4 xl:px-6 flex justify-center items-center bg-fculColor rounded-lg  font-bold text-xl text-white cursor-pointer hover:bg-opacity-60"
              :href="route('projects.create')"
          >+</Link>
          <button class="px-3 flex justify-center items-center bg-fculColor rounded-lg  font-bold text-xl text-white cursor-pointer hover:bg-opacity-60"
                  @click="filtersOpened = !filtersOpened"
                  :aria-label="$t('filters')"
          >
              <FontAwesomeIcon
                  :icon="faFilter"
                  size="2xs"
              />
          </button>
        </div>
      </div>
      <button v-else class="px-6 py-3 mb-4 flex justify-center items-center bg-fculColor rounded-lg  font-bold text-xl text-white cursor-pointer hover:bg-opacity-60"
              @click="filtersOpened = !filtersOpened"
              :aria-label="$t('filters')"
      >
        <FontAwesomeIcon
            :icon="faFilter"
            size="2xs"
        />
      </button>
    </div>

    <div class="flex h-full max-h-full overflow-hidden px-6 ">
        <div class="flex flex-col w-full">
            <div class="w-full h-full max-h-full pb-8   pt-6 flex flex-wrap  items-start justify-center  gap-x-6 gap-y-4 overflow-y-scroll scrollbar">
                <SquareListContainerItem
                    class=""
                    v-for="project in projects?.data"
                    :key="project.id"
                    :title="activeLanguage === 'pt'? project.titlePt ?? project.titleEn : project.titleEn ?? project.titlePt"
                    :navigate-to="route('projects.show', project.id)"
                >
                    <div class="px-2 w-full max-h-full overflow-hidden">
                        <div
                            class="flex"
                        >
                            <p class="text-black font-bold">{{$t('acronym')}}:</p>
                            <p class="text-black ml-1 break-words">{{project.acronym}}</p>
                        </div>
                        <div
                            class="flex"
                        >
                            <p class="text-black font-bold">{{$t('state')}}:</p>
                            <p class="text-black ml-1 break-words">{{$t(project.state)}}</p>
                        </div>
                        <div class="flex">
                            <p class="text-black font-bold">{{$t('date_start')}}:</p>
                            <p class="text-black ml-1">{{project.dateStart}}</p>
                        </div>
                        <div class="flex">
                            <p class="text-black font-bold">{{$t('date_end')}}:</p>
                            <p class="text-black ml-1">{{project.dateEnd}}</p>
                        </div>
                        <div
                            v-if="project.typology"
                            class="flex w-full h-fit overflow-x-hidden"
                        >
                            <p class="text-black font-bold">{{$t('typologies_single')}}:</p>
                            <p class="text-black ml-1 whitespace-nowrap">{{project.typology}}</p>
                        </div>
                        <div class="flex w-full h-fit overflow-x-hidden">
                            <p class="text-black font-bold">{{$t('scope_single')}}:</p>
                            <p class="text-black ml-1 whitespace-nowrap">{{project.scope}}</p>
                        </div>
                    </div>
                </SquareListContainerItem>

            </div>
            <div class="pt-1">
                <HorizontalPagination class="" :links="nextPageLinks"/>
            </div>
        </div>
        <div v-if="filtersOpened" class="absolute  sm:left-auto transform mt-2 -translate-x-1/2 sm:translate-x-0 h-96 sm:h-auto sm:relative w-full sm:w-full flex flex-col overflow-hidden bg-white  sm:ml-2 sm:mt-6 sm:mb-8 pt-4 rounded-lg drop-shadow-2xl shadow-fculColor">
            <div class="flex justify-between pb-2">
                <p class="text-lg font-bold pl-4">
                    {{$t('filters')}}
                </p>
                <div class="flex">
                    <button
                        v-if="canGenerateExport(user?.role)"
                        class=" px-3 mr-2 flex justify-center items-center bg-fculColor rounded-lg  font-bold text-sm text-white cursor-pointer hover:bg-opacity-60"
                        @click="() => downloadExcel()"
                    >
                      <FontAwesomeIcon
                          :icon="faDownload"
                          class="text-white "
                      />
                    </button>
                    <button
                        class="px-3 mr-4 flex justify-center items-center bg-decline rounded-lg  font-bold text-sm text-white cursor-pointer hover:bg-opacity-60"
                        @click="() => {
                            clearFilters()
                        }"
                    >
                        {{$t('clear')}}
                    </button>
                    <button
                        class="sm:hidden px-3 mr-4 flex justify-center items-center bg-decline rounded-lg  font-bold text-sm text-white cursor-pointer hover:bg-opacity-60"
                        @click="() => {
                            filtersOpened = false
                        }"
                    >
                        X
                    </button>
                </div>
            </div>
            <div class="h-full max-h-full overflow-y-scroll scrollbar pr-1 pl-3 pb-3 ">
                <div>
                    <button
                        class="w-full text-white font-bold px-2 h-10 rounded-lg bg-fculColor  flex items-center cursor-pointer hover:bg-opacity-100 overflow-hidden"
                        :class="{
                            'bg-opacity-100': activeProjects,
                            'bg-opacity-60 ': !activeProjects
                        }"
                        @click="() => filterFullActive()"
                    >
                        {{$t('active_projects')}}
                    </button>
                </div>
                <div>
                    <label class="mb-1 mt-2 pl-2 font-bold">{{$t('period_start_date')}}</label>
                    <input
                        class="border-0 focus:border-2 px-4 my-1.5 py-2 rounded-lg drop-shadow-xl bg-fculColor bg-opacity-60 shadow-black text-white w-full"
                        v-model="startDate"
                        @input="() => {
                            if(startDate === '') {
                                onDelayedSearch('', 'startDate')
                            }

                            const date  = new Date(startDate);
                            if (date.getFullYear() > 1000) {
                                onDelayedSearch(startDate, 'startDate')
                            }
                        }"
                        type="date"
                    >
                </div>
                <div>
                    <label class="mb-1 mt-2 pl-2 font-bold ">{{$t('period_end_date')}}</label>
                    <input
                        class=" px-4 my-1.5 py-2 rounded-lg drop-shadow-xl bg-fculColor bg-opacity-60 shadow-black text-white w-full"
                        v-model="endDate"
                        @input="() => {
                            if(endDate === '') {
                                onDelayedSearch('', 'endDate')
                            }

                            const date  = new Date(endDate);
                            if (date.getFullYear() > 1000) {
                                onDelayedSearch(endDate, 'endDate')
                            }
                        }"
                        type="date"
                    >
                </div>
                <div>
                    <label class="mb-1 mt-2 pl-2 font-bold ">{{$t('financing_value_total')}}</label>
                    <div class="flex">
                        <input
                            class=" px-4 my-1.5 py-2 w-1/2 rounded-lg drop-shadow-xl bg-fculColor bg-opacity-60 shadow-black text-white mr-1"
                            v-model="minFinanced"
                            @input="() => {
                                onDelayedSearch(minFinanced, 'minFinanced')
                            }"
                            placeholder="min"
                            type="number"
                        >
                        <input
                            class=" px-4 my-1.5 py-2 w-1/2 rounded-lg drop-shadow-xl bg-fculColor bg-opacity-60 shadow-black text-white ml-1"
                            v-model="maxFinanced"
                            @input="() => {
                                onDelayedSearch(maxFinanced, 'maxFinanced')
                            }"
                            placeholder="max"
                            type="number"
                        >
                    </div>
                </div>
                <div>
                    <label class="mb-1 mt-2 pl-2 font-bold ">{{$t('budget_value_total')}}</label>
                    <div class="flex">
                        <input
                            class=" px-4 my-1.5 py-2 w-1/2 rounded-lg drop-shadow-xl bg-fculColor bg-opacity-60 shadow-black text-white mr-1"
                            v-model="minBudget"
                            @input="() => {
                                onDelayedSearch(minBudget, 'minBudget')
                            }"
                            placeholder="min"
                            type="number"
                        >
                        <input
                            class=" px-4 my-1.5 py-2 w-1/2 rounded-lg drop-shadow-xl bg-fculColor bg-opacity-60 shadow-black text-white ml-1"
                            v-model="maxBudget"
                            @input="() => {
                                onDelayedSearch(maxBudget, 'maxBudget')
                            }"
                            placeholder="max"
                            type="number"
                        >
                    </div>
                </div>
                <MultiListFilter
                    :title="$t('typologies')"
                    :model-value-search="typologySearch"
                    :list="typologies"
                    :selected-on-list="selectedTypologies"
                    @on-delay-search="(value) => onDelayedSearch(value, 'typology')"
                    @on-search="(value) => onSearch(value, 'typologies')"
                />
                <MultiListFilter
                    :title="$t('scope')"
                    :model-value-search="scopeSearch"
                    :list="scopes"
                    :selected-on-list="selectedScopes"
                    @on-delay-search="(value) => onDelayedSearch(value, 'scope')"
                    @on-search="(value) => onSearch(value, 'scopes')"
                />
                <MultiListFilter
                    :title="$t('financing_origin')"
                    :model-value-search="financingOriginSearch"
                    :list="financingOrigins"
                    :selected-on-list="selectedFinancingOrigins"
                    @on-delay-search="(value) => onDelayedSearch(value, 'financingOrigin')"
                    @on-search="(value) => onSearch(value, 'financingOrigins')"
                />
                <MultiListFilter
                    :title="$t('departments')"
                    :model-value-search="departmentSearch"
                    :list="departments"
                    :selected-on-list="selectedDepartments"
                    @on-delay-search="(value) => onDelayedSearch(value, 'department')"
                    @on-search="(value) => onSearch(value, 'departments')"
                />
                <MultiListFilter
                    :title="$t('i_and_d_structures')"
                    :model-value-search="investigationStructureSearch"
                    :list="investigationStructures"
                    :selected-on-list="selectedInvestigationStructures"
                    @on-delay-search="(value) => onDelayedSearch(value, 'investigationStructure')"
                    @on-search="(value) => onSearch(value, 'investigationStructures')"
                />
                <MultiListFilter
                    :title="$t('scientific_areas')"
                    :model-value-search="scientificAreaSearch"
                    :list="scientificAreas"
                    :selected-on-list="selectedScientificArea"
                    @on-delay-search="(value) => onDelayedSearch(value, 'scientificArea')"
                    @on-search="(value) => onSearch(value, 'scientificAreas')"
                />
                <MultiListFilter
                    :title="$t('keywords')"
                    :model-value-search="keywordSearch"
                    :list="keywords"
                    :selected-on-list="selectedKeywords"
                    @on-delay-search="(value) => onDelayedSearch(value, 'keyword')"
                    @on-search="(value) => onSearch(value, 'keywords')"
                />
                <MultiListFilter
                    :title="$t('social_economical_categories')"
                    :model-value-search="socialEconomyCategorySearch"
                    :list="socialEconomyCategories"
                    :selected-on-list="selectedSocialEconomyCategories"
                    @on-delay-search="(value) => onDelayedSearch(value, 'socialEconomyCategory')"
                    @on-search="(value) => onSearch(value, 'socialEconomyCategories')"
                />
                <MultiListFilter
                    :title="$t('ods')"
                    :model-value-search="odsSearch"
                    :list="odss"
                    :selected-on-list="selectedOdss"
                    @on-delay-search="(value) => onDelayedSearch(value, 'ods')"
                    @on-search="(value) => onSearch(value, 'odss')"
                />
                <MultiListFilter
                    :title="$t('financing_programs')"
                    :model-value-search="financingProgramSearch"
                    :list="financingPrograms"
                    :selected-on-list="selectedFinancingProgram"
                    @on-delay-search="(value) => onDelayedSearch(value, 'financingProgram')"
                    @on-search="(value) => onSearch(value, 'financingPrograms')"
                />
                <MultiListFilterEntities
                    :title="$t('financing_entities')"
                    :model-value-search="financingEntitySearch"
                    :list="financingEntities"
                    :selected-on-list="selectedFinancingEntity"
                    @on-delay-search="(value) => onDelayedSearch(value, 'financingEntity')"
                    @on-search="(value) => onSearch(value, 'financingEntities')"
                />
                <MultiListFilter
                    :title="$t('participant_entities')"
                    :model-value-search="participantEntitySearch"
                    :list="participantEntities"
                    :selected-on-list="selectedParticipantEntities"
                    @on-delay-search="(value) => onDelayedSearch(value, 'participantEntity')"
                    @on-search="(value) => onSearch(value, 'participantEntities')"
                />
                <MultiListFilterFculMember
                    :title="$t('participation')"
                    :model-value-search="fculMemberSearch"
                    :listPersons="fculMembers"
                    :model-selected-person="selectedFculMember"
                    :model-selected-role="selectedParticipationRole"
                    :list-participation-roles="participationRoles"
                    @on-delay-search="(value) => onDelayedSearch(value.value, value.property)"
                    @on-search="(value) => onSearch(value.value, value.property)"
                />
            </div>
        </div>
    </div>
  </div>
</template>

<script setup>

import SquareListContainerItem from "../../Components/SquareListContainerItem.vue";
import {getActiveLanguage} from "laravel-vue-i18n";
import {Link, router} from "@inertiajs/vue3";
import SearchBar from "../../Components/SearchBar.vue";
import _ from "lodash";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {faBell, faCheck, faClock, faDownload, faFilter, faListCheck} from "@fortawesome/free-solid-svg-icons";
import {computed, ref} from "vue";
import MultiListFilter from "../../Components/Projeto/Filters/MultiListFilter.vue";
import MultiListFilterEntities from "../../Components/Projeto/Filters/MultiListFilterEntities.vue";
import MultiListFilterFculMember from "../../Components/Projeto/Filters/MultiListFilterFculMember.vue";
import {useUser} from "../../Components/hooks/useUser.js";
import {canCreateProject, canGenerateExport, hasMyFundus} from "../../Authorizations.js";
import HorizontalPagination from "../../Components/HorizontalList/HorizontalPagination.vue";
import base64 from "base-64";

    const { isAuthenticated, user } = useUser()


    const props = defineProps({
        projects: Object,
        projectQuery: String,
        startDate: String,
        endDate: String,
        minFinanced: Number,
        maxFinanced: Number,
        minBudget: Number,
        maxBudget: Number,
        typologies: Array,
        typologySearch: String,
        selectedTypologies: Array,
        scopes: Array,
        scopeSearch: String,
        selectedScopes: Array,
        financingOrigins:  Array,
        financingOriginSearch: String,
        selectedFinancingOrigins: Array,
        departments: Array,
        departmentSearch: String,
        selectedDepartments: Array,
        investigationStructures: Array,
        investigationStructureSearch: String,
        selectedInvestigationStructures: Array,
        scientificAreas: Array,
        scientificAreaSearch: String,
        selectedScientificArea: Array,
        keywords: Array,
        keywordSearch: String,
        selectedKeywords: Array,
        socialEconomyCategories: Array,
        socialEconomyCategorySearch: String,
        selectedSocialEconomyCategories: Array,
        odss: Array,
        odsSearch: String,
        selectedOdss: Array,
        financingPrograms: Array,
        financingProgramSearch: String,
        selectedFinancingProgram: Array,
        financingEntities: Array,
        financingEntitySearch: String,
        selectedFinancingEntity: Array,
        participantEntities: Array,
        participantEntitySearch: String,
        selectedParticipantEntities: Array,
        fculMembers: Array,
        fculMemberSearch: String,
        selectedFculMember: String,
        participationRoles: Array,
        participationRoleSearch: String,
        selectedParticipationRole: String,
        notificationBarData: Object,
        closeEnd: Boolean,
        incomplete: Boolean
    })



    const activeLanguage = getActiveLanguage();

    const queryParameters = ref({
        page: props?.projects?.current_page,
        startDate: props.startDate,
        endDate: props.endDate,
        typologies: JSON.stringify(props.selectedTypologies),
        closeEnd: props.closeEnd,
        incomplete: props.incomplete,
    })

    const startDate = ref(props.startDate ?? '')
    const endDate = ref(props.endDate ?? '')
    const minFinanced = ref(props.minFinanced)
    const maxFinanced = ref(props.maxFinanced)
    const minBudget= ref(props.minBudget)
    const maxBudget = ref(props.maxBudget)

    const onDelayedSearch =  _.throttle((value, property) => {



        if (queryParameters.value && value === ""){
            delete queryParameters.value[property]
        } else {
            queryParameters.value[property] = value
        }

        for (const key of Object.keys(queryParameters.value)) {

            if (!queryParameters.value[key]
                || queryParameters.value[key] === null
                ||queryParameters.value[key] === ""
                || queryParameters.value[key] === "[]"
            ) {
                delete queryParameters.value[key]
            }
        }


        router.get(route('projects.index'), queryParameters.value, {
            preserveState: true
        })
    }, 1000)

    const getQueryParams = () => {
      let params = ''

       Object.keys(queryParameters.value).forEach(key => {
        if (key !== undefined
            && queryParameters.value[key] !== ''
            && queryParameters.value[key] !== '[]'
            && queryParameters.value[key] !== null
            && key !== 'page'
        ) {
          params = params.concat(`${key}=${queryParameters.value[key]}&`)
        }
      })

      return params.substring(0, params.length - 1)

    }

    const nextPageLinks = computed(() => {
      props.projects.links.forEach(link => {
        if (link.url) {

          link.url = link.url + '&' + getQueryParams()

        }
      })
      return props.projects.links
    })


    const onSearch = (value, property) => {



        if (queryParameters.value && value === ""){
            delete queryParameters.value[property]
        } else {
            queryParameters.value[property] = value
        }

        for (const key of Object.keys(queryParameters.value)) {

            if (!queryParameters.value[key]
                || queryParameters.value[key] === null
                ||queryParameters.value[key] === ""
                || queryParameters.value[key] === "[]"
            ) {
                delete queryParameters.value[key]
            }
        }


        router.get(route('projects.index'), queryParameters.value, {
            preserveState: true,
        })
    }


    const clearFilters = () => {
        queryParameters.value = {}
        onSearch('', '')
        startDate.value = ''
        endDate.value = ''
        minFinanced.value = undefined
        maxFinanced.value = undefined
        minBudget.value = undefined
        maxBudget.value = undefined
    }

    const filtersOpened = ref(window.location.search !== '' && !window.location.search.includes('page='))


  const downloadExcel = () => {
      window.location.href = 'projects/generate-file' + window.location.search
  }

  const activeProjectsActive = ref(false)

  const filterByMyActive = () => {

        if (queryParameters.value.selectedFculMember) {
            delete queryParameters.value.selectedFculMember
            delete queryParameters.value.startDate
            delete queryParameters.value.endDate
            activeProjectsActive.value = false
        } else {
            queryParameters.value = {
                selectedFculMember: base64.encode(user.value.userId),
                startDate: new Date(),
                endDate: new Date(),
            }
            activeProjectsActive.value = true
        }

      router.get(route('projects.index'), queryParameters.value, {
          preserveState: true,
      })
  }

  const filterCloseToEnd = () => {
      if (queryParameters.value.closeEnd) {
          delete queryParameters.value.closeEnd
      } else {
          queryParameters.value = {
              closeEnd: true
          }
      }

      router.get(route('projects.index'), queryParameters.value, {
          preserveState: true,
      })
  }

    const filterByIncomplete = () => {
        if (queryParameters.value.incomplete) {
            delete queryParameters.value.incomplete
            delete queryParameters.value.selectedFculMember
        } else {
            queryParameters.value = {
                incomplete: true,
                selectedFculMember: base64.encode(user.value.userId)
            }
        }

        router.get(route('projects.index'), queryParameters.value, {
            preserveState: true,
        })
    }

    const activeProjects = ref(false)

    const filterFullActive = () => {
        if (queryParameters.value.endDate && queryParameters.value.startDate) {
            delete queryParameters.value.endDate
            delete queryParameters.value.startDate
            activeProjects.value = false
        } else if (!queryParameters.value.endDate && !queryParameters.value.startDate) {
            queryParameters.value = {
                startDate: new Date(),
                endDate: new Date(),
            }
            activeProjects.value = true
        }

        router.get(route('projects.index'), queryParameters.value, {
            preserveState: true,
        })
    }
</script>

