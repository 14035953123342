<template>
  <EditForm
    :title="$t('fcul_decomposition_models_edit')"
    :action-button-disabled="wasChanged === form.designation && dataInputsWasChanged"
    :return-route="route('settings.fcul-decomposition-models.index')"
    @submit="onSubmit"
  >
    <div class="flex flex-col w-full px-2">
        <Input
          :label="$t('fcul_decomposition_models_single')"
          :error="form.errors.designation"
          :model-value="form.designation"
          @on-change="onChange"
        />
        <label class="font-bold pl-2 mb-4">Decomposição</label>
        <RecursiveInput
        v-for="input in form.decompositionInputs"
        :input="input"
        />
    </div>
  </EditForm>
</template>

<script setup>

import EditForm from "../../../Components/Forms/EditForm.vue";
import Input from "../../../Components/Input.vue";
import { useForm} from "@inertiajs/vue3";
import {ref, watch} from "vue";
import RecursiveInput from "../../../Components/Forms/RecursiveInput.vue";

const props = defineProps({
    decompositionModel: Object
});

const wasChanged = ref(props.decompositionModel.designation)
const prevInputs = ref(props.decompositionModel.decompositionInputs)
const dataInputsWasChanged = ref(true)

const form = useForm({
  designation: props.decompositionModel.designation,
    decompositionInputs: props.decompositionModel.decompositionInputs
});

const onChange = (value) => {
  if (form.errors.designation) {
      form.errors.designation = null;
  }
  form.designation = value;
};

const onSubmit = () => {
    form.put(route('settings.fcul-decomposition-models.update', props.decompositionModel.id));
};

watch(form, ()=> {
    dataInputsWasChanged.value = JSON.stringify(prevInputs.value)===JSON.stringify(form.decompositionInputs);
})


</script>

