<template>
    <div class="w-full flex flex-col h-screen max-h-screen overflow-hidden bg-background ">
        <div class="w-full pt-8 lg:pt-8  pb-8 pl-8 pr-8 bg-background flex flex-col lg:flex-row  items-center justify-between">
            <div class="flex items-center">
                <Link
                    class="cursor-pointer"
                    :href="sanitizedText(route('projects.index'))"
                >
                    <FontAwesomeIcon :icon="faArrowLeft" class="mr-6 text-fculColor hover:text-opacity-60" size="lg"/>
                </Link>
                <a class="font-bold text-3xl text-fculColor text-justify" href="#main">{{languagePt? (project.titlePt ?? project.titleEn)
                    : (project.titleEn ?? project.titlePt)}}</a>
            </div>
            <div class="flex flex-col ml-8 lg:w-1/3 w-full lg:mt-0 mt-8">
                <div class="w-full max-w-full flex flex-wrap lg:justify-end justify-center gap-4 gap-x-9">
                    <img v-for="logo in project.logos" :alt="(!logo.designation || logo.designation === '')? 'logo' : logo.designation" class="h-8" :src="base64.decode(logo.logo)">
                </div>
            </div>
        </div>
        <div class="lg:pl-8 pl-2 lg:pr-6 pr-1 h-full overflow-y-scroll bg-background pt-4 scrollbar">
            <div class="pt-4 lg:pt-8 pb-4 lg:pb-10 lg:px-10 px-4 mb-2 bg-white rounded-lg drop-shadow-xl shadow-black">
                <div class="flex flex-wrap w-4/5 gap-4 gap-x-9 pl-8 pt-4 justify-between">
                    <InfoItem
                        :label="$t('Id FUNDUS')"
                        :value="`${project.id}`"
                    />
                    <InfoItem
                        :label="$t('acronym')"
                        :value="project.acronym"
                    />
                    <InfoItem
                        :label="$t('date_start')"
                        :value="project.dateStart"
                    />
                    <InfoItem
                        :label="$t('date_end')"
                        :value="project.dateEnd"
                    />
                    <InfoItem
                        :label="$t('state')"
                        :value="$t(project.state)"
                    />
                    <InfoItem
                        :label="$t('typologies_single')"
                        :value="project.typology"
                    />
                    <InfoItem
                        :label="$t('scope_single')"
                        :value="project.scope"
                    />
                    <InfoItem
                        v-if="project.financingOrigin"
                        :label="$t('financing_origin_single')"
                        :value="project.financingOrigin"
                    />
                    <InfoItem
                        v-if="project.doi"
                        :label="$t('doi')"
                        :value="project.doi"
                    />
                    <InfoItem
                        v-if="project.managementCoreFcul"
                        :label="$t('management_core_ciências')"
                        :value="project.managementCoreFcul"
                    />
                    <InfoItem
                        v-if="project.managementCoreFCiencias"
                        :label="$t('management_core_fciencias')"
                        :value="project.managementCoreFCiencias"
                    />
                </div>
            </div>
            <Separator
                id="main"
                v-if="scientificSection"
                class="mb-8" :separator-name="$t('scientific_information')"
            >
                <div
                    class="w-full pt-4 lg:pt-8 pb-4 lg:px-10 px-4  bg-white rounded-lg drop-shadow-xl shadow-black"
                    v-if="scientificSection"
                >
                    <div class="mb-6" v-if="languagePt && project.simplifiedDescriptionPt && project.simplifiedDescriptionPt !== '' ">
                        <p class="font-bold text-fculColor">{{$t('simplified_description')}}</p>
                        <p class="p-6 mt-2 rounded-lg text-white bg-fculColor bg-opacity-80">{{project.simplifiedDescriptionPt}}</p>
                    </div>
                    <div class="mb-6" v-else-if="project.simplifiedDescriptionEn && project.simplifiedDescriptionEn !== ''">
                        <p class="font-bold text-fculColor">{{$t('simplified_description')}}</p>
                        <p class="p-6 mt-2 rounded-lg text-white bg-fculColor bg-opacity-80">{{project.simplifiedDescriptionEn}}</p>
                    </div>
                    <div
                        class="mb-6"
                    >
                        <div class="flex mb-2 lg:mt-0 mt-4 flex-col lg:flex-row">
                            <p class="w-full lg:w-1/2  font-bold text-fculColor">{{$t('keywords_single')}}</p>
                            <p class="w-full lg:w-1/2  font-bold text-fculColor lg:text-end">{{$t('keywords_single_other_idiom')}}</p>
                        </div>
                        <ProjectPropertyContainer class="text-black">
                            <ProjectKeyword
                                v-for="keyword in project.keywords"
                                :keyword="keyword"
                            />

                        </ProjectPropertyContainer>
                    </div>
                    <div class="mb-6" v-if="languagePt && project.scientificDescriptionPt && project.scientificDescriptionPt !== ''">
                        <p class="font-bold text-fculColor">{{$t('scientific_description')}}</p>
                        <p class="p-6 mt-2 rounded-lg text-white bg-fculColor bg-opacity-80">{{project.scientificDescriptionPt}}</p>
                    </div>
                    <div class="mb-8" v-else-if="project.scientificDescriptionEn && project.scientificDescriptionEn !== ''">
                        <p class="font-bold text-fculColor">{{$t('scientific_description')}}</p>
                        <p class="p-6 mt-2 rounded-lg text-white bg-fculColor bg-opacity-80">{{project.scientificDescriptionEn}}</p>
                    </div>
                    <div
                        class="flex justify-between w-full lg:w-1/3 mb-4 flex-col lg:flex-row"
                        v-if="(project.startTRL || project.targetTRL) && (project.startTRL !== '' || project.targetTRL !== '')"
                    >
                        <div class="flex mb-4 lg:mb-0" v-if="project.startTRL && project.startTRL !== ''">
                            <p class="font-bold text-fculColor">{{$t('start_trl')}}:</p>
                            <p class="ml-2 whitespace-nowrap text-black">{{project.startTRL}}</p>
                        </div>
                        <div class="flex" v-if="project.targetTRL && project.targetTRL !== ''">
                            <p class="font-bold text-fculColor">{{$t('target_trl')}}:</p>
                            <p class="ml-2 whitespace-nowrap text-black">{{project.targetTRL}}</p>
                        </div>
                    </div>

                    <ProjectPropertyContainer
                        v-if="project.scientificAreas && project.scientificAreas.length > 0"
                        :property-name="$t('scientific_areas')"
                        class="mb-6"
                    >
                        <ProjectProperty
                            v-for="scientificArea in project.scientificAreas"
                            :value="scientificArea.designation"
                        />
                    </ProjectPropertyContainer>

                    <ProjectPropertyContainer
                        v-if="project.socialEconomyCategories && project.socialEconomyCategories.length > 0"
                        :property-name="$t('social_economical_categories')"
                        class="mb-6"
                    >
                        <ProjectProperty
                            v-for="socialEconomyCategory in project.socialEconomyCategories"
                            :value="socialEconomyCategory.designation"
                        />
                    </ProjectPropertyContainer>

                    <ProjectPropertyContainer
                        v-if="project.sustainableDevelopmentGoals && project.sustainableDevelopmentGoals.length > 0"
                        :property-name="$t('sustainable_development_goals')"
                        class="mb-6"
                    >
                        <ProjectProperty
                            v-for="sustainableGoal in project.sustainableDevelopmentGoals"
                            :value="sustainableGoal.designation"
                        />
                    </ProjectPropertyContainer>

                    <ProjectTextBlock
                        v-if="languagePt && project.impactPt && project.impactPt !== ''"
                        :title="$t('impact')"
                        :text="project.impactPt"
                    />

                    <ProjectTextBlock
                        v-else-if="project.impactEn && project.impactEn !== ''"
                        :title="$t('impact')"
                        :text="project.impactEn"
                    />

                    <ProjectTextBlock
                        v-if="languagePt && project.ethicPt && project.ethicPt !== ''"
                        :title="$t('ethic')"
                        :text="project.ethicPt"
                    />

                    <ProjectTextBlock
                        v-else-if="project.ethicEn && project.ethicEn !== ''"
                        :title="$t('ethic')"
                        :text="project.ethicEn"
                    />

                    <ProjectTextBlock
                        v-if="languagePt && project.personalDataManagementPlanPt && project.personalDataManagementPlanPt !== ''"
                        :title="$t('personal_data_management_plan')"
                        :text="project.personalDataManagementPlanPt"
                    />

                    <ProjectTextBlock
                        v-else-if="project.personalDataManagementPlanEn && project.personalDataManagementPlanEn !== ''"
                        :title="$t('personal_data_management_plan')"
                        :text="project.personalDataManagementPlanEn"
                    />
                    <ProjectPropertyContainer
                        v-if="project.helpfulHyperlinks && project.helpfulHyperlinks.length > 0"
                        :property-name="$t('helpful_hyperlinks')"
                        class="mb-6"
                    >
                        <ProjectProperty
                            v-for="helpfulHyperlink in project.helpfulHyperlinks"
                            :value="sanitizedText(`${helpfulHyperlink.hyperlinkDesignation} - <a href='${helpfulHyperlink.helpfulHyperlink.includes('https://')? helpfulHyperlink.helpfulHyperlink : 'https://' + helpfulHyperlink.helpfulHyperlink}' >${helpfulHyperlink.helpfulHyperlink}</a>`)"
                        />
                    </ProjectPropertyContainer>

                    <ProjectPropertyContainer
                        v-if="project.rewards && project.rewards.length > 0"
                        :property-name="$t('rewards')"
                        class="mb-6"
                    >
                        <ProjectProperty
                            v-for="reward in project.rewards"
                            :value="sanitizedText(`${reward.rewardDesignation} - <a href='${reward.rewardHyperlink.includes('https://')? reward.rewardHyperlink : 'https://' + reward.rewardHyperlink}' >${reward.rewardHyperlink}</a>`)"
                        />
                    </ProjectPropertyContainer>
                </div>
            </Separator>

            <Separator
                class="mb-8 w-full" :separator-name="$t('participants')"
            >
                <ProjectSection
                    class="mb-4"
                    v-if="project.participantEntities && project.participantEntities.length > 0"
                    v-for="entity in project.participantEntities"
                    :section-title="entity.participantEntity"
                >

                    <ProjectContainerProperty

                    >
                        <div class="flex flex-wrap w-full gap-y-2 gap-x-12 justify-between my-2 px-4 lg:px-8">
                            <div class="flex lg:items-center flex-col lg:flex-row" v-if="entity?.participantEntityFinancedValue && entity?.participantEntityFinancedValue !== ''">
                                <p>{{$t('participant_entity_financed_value')}}: </p>
                                <p class="pl-2 font-medium" >{{formater(entity.participantEntityFinancedValue)}} €</p>
                            </div>
                            <div class="flex lg:items-center flex-col lg:flex-row" v-if="entity?.participantEntityBudgetValue && entity?.participantEntityBudgetValue !== ''">
                                <p>{{$t('participant_entity_budget_value')}}: </p>
                                <p class="pl-2 font-medium" >{{formater(entity.participantEntityBudgetValue)}} €</p>
                            </div>
                            <div class="flex max-w-lg flex-col lg:flex-row" >
                                <p>{{$t('participation_role_single_entities')}}: </p>
                                <p class="pl-2 font-medium" >{{entity.participationEntityRole}}</p>
                            </div>
                            <div class="flex max-w-lg flex-col lg:flex-row" v-if="entity?.participationResponsibleId?.designation && entity?.participationResponsibleId?.designation !== ' '">
                                <p>{{$t('participant_entities_responsible')}}: </p>
                                <p class="pl-2 font-medium" >{{entity?.participationResponsibleId?.designation ?? ""}}</p>
                            </div>
                            <div class="flex lg:items-center flex-col lg:flex-row" v-if="entity.participationEntityOverheads && entity.participationEntityOverheads !=0">
                                <p>{{$t('participant_entity_participation_overheads')}}: </p>
                                <p class="pl-2 font-medium" >{{entity.participationEntityOverheads}} €</p>
                            </div>
                        </div>

                        <ProjectPropertyContainer
                            class="mt-4"
                            v-if="
                                project.participantEntities && project.participantEntities.length > 0 && (
                                 project.participantEntities?.find(x => x.participantEntityId == fculEntity.id) && fculEntity.id == entity.participantEntityId
                                || project.participantEntities?.find(x => x.participantEntityId == fcienciasEntity.id) && project.participantEntities?.every(x => x.participantEntityId != fculEntity.id) && fcienciasEntity.id == entity.participantEntityId
                                || project.participantEntities?.find(x => x.participantEntityId === 3) && project.participantEntities?.find.every(x => x.participantEntityId !== fcienciasEntity.id && x.participantEntityId !== fculEntity.id) && 3 == entity.participantEntityId
                                )
                            "
                            :size="INPUT_SIZES.LARGE"
                        >
                            <ProjectContainerProperty
                                v-for="person in project.participantPersons"
                            >
                                <div class="flex w-full justify-between mb-2 flex-col lg:flex-row">
                                    <div class="flex max-w-lg flex-col lg:flex-row">
                                        <p class="font-bold" >{{person.participantName}}</p>
                                    </div>
                                    <div class="flex lg:items-center flex-col lg:flex-row">
                                        <p>{{$t('participation_type')}}: </p>
                                        <p class="pl-2 font-medium" >{{person.participationType}}</p>
                                    </div>
                                </div>
                                <div class="flex w-full justify-between flex-col lg:flex-row">
                                    <div class="flex flex-col lg:flex-row">
                                        <div class="flex max-w-lg flex-row">
                                            <p>{{$t('from')}}: </p>
                                            <p class="pl-2 font-medium" >{{person.dateStart}}</p>
                                            <p class="pl-2">{{$t('to')}}</p>
                                            <p class="pl-2 font-medium" >{{person.dateEnd}}</p>
                                        </div>
                                    </div>
                                </div>
                            </ProjectContainerProperty>
                        </ProjectPropertyContainer>
                    </ProjectContainerProperty>
                </ProjectSection>
            </Separator>

            <Separator
                class="mb-8 w-full" :separator-name="$t('financing')"
            >
                <ProjectSection>
                    <div>
                        <div>
                            <div class="flex flex-col lg:flex-row w-full text-black">
                                <InfoItem
                                    class="mt-4 overflow-x-scroll"
                                    :label="$t('budget_value_total')"
                                    :value="`${formater(project.totalBudgetValue)} €`"
                                />
                                <InfoItem
                                    class="mt-4 lg:ml-6"
                                    :label="$t('financing_value_total')"
                                    :value="`${formater(project.totalFinancingValue)} €`"
                                />
                            </div>
                            <ProjectPropertyContainer
                                v-if="project.financingEntities && project.financingEntities.length > 0"
                                :property-name="$t('financing_entities')"
                                class="mb-6"
                            >
                                <ProjectProperty
                                    v-for="entity in project.financingEntities"
                                    :value="entity.designation"
                                    :second-value="entity.financingEntityPercentage? `${entity.financingEntityPercentage}%` : ''"
                                    :clickable="true"
                                    @on-click="() => {
                                if(entity.type === 'projeto') {
                                    router.visit(route('projects.show', entity.entityId), {
                                        preserveScroll: true
                                    });
                                } else {
                                    windows.location.href = sanitizedText(gremius + entity.entityId)
                                }
                            }"
                                />

                            </ProjectPropertyContainer>
                            <ProjectPropertyContainer
                                v-if="project.financingPrograms && project.financingPrograms.length > 0"
                                :property-name="$t('financing_programs')"
                                class="mb-4"
                            >
                                <ProjectProperty
                                    v-for="financingProgram in project.financingPrograms"
                                    :value="financingProgram.designation"
                                />

                            </ProjectPropertyContainer>

                            <div
                                class=""
                                v-if="project.financingPrograms && project.financingPrograms.length > 0"
                            >
                                <p class="text-fculColor font-bold ">{{$t('financing_programs_value_distribution')}}</p>
                                <div
                                    class="pl-8 pt-6 pb-8 mb-2 rounded-lg bg-fculColor bg-opacity-80 w-full flex flex-col overflow-scroll  lg:overflow-hidden"
                                    v-for="financingProgram in project.financingPrograms"
                                >
                                    <ProjectRecursiveContainer
                                        :title="`${$t('financing_programs_single')} ${financingProgram.designation} ${$t('distribution_value')}`"
                                        :financing-program="financingProgram"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </ProjectSection>
            </Separator>
            <Separator
                v-if="project.departments && project.departments.length > 0
                || project.investigationStructures && project.investigationStructures.length > 0
                || project.serviceUnits && project.serviceUnits.length > 0"
                class="mb-4 w-full" :separator-name="$t('structures_ulisboa')"
            >
                <ProjectSection
                    class="mb-4"
                    v-if="project.departments && project.departments.length > 0"
                    :section-title="$t('departments')"
                >
                    <ProjectPropertyContainer
                    >
                        <ProjectProperty
                            class="hover:bg-fculColor hover:bg-opacity-80 hover:text-white cursor-pointer"
                            v-for="department in project.departments"
                            :value="department.designation"
                            @click="() => navigateTo(department.id)"
                        />

                    </ProjectPropertyContainer>
                </ProjectSection>

                <ProjectSection
                    class="mb-4"
                    v-if="project.investigationStructures && project.investigationStructures.length > 0"
                    :section-title="$t('i_and_d_structures')"
                >
                    <ProjectPropertyContainer
                    >
                        <ProjectProperty
                            class="hover:bg-fculColor hover:bg-opacity-80 hover:text-white cursor-pointer"
                            v-for="investigationStructure in project.investigationStructures"
                            :value="investigationStructure.designation"
                            @click="() => navigateTo(investigationStructure.id)"
                        />

                    </ProjectPropertyContainer>
                </ProjectSection>
                <ProjectSection
                    v-if="project.serviceUnits && project.serviceUnits.length > 0"
                    :section-title="$t('service_unit_single')"
                >
                    <ProjectPropertyContainer
                    >
                        <ProjectProperty
                            class="hover:bg-fculColor hover:bg-opacity-80 hover:text-white cursor-pointer"
                            v-for="serviceUnit in project.serviceUnits"
                            :value="serviceUnit.designation"
                            @click="() => navigateTo(serviceUnit.id)"
                        />

                    </ProjectPropertyContainer>
                </ProjectSection>
            </Separator>


            <div class="mb-4 w-full flex justify-end">
                <Link
                    :href="sanitizedText(route('index'))"
                    class="flex justify-between btn-custom  w-fit py-3 px-4">
                    FUNDUS
                </Link>
            </div>
        </div>
    </div>
</template>

<script setup>
    import {getActiveLanguage} from "laravel-vue-i18n";
    import {computed, getCurrentInstance, onMounted, onUnmounted, ref} from "vue";
    import {Link} from "@inertiajs/vue3";
    import InfoItem from "../../Components/Projeto/InfoItem.vue";
    import ProjectKeyword from "../../Components/Projeto/ProjectKeyword.vue";
    import ProjectProperty from "../../Components/Projeto/ProjectProperty.vue";
    import ProjectPropertyContainer from "../../Components/Projeto/ProjectPropertyContainer.vue";
    import ProjectTextBlock from "../../Components/Projeto/ProjectTextBlock.vue";
    import ProjectSection from "../../Components/Projeto/ProjectSection.vue";
    const {proxy} = getCurrentInstance()
    const emitter = proxy.emitter
    const windows = proxy.window
    const gremius = "https://gremius.ciencias.ulisboa.pt/entities/external/entity/"
    import {INPUT_SIZES} from "../../Components/Input.vue";
    import ProjectContainerProperty from "../../Components/Projeto/ProjectContainerProperty.vue";
    import ProjectRecursiveContainer from "../../Components/Projeto/ProjectRecursiveContainer.vue";
    import {useUser} from "../../Components/hooks/useUser.js";
    import Separator from "../../Components/Projeto/Separator.vue";
    import DOMPurify from "dompurify";
    import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
    import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
    import base64 from "base-64";
    import {formater} from "../../Utils.js";

    const { isAuthenticated, user } = useUser()

    const props = defineProps({
        project: Object,
        fculEntity: Object,
        fcienciasEntity: Object,
    })

    const scientificSection = computed(() => {
        return (languagePt && props.project.simplifiedDescriptionPt && props.project.simplifiedDescriptionPt !== ''
                || props.project.simplifiedDescriptionEn && props.project.simplifiedDescriptionEn !== '')
            || (languagePt && props.project.scientificDescriptionPt && props.project.scientificDescriptionPt !== ''
                || props.project.scientificDescriptionEn && props.project.scientificDescriptionEn !== '')
            || (props.project.startTRL || props.project.targetTRL) && (props.project.startTRL !== '' || props.project.targetTRL !== '')
            || props.project.scientificAreas && props.project.scientificAreas.length > 0
            || props.project.socialEconomyCategories && props.project.socialEconomyCategories.length > 0
            || props.project.sustainableDevelopmentGoals && props.project.sustainableDevelopmentGoals.length > 0
            || props.project.helpfulHyperlinks && props.project.helpfulHyperlinks.length > 0
            || props.project.rewards && props.project.rewards.length > 0
            || (languagePt && props.project.impactPt && props.project.impactPt !== ''
                || props.project.impactEn && props.project.impactEn !== '')
            || (languagePt && props.project.ethicPt && props.project.ethicPt !== ''
                || props.project.ethicEn && props.project.ethicEn !== '')
            || (languagePt && props.project.personalDataManagementPlanPt && props.project.personalDataManagementPlanPt !== ''
                || languagePt && props.project.personalDataManagementPlanEn && props.project.personalDataManagementPlanEn !== '')
            || props.project.keywords?.length > 0
    })

    const languagePt = ref(getActiveLanguage() === 'pt')

    onMounted(() => {
        emitter.on('languageChange', () => {
            languagePt.value = getActiveLanguage() === 'pt'
        })
    })

    onUnmounted(() => {
        emitter.off('languageChange')
    })


    const navigateTo = (href) => {
      window.location.href = sanitizedText('https://gremius.ciencias.ulisboa.pt/entities/internal/entity/' + href)
    }
    const sanitizedText = (text) => {
        return DOMPurify.sanitize(text);
    }


</script>


