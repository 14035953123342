<template>
  <EditForm
    :title="$t('participation_types_edit')"
    :action-button-disabled="wasChanged === form.designation && participationRole.isResponsible === form.isResponsible"
    :return-route="route('settings.participation-role.index')"
    @submit="onSubmit"
  >
    <Input
        class="px-2"
      :label="$t('participation_types_single')"
      :error="form.errors.designation"
      :model-value="form.designation"
      @on-change="onChange"
    />
    <div class="flex flex-col pl-10">
      <label
          class="mb-1 mt-2 pl-2 font-bold"
      >{{$t('is_responsible')}}</label>
      <input
          v-model="form.isResponsible"
          class=" pt-5 pb-5  px-5 my-1.5 rounded-lg drop-shadow-xl shadow-black text-fculColor "
          type="checkbox"
      />
    </div>
  </EditForm>


</template>

<script setup>

import EditForm from "../../../Components/Forms/EditForm.vue";
import Input from "../../../Components/Input.vue";
import { useForm} from "@inertiajs/vue3";
import {ref} from "vue";

const props = defineProps({
    participationRole: Object
});


const wasChanged = ref(props.participationRole.designation)

const form = useForm({
    designation: props.participationRole.designation,
    isResponsible: props.participationRole.isResponsible
});

const onChange = (value) => {
  if (form.errors.designation) {
      form.errors.designation = null;
  }
  form.designation = value;
};

const onSubmit = () => {
    form.put(route('settings.participation-role.update', props.participationRole.id));
};

</script>

