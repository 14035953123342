<template>
    <CreateForm
        :title="$t('financing_programs_add')"
        :action-button-disabled="form.designation === '' || form.designation.length === 0 || form.participationRoles.length === 0"
        :return-route="route('settings.financing-programs.index')"
        @submit="onSubmit"
    >
        <div class="flex flex-col justify-center w-full overflow-y-scroll">
            <div class="px-2 w-full flex flex-wrap justify-between">
                <Input
                    :label="$t('financing_programs_single')"
                    :error="form.errors.designation"
                    :model-value="form.designation"
                    :is-required="true"
                    @on-change="(value) => onChange(value,'designation')"
                />
                <Dropdown
                    :label="$t('financing_programs_parent')"
                    :error="form.errors.financingProgramParent"
                    :model-value="form.financingProgramParent"
                    :list="financingPrograms"
                    :search-value="financingProgramQuery"
                    @on-change="(value) => onChange(value, 'financingProgramParent')"
                    @on-search="(value) => onSearch(value, 'financingPrograms')"
                    @on-click="(value) => onSearch(value, 'financingPrograms')"
                />
                <ComposedList
                    class="mt-8 bg-fculColor bg-opacity-20"
                    v-slot="listProps"
                    :label="$t('participation_types_entities')"
                    :list-display-name="(item) => `${item.typeOfParticipation}`"
                    :button-disable="(addingValue) => !addingValue.typeOfParticipation || !addingValue.systemEntityParticipationRole"
                    :list-size="INPUT_SIZES.MEDIUM"
                    :is-required="true"
                    @on-list-update="(value) => {
                        onChange(value.map(item => ({typeOfParticipation: item.typeOfParticipation, systemEntityParticipationRole: item.systemEntityParticipationRole?.id })), 'participationRoles')
                    }"
                >
                    <Input
                        :label="$t('participation_types_designation_entities')"
                        :model-value="listProps.toAddContent.typeOfParticipation"
                        :is-required="true"
                        @on-change="(value) => listProps.toAddContent.typeOfParticipation = value"
                    />
                    <Dropdown
                        :label="$t('participation_types_system_entities')"
                        :model-value="listProps.toAddContent.systemEntityParticipationRole"
                        :list="systemEntityParticipationRoles"
                        :search-value="systemEntityParticipationRoleQuery"
                        :is-required="true"
                        @on-change="(value) =>listProps.toAddContent.systemEntityParticipationRole = value"
                        @on-search="(value) => onSearch(value, 'systemParticipationRole')"
                    />
                </ComposedList>
                <ComposedList
                    class=" my-8 bg-fculColor bg-opacity-20 "
                    v-slot="listProps"
                    :label="$t('logos')"
                    :list-display-name="(item) => `${item.logoDesignation}`"
                    :button-disable="(addingValue) => !addingValue.logoDesignation || !addingValue.logo"
                    :list-size="INPUT_SIZES.MEDIUM"
                    @on-list-update="(value) => {
                        onChange(value.map(item => ({logoDesignation: item.logoDesignation, logo: item.logo })), 'logos')
                    }"
                >
                    <Input
                        :label="$t('logos_designation')"
                        :model-value="listProps.toAddContent.logoDesignation"
                        :is-required="true"
                        @on-change="(value) => listProps.toAddContent.logoDesignation = value"
                    />
                    <Input
                        :label="$t('logos_single')"
                        :model-value="listProps.toAddContent.logoLocation"
                        :is-required="true"
                        type="file"
                        accept="image/*"
                        @on-change="(binaryValue, location) => {
                            listProps.toAddContent.logoLocation = location
                            listProps.toAddContent.logo = binaryValue
                          }"
                    />
                </ComposedList>
            </div>
            <label class="font-bold pl-2 mb-4">Decomposição</label>
            <div class="w-full px-4">
                <button
                    class="bg-fculColor w-16 py-2 rounded-lg drop-shadow-xl shadow-black font-bold text-white cursor-pointer hover:bg-opacity-60 mb-4"
                    @click.prevent="increaseInput()"
                >
                    +
                </button>
            </div>
            <div class="flex w-full flex-col items-start px-4 pb-6 overflow-x-scroll">
                <RecursiveInput
                    :creation="true"
                    v-for="input in form.decompositionInputs"
                    :input="input"
                    @remove-this="removeThisInput"
                />
            </div>
        </div>
    </CreateForm>
</template>

<script setup>

import {router, useForm} from "@inertiajs/vue3";
import Input from "../../../Components/Input.vue";
import CreateForm from "../../../Components/Forms/CreateForm.vue";
import Dropdown from "../../../Components/Dropdown.vue"
import {ref} from "vue";
import RecursiveInput from "../../../Components/Forms/RecursiveInput.vue";
import {INPUT_SIZES} from "../../../Components/Input.vue";
import ComposedList from "../../../Components/Projeto/ComposedList.vue";

const props = defineProps({
    financingPrograms: Array,
    financingProgramQuery: String,
    systemEntityParticipationRoles: Array,
    systemEntityParticipationRoleQuery: String
})

const form = useForm({
    designation: "",
    financingProgramParent: null,
    decompositionInputs: [],
    logos: [],
    participationRoles: []
})

const onChange = (value, propertyName) => {
  if (form.errors[propertyName]) {
    form.errors[propertyName] = null;
  }
  form[propertyName] = ((value !== undefined)? (value.id? value.id : value) : null);

}

const queryParameters = ref({})

const onSearch = (value, property) => {

  if (queryParameters.value && value === ""){
    delete queryParameters.value[property]
  } else {
    queryParameters.value[property] = value
  }

  router.get(route('settings.financing-programs.create'), queryParameters.value, {
    preserveState: true,
  })
}

const onSubmit = () => {
    form.post(route('settings.financing-programs.store'))
}


const countId = ref(0)

const increaseInput = () => {
    form.decompositionInputs.push({
        id: countId.value++,
        name: "",
        child: []
    })
}

const removeThisInput = (thisId) => {
    form.decompositionInputs = form.decompositionInputs.filter((input) => input.id !== thisId)
}

</script>

<style>
textarea:focus, input:focus{
  outline: 0.19rem solid;
  outline-color: #2C3FB1;
}
</style>
