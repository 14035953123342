<template>
    <CreateForm
        :title="$t('keywords_add')"
        :action-button-disabled="form.designation === ''"
        :return-route="route('settings.keywords.index')"
        @submit="onSubmit"
    >

        <div class="w-full">
            <Input
                class="px-2"
                :label="$t('keywords_single')"
                :error="form.errors.designation"
                :model-value="form.designation"
                @on-change="(value) => onChange(value, 'designation')"
            />
            <Input
                class="lg:ml-8 px-2"
                :label="$t('keywords_single_other_idiom')"
                :error="form.errors.alternativeDesignation"
                :model-value="form.alternativeDesignation"
                @on-change="(value) => onChange(value, 'alternativeDesignation')"
            />
        </div>
    </CreateForm>
</template>

<script setup>

import {useForm} from "@inertiajs/vue3";
import Input from "../../../Components/Input.vue";
import CreateForm from "../../../Components/Forms/CreateForm.vue";

const form = useForm({
  designation: "",
    alternativeDesignation: ""
})

const onChange = (value, property) => {
    if (form.errors.designation) {
        form.errors.designation = null;
    }
    if (form.errors.alternativeDesignation) {
        form.errors.alternativeDesignation = null;
    }
    form[property] = value;
};

const onSubmit = () => {
    form.post(route('settings.keywords.store'))
}

</script>

<style>
textarea:focus, input:focus{
  outline: 0.19rem solid;
  outline-color: #2C3FB1;
}
</style>
