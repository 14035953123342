<template>
  <div
      class="mt-4 rounded-lg flex flex-col"
      :class="{
        'bg-opacity-40': isActive,
        'bg-fculColor': isActive,
        'bg-white': !isActive
      }"

  >
    <div class="w-full px-8 py-8 flex flex-row items-center justify-between cursor-pointer rounded-lg hover:bg-fculColor hover:text-white drop-shadow-2xl shadow-black"
         @click="changeVisibilityState"
         :class="{
          'bg-opacity-100': isActive,
          'bg-fculColor': isActive,
          'text-white': isActive
        }"
    >
      <p class="font-bold">{{ separatorName }}</p>
      <FontAwesomeIcon
          :icon="isActive? faCaretUp: faCaretDown" class="text-2xl "
      />
    </div>
    <div v-if="isActive" class="w-full flex flex-wrap justify-between mt-2 lg:mt-4 text-white px-2 lg:px-8 pb-2 lg:pb-8">
      <slot></slot>
    </div>

  </div>
</template>

<script setup>
  import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
  import {faCaretDown, faCaretUp} from "@fortawesome/free-solid-svg-icons";
  import {ref} from "vue";

  const props = defineProps({
    separatorName: String,
    openDefault: Boolean
  })

  const isActive = ref(props.openDefault);

  const changeVisibilityState = () => {
    isActive.value = !isActive.value;
  }


</script>
