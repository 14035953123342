<template>
    <EditForm
        class=""
        :title="$t('projects_update_documents')"
        :action-button-disabled="submitDisabled()"
        :return-route="route('projects.show', project.id)"
        @submit="onSubmit"
    >
        <div class="w-full flex flex-wrap justify-between px-2 mt-2 lg:px-0 lg:mt-0">
            <ComposedListAlternative
                class="mt-8 bg-white  text-black drop-shadow-2xl"
                v-slot="listProps"
                :label="$t('logos')"
                :list-display-name="(item) => `${item.logoDesignation}`"
                :button-disable="(addingValue) => !addingValue.logoDesignation || !addingValue.logo"
                :list-size="INPUT_SIZES.MEDIUM"
                :model-value="project.logos.map(item => ({
                    id: item.id,
                    logoDesignation: item.designation
                }))"
                :error="form.errors.logos"
                @on-list-update="(value) => {
                        onChange(value.map(item => ({id: item.id ?? -1, logoDesignation: item.logoDesignation, logo: item.logo })), 'logos')
                    }"
            >
                <Input
                    :label="$t('logos_designation')"
                    :model-value="listProps.toAddContent.logoDesignation"
                    :is-required="true"
                    @on-change="(value) => listProps.toAddContent.logoDesignation = value"
                    :invert-color="true"
                />
                <Input
                    :label="$t('logos_single')"
                    :model-value="listProps.toAddContent.logoLocation"
                    type="file"
                    accept="image/*"
                    @on-change="(binaryValue, location) => {
                            listProps.toAddContent.logoLocation = location
                            listProps.toAddContent.logo = binaryValue
                          }"
                    @error="(e) => {
                         form.errors.logos = $t(e)
                    }"
                />
            </ComposedListAlternative>
        </div>
    </EditForm>
</template>

<script setup>
import {INPUT_SIZES} from "../../../Components/Input.vue";
import {router, useForm} from "@inertiajs/vue3";
import _ from "lodash";
import {ref} from "vue";
import EditForm from "../../../Components/Forms/EditForm.vue";
import ComposedListAlternative from "../../../Components/Projeto/ComposedList.vue";
import Input from "../../../Components/Input.vue";

const props = defineProps({
    project: Object,
})


const form = useForm({
    type: "LOGOS",
    logos: props.project?.logos?.map(item => ({
        id: item.id,
        logoDesignation: item.designation
    }))
});


const queryParameters = ref({})

const onSearch =  _.throttle((value, property, used) => {

    if (queryParameters.value && value === ""){
        delete queryParameters.value[property]
    } else {
        queryParameters.value[property] = value
    }

    if (used) {
        queryParameters.value[property + "Used"] = JSON.stringify(used)
    }

    router.get(route('projects.edit.logos', props.project?.id), queryParameters.value, {
        preserveState: true,
    })
}, 1000)

const onChange = (value, propertyName) => {

    if (form.errors[propertyName]) {
        form.errors[propertyName] = null;
    }
    form[propertyName] = ((value !== undefined)?  value : null);
}

const onSubmit = () => {

    for (let prop in form.data()) {
        if (form[prop] instanceof Object && form[prop].id) {
            form[prop] = form[prop].id
        }
    }

    form.put(route('projects.update', props.project.id))
}


const submitDisabled = () => {
    return props.project.logos.filter(item => {
        return form.logos.find(logo => logo.id === item.id)
    }).length === props.project.logos.length && props.project.logos.length === form.logos.length;
}

</script>
