<template>
<div class="flex h-screen w-full bg-background">
    <SideBar class="hidden lg:flex"/>
    <SideBar
        v-if="menuActive" class="lg:hidden"
        @deactivate="menuActive = false"
        @onClick="() => menuActive = false"
    />
    <div class="absolute top-6 left-6 text-fculColor px-4 py-4 bg-white rounded-lg hover:bg-fculColor hover:bg-opacity-60 hover:text-white cursor-pointer lg:hidden"
         v-if="!menuActive"
        @click="menuActive = true"
    >
        <FontAwesomeIcon :icon="faBars" size="2xl"/>
    </div>
    <div
        v-if="menuActive"
        class="absolute top-0 left-0 w-screen h-screen bg-black bg-opacity-40 z-40"
        @click="menuActive = !menuActive"
    ></div>
    <slot class="w-full "></slot>
    <div
        id="toast-bottom-right"
        class="fixed flex border-fculColor items-center w-full max-w-xs p-4 space-x-4 text-white bg-fculColor divide-x divide-gray-200 rounded-lg drop-shadow-xl shadow-black right-5 bottom-5 d space-x z-10"
        role="alert"
        v-if="page.props.flash.success"
    >
        <div class="text-sm font-bold">
            {{ $t(page.props.flash.success) }}
        </div>
    </div>
    <div
        id="toast-bottom-right"
        class="fixed flex items-center w-full max-w-xs p-4 space-x-4 text-white bg-decline divide-x divide-gray-200 rounded-lg drop-shadow-xl shadow-black right-5 bottom-5 d space-x z-10"
        role="alert"
        v-if="page.props.flash.error"
    >
        <div class="text-sm font-bold">
            {{ $t(page.props.flash.error) }}
        </div>
    </div>
</div>
</template>

<script setup>

import {ref} from "vue";
import SideBar from "../Components/SideBar.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {usePage} from "@inertiajs/vue3";

const menuActive = ref(false);

const page = usePage();


  setInterval(() => {
      if (page.props.flash.success)
        page.props.flash.success = null;
      if (page.props.flash.error)
          page.props.flash.error = null;

  }, 4000)

</script>


