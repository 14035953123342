<template>
    <div class="flex ">
        <FontAwesomeIcon
            :icon="faSearch"
            size="lg"
            class="rounded-l-lg drop-shadow-xl shadow-black text-fculColor bg-white px-2 py-2.5"
        />
        <input
            class="border-0 focus:border-1 w-full h-10 px-2 focus:py-1.5 flex justify-center rounded-r-lg drop-shadow-xl shadow-black text-black"
            :placeholder="placeholder ?? $t('search')"
            @input="onValueUpdate"
        >
    </div>
</template>
designation

<script setup>

    import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
    import {faSearch} from "@fortawesome/free-solid-svg-icons";
    import {onMounted, ref} from "vue";

    const emit = defineEmits(['onSearch']);



    const props = defineProps({
        modelValue: String,
        placeholder: String
    })


    const onValueUpdate = (event) => {
        emit('onSearch', event.target.value)
    }

</script>

